/* 
 * VBのPHPのみでは再現できないデータベースの共通関数および
 * 使用するajaxの関数のファイル
 *
 * ※const.jsの定数を使用しているため、このファイルを使用する場合は
 * 　ファイル読み込み前にconst.jsを読み込んでおくこと
 */

var DatabaseFunc = {};

// 赤黒削除のモード
DatabaseFunc.AKAKURO = 0;
DatabaseFunc.SINGLE = 1;

/**
 * 作業データを削除する。
 * VB:basYama mSagyoDB_DEL に相当
 * @param {string} workNo 削除する作業番号
 * @param {string} workYear 作業年
 * @returns {undefined}
 */
DatabaseFunc.delWorkData = function (workNo, workYear) {
    // 作業データ取り消しのAjax処理を実行
    var delResult = DelWorkDataAjaxFunc.delWorkDataAjax(workNo, workYear);

    if (delResult.result === 'error') {
        alert(delResult.error_message);
        return false;
    }
    return true;
};

/*
 * delWorkData 用関数 ここから
 */
var DelWorkDataAjaxFunc = {};


DelWorkDataAjaxFunc.delWorkDataAjax = function (workNo, workYear) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/order_delete_work_data.php",
        data: {
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result = {};
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/*
 * delWorkData 用関数 ここまで
 */


DatabaseFunc.delAkakuroData = async function (workNo, workYear, mode) {
    //  赤黒削除用作業データを取得
    let workDataResult = this._getWorkDataForDelAkakuro(workNo, workYear);
    if (workDataResult.result === 'error') {
        alert(workDataResult.error_message);
        return false;
    }
    let workData = workDataResult.work_data;

    // 在庫・売上処理用データ作成(StockUpdateData.phpとプロパティの値を一致するように)
    let updateList = {};

    updateList.workNo = workData.workNo;
    updateList.workYear = workData.workYear;
    updateList.slipNo = '';
    updateList.customerCode = workData.customerCode;
    updateList.cutScheduleDate = workData.cutScheduledDate;
    updateList.cutCompletionDate = workData.cutCompletionDate;
    updateList.cutWorkerCode = workData.cuttingWorkerCode;
    updateList.materialSubCode = workData.materialSubContractorCode;
    updateList.shapeCode = workData.shapeCode;
    updateList.materialCode = workData.materialCode;
    updateList.heattreatCode = workData.heattreatCode;
    updateList.thickness = workData.thickness;
    updateList.vertical = 0;
    updateList.horizontal = 0;
    updateList.member = workData.member;
    updateList.amount = workData.amount - workData.processingFees;
    updateList.akakuroFlag = workData.isAkakuro;

    // 赤黒2行処理の場合在庫変更処理は行わない
    let stockChangeInfo = null;
    let stockDate = '';
    if (mode === this.AKAKURO) {
        updateList.weight = 0;
        updateList.processingDate = workData.stockDate;
        // 1行処理で在庫計上日が有り、重量が入っている場合
    } else if (workData.stockDate != ' ' && workData.weight != '0') {
        updateList.weight = -workData.weight;
        // 在庫引当日を設定
        updateList.processingDate = workData.stockDate;

        // 在庫変更のチェック処理
        stockChangeInfo = await this.checkChangeStockAdd(updateList);
        // nullなら終了
        if (stockChangeInfo === null) {
            return false;
        }
        // 計算対象で有り、社内切断がある場合
        if (stockChangeInfo.is_calc && stockChangeInfo.company_cut) {
            stockDate = stockChangeInfo.stock_date;
            // それ以外
        } else {
            stockDate = updateList.processingDate;
        }
        // それ以外
    } else {
        updateList.weight = 0;
        // 赤黒納品完了日を設定
        updateList.processingDate = workData.deliveryCompletionDate;
    }

    // 処理日は売上計上日
    updateList.processingDate = workData.monthlySalesDate;

    // 売上変更処理
    let salesDate = await this.checkChangeSalesAdd(updateList);

    if (salesDate === null) {
        return false;
    }

    // 処理日を上書き
    updateList.processingDate = salesDate;

    // 削除・更新処理を行う。
    let workNote = workData.workNote;
    let isReturnGoods = false;
    let beforeWorkNo = '';
    let beforeWorkYear = '';

    // 返品データ判定
    if (workNote.match(/返品/)) {
        isReturnGoods = true;
        beforeWorkNo = workData.beforeWorkNo;
        beforeWorkYear = workData.beforeWorkYear;
    }

    let updateResult = this._updateDelAkakuro(updateList, stockDate, salesDate, stockChangeInfo, isReturnGoods, beforeWorkNo, beforeWorkYear);
    if (updateResult.result === 'error') {
        alert(updateResult.error_message);
        return false;
    }

    return true;

};

/*
 * delAkakuroData 用関数 ここから
 */
DatabaseFunc._getWorkDataForDelAkakuro = function (workNo, workYear) {

    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_get_work_data_del_akakuro.php",
        data: {
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result = {};
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/**
 * 赤黒データ削除処理を行う。
 *
 * @param {array} updateData
 * @param {string} stockDate
 * @param {string} salesDate
 * @param {array} stockChangeInfo
 * @param {boolean} isReturnGoods
 * @param {string} beforeWorkNo
 * @param {string} beforeWorkYear
 * @returns {DatabaseFunc._updateDelAkakuro.result|data}
 */
DatabaseFunc._updateDelAkakuro = function (updateData, stockDate, salesDate, stockChangeInfo, isReturnGoods, beforeWorkNo, beforeWorkYear) {

    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_del_akakuro_data.php",
        data: {
            "update_data": encodeURIComponent(JSON.stringify(updateData)),
            "stock_date": stockDate,
            "sales_date": salesDate,
            "stock_change_info": encodeURIComponent(JSON.stringify(stockChangeInfo)),
            "is_return_goods": isReturnGoods,
            "before_work_no": beforeWorkNo,
            "before_work_year": beforeWorkYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result = {};
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};
/*
 * delAkakuroData 用関数 ここまで
 */

// 売り上げ変更
DatabaseFunc.SALES_SMODE_AKAKURO = 0;
DatabaseFunc.SALES_SMODE_KAKU = 1;
DatabaseFunc.SALES_SMODE_MARU = 2;
DatabaseFunc.SALES_SMODE_RING = 3;
DatabaseFunc.SALES_SMODE_KATA = 4;

/**
 * 売り上げ変更のチェック部分の処理を行う。
 * VB:ComProc UriageHenkouAddのチェック部分に相当
 * @param {Object} updateData  PHPのStockUpdateDataを変換したオブジェクト
 * @returns {Promise} 売上更新を行う日付 失敗・中止時null
 */
DatabaseFunc.checkChangeSalesAdd = function (updateData) {

    return new Promise((resolve) => {

        // 締め日チェック
        let cutoffResult = this._getCutoffDateLockStatus(updateData.customerCode, updateData.processingDate);

        if (cutoffResult.result === 'error') {
            alert(cutoffResult.error_message);
            resolve(null);
            return;
        } else if (cutoffResult.status.code == '1') {

//            alert('締め処理中です。\n確定処理を中止しました。');
            alert('締め処理ロック中です。\n確定処理を中止しました。');
            resolve(null);
            return;

        }

        // 売上月次ロック情報を取得
        let salesLockResult = this._getSalesLockStatus(updateData.processingDate);

        // 値がnullならfalseを返す
        if (salesLockResult.result === 'error') {
            alert(salesLockResult.error_message);
            resolve(null);
            return;
        }
        let salesStatus = salesLockResult.status.status;

        // ステータスの値が1か2であれば再計算ダイアログを開く
        if (salesStatus === 1 || salesStatus === 2) {

            // 再計算の日付を取得
            this.openRecalcDialog(false, salesLockResult.status.recalc_dialog_data, (dialogResult) =>{

                if (dialogResult === RECALC_RESULT_CANCEL || dialogResult == undefined) {
                    alert('処理を中止しました。');
                    resolve(null);
                    return;
                }

                // 日付に変換できるものでなければ中止
                if (DateFunc.convertDate(dialogResult) == null) {
                    alert('不正な日付のため処理を中止します。');
                    resolve(null);
                    return;
                }

                // 入力した日付を返す。
                resolve(dialogResult);

            });

        } else {
            // 日付をそのまま返す。
            resolve(updateData.processingDate);
        }

        // try {
        //
        //
        // } catch (e) {
        //     alert('予期しないエラーが発生しました。\n' + e.message);
        //
        //     resolve(null);
        // }

    });


};
/*
 * changeSalesAdd用関数
 */

/**
 * 締日ロック状態を取得する。
 * @param {string} customerCode 取引先コード
 * @param {string} salesDate 売上処理日
 * @return {array} 成否とステータスコード
 */
DatabaseFunc._getCutoffDateLockStatus = function (customerCode, salesDate) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_get_cutoff_date_status.php",
        data: {
            "customer_code": customerCode,
            "sales_date": salesDate
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/**
 * 売上月次ロック情報を取得する。
 * @param {string} salesDate
 * @param {string} mode
 * @returns {data|DatabaseFunc._getSalesLockStatus.result}
 */
DatabaseFunc._getSalesLockStatus = function (salesDate, mode) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_get_sales_lock.php",
        data: {
            "sales_date": salesDate,
            "mode": mode
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};
/*
 * changeSalesAdd用関数 ここまで
 */

/**
 *  在庫変更のチェック部分の処理を行う。
 *  VB ComProc ZaikoHenkouAddの日付計算部分に該当
 *
 * @param {array} updateData PHPのStockUpdateDataを変換した配列
 * @param {function} callback 処理終了後に呼び出される関数
 */
DatabaseFunc.checkChangeStockAdd = function (updateData) {

    return new Promise(resolve =>{

        let result = {};

        // 運賃・値引き・消費税は重量計算しない
        if ($.inArray(updateData.materialCode, ['9005', '9006', '9010', '9900']) >= 0) {
            result.result = 'success';
            result.is_calc = false;

            resolve(result);
            return;
        }

        // 社内切断日がありかつ材料外注先がない場合、減算処理を行う
        if (updateData.cutScheduleDate && (!updateData.materialSubCode || updateData.materialSubCode === '99000')) {
            // 在庫ロックチェック
            let stockResult = this._getStockLockStatus(updateData.processingDate, updateData.weight);
            if (stockResult.result === 'error') {
                alert(stockResult.error_message);
                resolve(null);
                return;
            }

            let lockStatus = stockResult.status;
            let stockDate = null;

            // 共通の返却値
            result.result = 'success';
            result.is_calc = true;
            result.company_cut = true;

            // ステータスの値が1か2であれば再計算ダイアログを開く
            if (lockStatus.status == 1 || lockStatus.status == 2) {

                // 再計算ダイアログから日付を取得
                this.openRecalcDialog(true, lockStatus.recalc_dialog_data, (dialogResult) => {

                    // キャンセルは終了させる
                    if (dialogResult === RECALC_RESULT_CANCEL) {
                        alert('処理を中止しました。');
                        resolve(null);
                        return;
                    }

                    // 日付に変換できるものでなければ中止
                    if (DateFunc.convertDate(dialogResult) == null) {
                        alert('不正な日付のため処理を中止します。');
                        resolve(null);
                        return;
                    }

                    // 返却値設定
                    result.is_change = '1';
                    result.stock_date = dialogResult;

                    resolve(result);

                });

            } else {

                result.stock_date  = updateData.processingDate;
                result.is_change = '0';

                resolve(result);
            }


            // 社内切断なし（外注）
        } else {
            result.result = 'success';
            result.is_calc = true;
            result.company_cut = false;
            result.is_change = "0";
            result.stock_date = "";

            resolve(result);
        }

    });

};

// DatabaseFunc.checkChangeStockAdd = function (updateData, callback) {
//     let result = {};
//
//     // 運賃・値引き・消費税は重量計算しない
//     if ($.inArray(updateData.materialCode, ['9005', '9006', '9010', '9900']) >= 0) {
//         result.result = 'success';
//         result.is_calc = false;
//
//         callback(result);
//         return;
//     }
//
//     let isChange = null;
//     // 社内切断日がありかつ材料外注先がない場合、減算処理を行う
//     if (updateData.cutScheduleDate && (!updateData.materialSubCode || updateData.materialSubCode === '99000')) {
//         // 在庫ロックチェック
//         let stockResult = this._getStockLockStatus(updateData.processingDate, updateData.weight);
//         if (stockResult.result === 'error') {
//             alert(stockResult.error_message);
//             callback(null);
//             return;
//         }
//
//         let lockStatus = stockResult.status;
//         let stockDate = null;
//
//         // 共通の返却値
//         result.result = 'success';
//         result.is_calc = true;
//         result.company_cut = true;
//
//         // ステータスの値が1か2であれば再計算ダイアログを開く
//         if (lockStatus.status == 1 || lockStatus.status == 2) {
//
//             // 再計算ダイアログから日付を取得
//             this._openRecalcDialog(true, lockStatus.recalc_dialog_data, (dialogResult) => {
//
//                 // キャンセルは終了させる
//                 if (dialogResult === RECALC_RESULT_CANCEL) {
//                     alert('処理を中止しました。');
//                     callback(null);
//                     return;
//                 }
//
//                 // 日付に変換できるものでなければ中止
//                 if (DateFunc.convertDate(dialogResult) == null) {
//                     alert('不正な日付のため処理を中止します。');
//                     callback(null);
//                     return;
//                 }
//
//                 // 返却値設定
//                 result.is_change = '1';
//                 result.stock_date = dialogResult;
//
//                 callback(result);
//
//             });
//
//         } else {
//
//             result.stock_date  = updateData.processingDate;
//             result.is_change = '0';
//
//             callback(result);
//         }
//
//
//         // 社内切断なし（外注）
//     } else {
//         result.result = 'success';
//         result.is_calc = true;
//         result.company_cut = false;
//         result.is_change = "0";
//         result.stock_date = "";
//
//         callback(result);
//     }
// };
/*
 * checkChangeStockAdd用関数
 */
DatabaseFunc._getStockLockStatus = function (processingDate, weight) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_get_stock_lock.php",
        data: {
            "processing_date": processingDate,
            "weight": weight
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};
/*
 * checkChangeStockAdd用関数ここまで
 */


/**
 *  赤黒データ2行のデータを取得する。
 *
 * @param {string} receivedNo 受注番号
 * @param {string} akakuroCtrlNo 赤黒管理番号
 * @returns {data|DatabaseFunc.getAkakuroData.result} 各データの作業番号・作業年・重量の配列
 */
DatabaseFunc.getAkakuroData = function (receivedNo, akakuroCtrlNo) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_get_akakuro_data.php",
        data: {
            "received_no": receivedNo,
            "akakuro_ctrl_no": akakuroCtrlNo
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/**
 * 納品情報を削除する。
 *
 * @param workNo 作業No
 * @param workYear 作業年
 * @returns {Promise<string>}
 */
DatabaseFunc.deleteSlipData = function (workNo, workYear) {

    return new Promise(resolve => {
        // 納品書発行済みかチェックする
        let issuedResult = this._checkIssuedSlip(workNo, workYear);
        if (issuedResult.result === 'error') {
            alert(issuedResult.error_message);
            resolve('error');
            // 納品書が見つからなかった時は
        } else if (issuedResult.result === 'notfound') {
            resolve('del');
        }

        let message = '';
        // 仮伝票
        if (issuedResult.is_tmp) {
            message = '仮伝票(No.' + issuedResult.slip_no + ')を発行済みです。取り消してもよろしいですか？';
            // 本伝票
        } else {
            message = '本伝票(No.' + issuedResult.slip_no + ')を発行済みです。取り消してもよろしいですか？';
        }

        if (!confirm(message)) {
            resolve('normal');
        }

        // データ削除
        let delSlipResult = this._deleteSalesSlip(issuedResult.slip_no, workNo, workYear);

        // 失敗時エラー
        if (delSlipResult.result === 'error') {
            alert(delSlipResult.error_message);
            resolve('error');
            // 伝票テーブルに存在しなかった場合処理停止
        } else if (delSlipResult.result === 'noufound') {
            alert(delSlipResult.error_message);
            resolve('stop');
        }

        // 削除後伝票データ無しの場合
        if (delSlipResult.count === '0') {
            alert('納品書を削除しました。');
            resolve('del');
        }

        // 削除後伝票データ有りの場合
        if (!confirm('納品書を再発行しますか？')) {
            resolve('del');
        }

        // 納品書出力用データ作成
        let mode = 4;
        let slipNo = issuedResult.slip_no;

        // 納品書印刷ダイアログを表示
        DatabaseFunc._openDeliveryPdfModal(mode, slipNo, (dialogResult) =>{

            if (dialogResult === 'error') {
                resolve('error');
            } else if (dialogResult === 'cancel') {
                resolve('stop');
            } else {
                resolve('del');
            }
        });
    });
};
/*
 * deleteSlipData用関数
 */

DatabaseFunc._checkIssuedSlip = function (workNo, workYear) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_is_issued_slip.php",
        data: {
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

DatabaseFunc._deleteSalesSlip = function (slipNo, workNo, workYear) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_delete_sales_slip.php",
        data: {
            "slip_no": slipNo,
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: "utf-8",
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = "error";
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/*
 * deleteSlipData用関数ここまで
 */

/**
 * 届け先コードのリストを配列で取得する。
 *
 * @param {string} customerCode　紐付いている得意先コード 無しの場合すべて選択
 * @returns {DatabaseFunc._getDestinationCodeListAjax.result.codeList} 届け先コードの配列
 */
DatabaseFunc.getDestinationCodeList = function (customerCode) {
    // 取引先コード未入力ならすべて取得
    if (!customerCode) {
        customerCode = null;
    }

    var destinationData = this._getDestinationCodeListAjax(customerCode);

    if (destinationData.result === 'error') {
        return null;
    }

    return destinationData.codeList;

};

/*
 * getDestinationCodeList用関数
 */
DatabaseFunc._getDestinationCodeListAjax = function (customerCode) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_get_destination.php",
        data: {
            "customer_code": customerCode
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: "utf-8",
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = "error";
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};
/*
 * getDestinationCodeList用関数ここまで
 */

/**
 * 得意先名を取得する。
 *
 * @param {type} customerCode
 * @returns {data|DatabaseFunc.getCustomerName.result} result:見つかった時[success] 見つからなかった時[notfound]  使用禁止[prohibition] エラー[error] name: 見つかった時のみ得意先名
 */
DatabaseFunc.getCustomerName = function (customerCode) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_get_customer_name.php",
        data: {
            "customer_code": customerCode
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: "utf-8",
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = "error";
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/**
 * 納品書が存在するかチェックを行い、存在する場合伝票番号・納品日・切断日を取得する。
 *
 * @param {String} workNo 作業番号
 * @param {String} workYear 作業年
 * @returns {DatabaseFunc.getSlipInfo.result|data}
 *                    result: 納品書が見つかった時'found' 見つからなかった時'notfound'<br>
 *                    delivery_date: 納品書が見つかった時、納品日<br>
 *                    cut_date: 納品書が見つかった時、切断日
 */
DatabaseFunc.getSlipInfo = function (workNo, workYear) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_is_issued_slip.php",
        data: {
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/**
 * 伝票番号に紐付く作業データの作業番号・作業年を取得する。
 *
 * @param {string} slipNo 伝票番号
 * @returns {{array}} 結果と各作業データの作業番号(work_no) 作業年(work_year)を格納した配列
 */
DatabaseFunc.getWorkInfoSlip = function (slipNo) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_get_work_info_slip.php",
        data: {
            "slip_no": slipNo
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/**
 * 作業データのフラグ情報を取得する。
 *
 * @param {string} workNo 作業番号
 * @param {string} workYear 作業年
 * @returns {data|DatabaseFunc.getWorkFlagInfo.result} フラグ情報の配列<br>
 *            <b>return</b>: 返品フラグ<br>
 *            <b>delete</b>: 削除フラグ<br>
 *            <b>unclaimed</b>: 未請求フラグ<br>
 *            <b>prohibition</b>: 変更禁止フラグ<br>
 *            <b>lock</b>: ロックフラグ
 *
 */
DatabaseFunc.getWorkFlagInfo = function (workNo, workYear) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_get_work_data_flag_info.php",
        data: {
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/**
 * 取引先の情報を取得する。
 *
 * @param {String} customerCode 取引先コード
 * @returns {data|DatabaseFunc.getCustomerData.result}
 */
DatabaseFunc.getCustomerData = function (customerCode) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_get_billing_data.php",
        data: {
            "customer_code": customerCode
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

/**
 * 取引先の情報を取得する。
 *
 * @returns {data|DatabaseFunc.getCustomerData.result}
 * @param workNo
 * @param workYear
 */
DatabaseFunc.getOrderWorkNoAndYear = function (workNo, workYear) {
    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_get_order_work_no_year.php",
        data: {
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            result.result = 'error';
            result.error_message = errorThrown + XMLHttpRequest.responseText;
        }
    });

    return result;
};

DatabaseFunc.workDataLockOrderChange = function (workNo, workYear) {

    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_lock_order_work_data.php",
        data: {
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {

            // エラー・ロック時はメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
            } else if (data.result === "lock") {
                alert("作業番号:" + data.work_no + "は" + data.reason + "によってロックされています。\n使用中ユーザー:" + data.user_name);
            }

            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            result.result = "error";
        }
    });

    return result;

};

/**
 * 作業データをロックする。
 *
 * @param {String} workNo 作業番号
 * @param {String} workYear 作業年
 * @param {String} lockReason ロック理由
 * @param {boolean} viewAlert ロック時にアラートを表示するか
 * @returns {boolean} ロック成功時true それ以外はfalse
 */
DatabaseFunc.workDataLock = function (workNo, workYear, lockReason, viewAlert) {

    // アラート初期値はtrue
    if (viewAlert == undefined) {
        viewAlert = true;
    }

    $.ajaxSetup({
        cache: false
    });

    var success = false;

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_start_exclusive.php",
        data: {
            "work_no": workNo,
            "work_year": workYear,
            "lock_reason": lockReason
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {

            // エラー・ロック時はメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                success = false;
            } else if (data.result === "lock") {
                if (viewAlert) {
                    alert("作業番号:" + data.work_no + "は" + data.reason + "によってロックされています。\n使用中ユーザー:" + data.user_name);
                }
                success = false;
            } else {
                success = true;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(result.error_message = errorThrown + XMLHttpRequest.responseText);
            success = false;
        }
    });

    return success;

};

/**
 * 作業データをロック解除する。
 *
 * @param {String} workNo 作業番号
 * @param {String} workYear 作業年
 * @param {String} lockReason ロック理由
 * @param {boolean} viewAlert ロック時にアラートを表示するか
 * @returns {boolean} ロック成功時true それ以外はfalse
 */
DatabaseFunc.workDataUnlock = function (workNo, workYear, lockReason, viewAlert) {

    // アラート初期値はtrue
    if (viewAlert == undefined) {
        viewAlert = true;
    }

    $.ajaxSetup({
        cache: false
    });

    var success = false;

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_finish_exclusive.php",
        data: {
            "work_no": workNo,
            "work_year": workYear,
            "lock_reason": lockReason
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {

            // エラー・ロック時はメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                success = false;
            } else if (data.result === "lock") {
                if (viewAlert) {
                    alert("作業番号:" + data.work_no + "は" + data.reason + "によってロックされています。\n使用中ユーザー:" + data.user_name);
                }
                success = false;
            } else if (data.result === "no_data") {
                if (viewAlert) {
                    alert(data.error_message);
                }
                success = true;
            } else {
                success = true;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(result.error_message = errorThrown + XMLHttpRequest.responseText);
            success = false;
        }
    });

    return success;
};

/**
 * 赤黒データ変更時のロック開始を行う。
 *
 * @param workNo
 * @param workYear
 * @param daihinFlag
 * @param orderNo
 * @param beforeWorkNo
 * @param beforeWorkYear
 * @param numbering
 * @returns {boolean}
 */
DatabaseFunc.workDataLockChangeAkakuro = function (workNo, workYear, daihinFlag, orderNo, beforeWorkNo, beforeWorkYear, numbering) {

    $.ajaxSetup({
        cache: false
    });

    var success = false;

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_start_exclusive_change_akakuro.php",
        data: {
            "work_no": workNo,
            "work_year": workYear,
            "daihin_flag": daihinFlag,
            "order_no": orderNo,
            "msagyo_bango": beforeWorkNo,
            "msagyo_nen": beforeWorkYear,
            "numbering": numbering
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {

            // エラー・ロック時はメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                success = false;
            } else if (data.result === "lock") {
                alert("作業番号:" + data.work_no + "は" + data.reason + "によってロックされています。\n使用中ユーザー:" + data.user_name);
                success = false;
            } else {
                success = true;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            success = false;
        }
    });

    return success;

};

/**
 * 赤黒データ変更時のロック開始を行う。作業番号等のデータを返す
 *
 * @param workNo
 * @param workYear
 * @param daihinFlag
 * @param orderNo
 * @param beforeWorkNo
 * @param beforeWorkYear
 * @param numbering
 * @returns {boolean}
 */
DatabaseFunc.workDataLockChangeAkakuroReturnData = function (workNo, workYear, daihinFlag, orderNo, beforeWorkNo, beforeWorkYear, numbering, lockReason) {

    $.ajaxSetup({
        cache: false
    });

    var result = {};

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_start_exclusive_change_akakuro.php",
        data: {
            "work_no": workNo,
            "work_year": workYear,
            "daihin_flag": daihinFlag,
            "order_no": orderNo,
            "msagyo_bango": beforeWorkNo,
            "msagyo_nen": beforeWorkYear,
            "numbering": numbering,
            "lock_reason": lockReason
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {

            // エラー・ロック時はメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
            } else if (data.result === "lock") {
                alert("作業番号:" + data.work_no + "は" + data.reason + "によってロックされています。\n使用中ユーザー:" + data.user_name);
            }

            result = data;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            result.result = "error";
        }
    });

    return result;

};

/**
 * 複数のロックを開始する。
 *
 * @param {Array} workData [作業番号:work_no 作業年:work_year] の配列を格納した配列
 * @param lockReason ロック理由
 * @param viewAlert ロック時アラートを表示するか
 * @param {boolean} autoUnlock ロック時にアンロックを行うか
 * @returns {boolean} 成功時true 失敗時false
 */
DatabaseFunc.workDataLockMulti = function (workData, lockReason, viewAlert, autoUnlock) {
    // アラート初期値はtrue
    if (viewAlert == undefined) {
        viewAlert = true;
    }
    // アンロック初期値はtrue
    if (autoUnlock == undefined) {
        autoUnlock = true;
    }

    var success = true;

    for (var i = 0; i < workData.length; i++) {
        var result = this.workDataLock(workData[i].work_no, workData[i].work_year, lockReason, viewAlert);

        // 失敗したらフラグを変更しループ終了
        if (!result) {
            success = false;
        }

    }

    // 失敗時かつオートアンロック許可時はロック解除
    if (!success && autoUnlock) {
        this.workDataUnlockMulti(workData, lockReason, false);
    }

    return success;
};

/**
 * 複数のロックをまとめて開始する。
 *
 * @param {Array} workData [作業番号:work_no 作業年:work_year] の配列を格納した配列
 * @param lockReason ロック理由
 * @param viewAlert ロック時アラートを表示するか
 * @param {boolean} autoUnlock ロック時にアンロックを行うか
 * @returns {boolean} 成功時true 失敗時false
 */
DatabaseFunc.workDataLockTogether = function (workData, lockReason, viewAlert, autoUnlock) {
    // アラート初期値はtrue
    if (viewAlert == undefined) {
        viewAlert = true;
    }
    // アンロック初期値はtrue
    if (autoUnlock == undefined) {
        autoUnlock = true;
    }

    var success = true;

//    for (var i = 0; i < workData.length; i++) {
//        var result = this.workDataLock(workData[i].work_no, workData[i].work_year, lockReason, viewAlert);
//
//        // 失敗したらフラグを変更しループ終了
//        if (!result) {
//            success = false;
//        }
//
//    }

    $.ajaxSetup({
        cache: false
    });

    var success = false;

    var workDataJSON = JSON.stringify(workData);

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_start_exclusive_together.php",
        data: {
            "workData": workDataJSON,
            "lock_reason": lockReason
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {

            // エラー・ロック時はメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                success = false;
            } else if (data.result === "lock") {
                if (viewAlert) {
                    alert("作業番号:" + data.work_no + "は" + data.reason + "によってロックされています。\n使用中ユーザー:" + data.user_name);
                }
                success = false;
            } else {
                success = true;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(result.error_message = errorThrown + XMLHttpRequest.responseText);
            success = false;
        }
    });





    // 失敗時かつオートアンロック許可時はロック解除
    if (success == false && autoUnlock == true) {
        this.workDataUnlockMulti(workData, lockReason, false);
    }

    return success;
};

/**
 * 複数のロックを解除する。
 *
 * @param {Array} workData [作業番号:work_no 作業年:work_year] の配列を格納した配列
 * @param lockReason ロック理由
 * @param viewAlert ロック時アラートを表示するか
 * @returns {boolean} 成功時true 失敗時false
 */
DatabaseFunc.workDataUnlockMulti = function (workData, lockReason, viewAlert) {
    // アラート初期値はtrue
    if (viewAlert == undefined) {
        viewAlert = true;
    }

    var success = true;

    for (var i = 0; i < workData.length; i++) {
        var result = this.workDataUnlock(workData[i].work_no, workData[i].work_year, lockReason, viewAlert);

        // 失敗したらフラグを変更しループは続行
        if (!result) {
            success = false;
        }

    }

    return success;
};

/* ロックの理由 */

/**
 * 変更処理の場合
 *
 * @type String
 */
DatabaseFunc.REASON_CHANGE = "変更処理";

/**
 * 作業完了の場合
 *
 * @type String
 */
DatabaseFunc.REASON_WORK_COMP = "作業完了";

/**
 * 取り消し処理の場合
 *
 * @type {string}
 */
DatabaseFunc.REASON_DELETE_DATA = "取消処理";

/**
 * 納品書印刷の場合
 *
 * @type {string}
 */
DatabaseFunc.REASON_PRINT_DELIVERY = "納品書印刷";

/**
 * 担当者名をチェックし、未登録であれば追加する。
 *
 * @param customerCode 仕入先コード
 * @param personName 担当者名
 * @param isAsync 非同期処理を行うかどうか
 * @returns {Array | null} 成功時は結果"result" 担当者コード"customer_code"を格納した配列、失敗時はnull
 */
DatabaseFunc.addNewPersonName = function (customerCode, personName, isAsync) {

    if (isAsync == undefined) {
        isAsync = true;
    }

    $.ajaxSetup({
        cache: false
    });

    var result = null;

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_add_person_name.php",
        data: {
            "customer_code": customerCode,
            "person_name": personName
        },
        async: isAsync,	// 非同期
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            // エラーはメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                result = null;
            } else {
                result = data;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            result = null;
        }
    });

    return result;
};

/**
 * 作業データを取得する。
 *
 * @param {string} workNo 作業番号
 * @param {string} workYear 作業年
 * @returns {Array | null} 成功時は結果と作業データの配列 失敗時null
 */
DatabaseFunc.getWorkData = function (workNo, workYear) {

    $.ajaxSetup({
        cache: false
    });

    var result = null;

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_search_work_data.php",
        data: {
            "work_no": workNo,
            "work_year": workYear
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            // エラーはメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                result = null;
            } else {
                result = data;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            result = null;
        }
    });

    return result;
};

/**
 * 変更履歴を追加する。
 *
 * @param {string} processType 更新種別
 * @param {string} workNo 作業番号
 * @param {string} workBranchNo 作業枝番号
 * @param {string} workYear 作業年
 * @param {string} stockDate 在庫日付 YYYYMMDD形式
 * @param {string} salesDate 売上日付 YYYYMMDD形式
 * @param {string} weight 重量
 * @param {string} salesAmount 金額
 * @returns {*}
 */
DatabaseFunc.addUpdateHistory = function (processType, workNo, workBranchNo, workYear, stockDate, salesDate, weight, salesAmount) {


    $.ajaxSetup({
        cache: false
    });

    var result = null;

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_update_order_stock_history.php",
        data: {
            "process_type": processType,
            "work_no" : workNo,
            "work_branch_no": workBranchNo,
            "work_year": workYear,
            "stock_date": stockDate,
            "sales_date": salesDate,
            "weight": weight,
            "sales_amount": salesAmount
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            // エラーはメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                result = null;
            } else {
                result = data;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            result = null;
        }
    });

    return result;
};

/**
 * 在庫更新プロシージャを実行する。(mSetZaikoSagyo)
 * @param date
 * @param materialCode
 * @param thickness
 * @param weight
 * @returns {*}
 */
DatabaseFunc.updateStockData = function (date, materialCode, thickness, weight) {
    $.ajaxSetup({
        cache: false
    });

    var result = null;

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_update_stock_data.php",
        data: {
            "date": date,
            "material_code": materialCode,
            "thickness": thickness,
            "weight": weight
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            // エラーはメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                result = null;
            } else {
                result = data;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            result = null;
        }
    });

    return result;
};

/**
 * 業データに紐付く加工データがあるか確認する。
 * 厳密には作業番号・作業年に該当する作業データのHACCHUSAKI_CODEに
 * 値があれば紐付かれていると判断する。
 *
 * @param {string} workNo 作業番号
 * @param {string} workYear 作業年
 * @return {boolean} 存在すればtrue 存在しなければfalse
 */
DatabaseFunc.isExistProcessingData = function(workNo, workYear) {

    $.ajaxSetup({
        cache: false
    });

    var result = false;

    $.ajax({
        type: "post",
        url: "/common/ajax_function_db/ajax_is_exist_processing_data.php",
        data: {
            "work_no": workNo,
            "work_year": workYear,
        },
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            // エラーはメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                result = false;
            } else {
                result = data.is_exist;
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            result = false;
        }
    });

    return result;

};

/**
 * 現時点での消費税を取得する。
 *
 * @return {string}
 */
DatabaseFunc.getNowSalesTax = function () {

    $.ajaxSetup({
        cache: false
    });

    var result = null;

    $.ajax({
        type: "get",
        url: "/common/ajax_function_db/ajax_get_now_sales_tax.php",
        async: false,	// 非同期無効
        crossDomain: false,
        dataType: "json",
        scriptCharset: 'utf-8',
        success: function (data) {
            // エラーはメッセージを表示
            if (data.result === "error") {
                alert(data.error_message);
                result = null;
            } else {
                result = parseFloat(data.sales_tax);
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown + XMLHttpRequest.responseText);
            result = null;
        }
    });

    return result;
};

/**
 * 納品書印刷ダイアログを表示する
 * @param mode
 * @param slipNo
 * @param callback
 * @private
 */
DatabaseFunc._openDeliveryPdfModal = function(mode, slipNo, callback) {

    function changeBackground() {
        let target = document.getElementById("delivery_pdf_fade_layer");
        let maxheightA = Math.max(document.body.clientHeight, document.body.scrollHeight)
        let maxheightB = Math.max(document.documentElement.scrollHeight, document.documentElement.clientHeight)
        let MaxHeight = Math.max(maxheightA,maxheightB);
        target.style.height = MaxHeight+"px";
        let maxwidthA = Math.max(document.body.clientWidth, document.body.scrollWidth)
        let maxwidthB = Math.max(document.documentElement.scrollWidth, document.documentElement.clientWidth)
        let MaxWidth = Math.max(maxwidthA, maxwidthB);
        target.style.width = MaxWidth + "px";
        target.style.visibility = "visible";
    }

    /**
     * 背景を元に戻す
     */
    function returnBackground() {
        let target = document.getElementById("delivery_pdf_fade_layer");
        target.style.visibility = "hidden";
    }

    function createIframe(daialogElement, iframeName, url) {
        let newIframe = document.createElement("iframe");
        newIframe.id = iframeName;
        newIframe.name = iframeName;
        newIframe.src = url;
        newIframe.style.width = "100%";
        newIframe.style.height = "100%";
        newIframe.style.border = "none";

        daialogElement.insertBefore(newIframe, daialogElement.firstChild);
    }

    /**
     * IFrameを閉じる
     */
    function closeIframe() {
        let modalDialog = document.getElementById('delivery_pdf_modal_dialog');
        let modalIframe = document.getElementById('deliverPdfIframe');

        modalDialog.removeChild(modalIframe);
    }

    changeBackground();

    let deliveryPdfModal = document.getElementById('delivery_pdf_modal_dialog');

    // 入力された得意先名を取得

    let url = '../order/delivery_pdf_option_modal.php';

    createIframe(deliveryPdfModal, 'deliveryPdfIframe', url + '?mode=' + mode + '&slip_no=' + slipNo);
    deliveryPdfModal.showModal();

    // Xボタン または 未選択
    deliveryPdfModal.onclose = function() {

        callback(this.returnValue);

        closeIframe();
        returnBackground();
    }

}

/**
 * 再計算ダイアログを開く。
 * @param isStock
 * @param recalcDialogData
 * @param callback
 */
DatabaseFunc.openRecalcDialog = function (isStock, recalcDialogData, callback) {

    /**
     * modal表示用に背景を暗くする。
     */
    function changeBackground() {
        let target = document.getElementById("recalc_fade_layer");
        let maxheightA = Math.max(document.body.clientHeight, document.body.scrollHeight)
        let maxheightB = Math.max(document.documentElement.scrollHeight, document.documentElement.clientHeight)
        let MaxHeight = Math.max(maxheightA,maxheightB);
        target.style.height = MaxHeight+"px";
        let maxwidthA = Math.max(document.body.clientWidth, document.body.scrollWidth)
        let maxwidthB = Math.max(document.documentElement.scrollWidth, document.documentElement.clientWidth)
        let MaxWidth = Math.max(maxwidthA, maxwidthB);
        target.style.width = MaxWidth + "px";
        target.style.visibility = "visible";
    }

    /**
     * 背景を元に戻す
     */
    function returnBackground() {
        let target = document.getElementById("recalc_fade_layer");
        target.style.visibility = "hidden";
    }

    function createIframe(daialogElement, iframeName, url) {
        let newIframe = document.createElement("iframe");
        newIframe.id = iframeName;
        newIframe.name = iframeName;
        newIframe.src = url;
        newIframe.style.width = "100%";
        newIframe.style.height = "100%";
        newIframe.style.border = "none";

        daialogElement.insertBefore(newIframe, daialogElement.firstChild);
    }

    /**
     * IFrameを閉じる
     */
    function closeIframe() {
        let modalDialog = document.getElementById('recalc_modal_dialog');
        let modalIframe = document.getElementById('recalcIframe');

        modalDialog.removeChild(modalIframe);
    }

    changeBackground();

    let recalcModalDialog = document.getElementById('recalc_modal_dialog');


    // 入力された得意先名を取得

    let url = '../common/recalc.php';
    if (isStock) {
        url = '../common/recalc_stock.php';
    }

    createIframe(recalcModalDialog, 'recalcIframe', url + '?dialog_data=' + encodeURIComponent(JSON.stringify(recalcDialogData)));
    recalcModalDialog.showModal();

    // Xボタン または 未選択
    recalcModalDialog.onclose = function() {

        callback(this.returnValue);

        closeIframe();
        returnBackground();
    }


};