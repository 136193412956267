/**
 * バリデーションクラス
 *
 * Created by kamata on 2017/03/23.
 */
// バリデーションクラス作成
var ValidateUtil = function () {};

/**
 * 入力値がファイル名として使用できるかチェックを行う。
 *
 * @param {string} fileName チェックするファイル名
 * @return {boolean} 使用可能の場合true
 */
ValidateUtil.valFileName = function (fileName) {

    // 判定
    return !fileName.match( /^.*[(\\|/|:|*|?|"|<|>||)].*$/ )
};

/**
 * 入力値の文字数が指定数以内であるか判定する。
 *
 * @param str
 * @param maxLength
 * @return {boolean}
 */
ValidateUtil.valLimit = function (str, maxLength) {
    // 文字列が指定文字数か判定
    return this.getBytes(str) < maxLength;
};

/**
 * 文字列のバイト数を返す。
 * 全角文字は2バイトとして計算する。
 *
 * @param {string} src 計算を行う文字列
 * @return {number} バイト数
 */
ValidateUtil.getBytes = function (src) {
    var str = src.toString();
    var len = str.length;
    var i, cd, blen = 0;

    for (i = 0; i < len; i++) {
        blen += 2;
        cd = str.charCodeAt(i);
        if (0x20 <= cd && cd <= 0x7e) {
            blen--;
        }
        if (0xff61 <= cd && cd <= 0xff9f) {
            blen--;
        }
    }

    return blen;
};