// JavaScript 共通関数

// 数値文字列からカンマ削除
function comJxDelComma(targetNum) {

  var retStr = ''; // 作成する文字列を格納

  // 先頭の文字から順にループ
  for (var i = 0; i < targetNum.length; i++) {
    if (targetNum.charAt(i) != ',') {
      // カンマ記号でなければコピー
      retStr = retStr + targetNum.charAt(i);
    }
  }

  return retStr;
}

// 文字列バイト数計算
// ※画面はUTFだが、DBはShift-JISなので漢字2バイトで計算
function getBytes(strSrc) {

  // バリデートユーティリティクラスのメソッドを呼び出す
  return ValidateUtil.getBytes(strSrc);

}

// 受注用日付の同期設定
// ty:1:受注日 2:切断日 3:熱処理日 4:加工日 5:納期 6:仕入予定日 7:仕入日
function setOrderSagyoDate(ty) {

  var juchubi = document.getElementById('juchubi');
  var setsubi = document.getElementById('setsubi');
  var netsubi = document.getElementById('netsubi');
  var kakobi = document.getElementById('kakobi');
  var nouki = document.getElementById('nouki');

  var Df = 0;

  if (ty == 1) {
    // 受注日 -> 切断日を同期する

    // 未入力はNG
    if (juchubi.value == '') {
      alert(Message.dateErrorEmpty);
      moveFocusStepLoop('juchubi');
      return false;
    }

    var juv = juchubi.value;

    // 日付を正しい形にする
    var r = DateFunc.convertDate(juv);

    if (r == null) {
      alert(Message.dateErrorInvalidDate);
      moveFocusStepLoop('juchubi');
      return false;
    }

    juchubi.value = r.format('YYYY/MM/DD');

    //今日より300日離れているか確認
    var dff = DateFunc.checkThreeHundredDays(r);
    if (dff == false) {
      moveFocusStepLoop('juchubi');
      return false;
    }

    // 切断日を同期
    setsubi.value = juchubi.value;


  } else if (ty == 2) {
    // 切断日 -> 熱処理日を同じにする / 納期を＋１にする（日曜なら＋２）

    // 未入力OK
    if (setsubi.value == '') {
      return true;
    }

    var stu = setsubi.value;

    // 日付を正しい形にする
    var r = DateFunc.convertDate(stu);
    if (r == null) {
      alert(Message.dateErrorInvalidDate);
      moveFocusStepLoop('setsubi');
      return false;
    }

    setsubi.value = r.format('YYYY/MM/DD');

    //今日より300日離れているか確認
    var dff = DateFunc.checkThreeHundredDays(r);
    if (dff == false) {
      moveFocusStepLoop('setsubi');
      return false;
    }

    //納期を＋１にする（日曜なら＋２）
    var nk = JxGetDateAddDaySunday(setsubi.value);
    nouki.value = nk;

    // 熱処理日を同期
    netsubi.value = setsubi.value;

  } else if (ty == 3) {
    // 熱処理日 -> 納期を＋１（日曜なら＋２）

    // 未入力OK
    if (netsubi.value == '') {
      return true;
    }

    var ntu = netsubi.value;

    // 日付を正しい形にする
    var r = DateFunc.convertDate(ntu);
    if (r == null) {
      alert(Message.dateErrorInvalidDate);
      moveFocusStepLoop('netsubi');
      return false;
    }

    netsubi.value = r.format('YYYY/MM/DD');

    //今日より300日離れているか確認
    var dff = DateFunc.checkThreeHundredDays(r);
    if (dff == false) {
      moveFocusStepLoop('netsubi');
      return false;
    }

    //納期を＋１にする（日曜なら＋２）
    var nk = JxGetDateAddDaySunday(netsubi.value);
    nouki.value = nk;

  } else if (ty == 4) {
    // 加工日

    // 未入力OK（確定時チェック）
    if (kakobi.value == '') {
      return true;
    }

    var kak = kakobi.value;

    // 日付を正しい形にする
    var r = DateFunc.convertDate(kak);
    if (r == null) {
      alert(Message.dateErrorInvalidDate);
      moveFocusStepLoop('kakobi');
      return false;
    }

    kakobi.value = r.format('YYYY/MM/DD');

    //今日より300日離れているか確認
    var dff = DateFunc.checkThreeHundredDays(r);
    if (dff == false) {
      moveFocusStepLoop('kakobi');
      return false;
    }

  } else if (ty == 5) {
    // 納期

    // 必須入力
    if (nouki.value == '') {
      alert(Message.dateErrorEmpty);
      setTimeout(function(){ 
        nouki.focus();
        nouki.select();
      }, 1);
      return false;
    }

    var ntu = nouki.value;

    // 日付を正しい形にする
    var r = DateFunc.convertDate(ntu);
    if (r == null) {
      alert(Message.dateErrorInvalidDate);
      moveFocusStepLoop('nouki');
      return false;
    }

    nouki.value = r.format('YYYY/MM/DD');

    //今日より300日離れているか確認
    var dff = DateFunc.checkThreeHundredDays(r);
    if (dff == false) {
      moveFocusStepLoop('nouki');
      return false;
    }

    // 第1第3土曜、日曜、祝日判定
    var hori = DateFunc.checkHoliday(r);
    if (hori == false) {
      moveFocusStepLoop('nouki');

      return false;
    }

    // 加工日更新


  } else if (ty == 6) {
    // 仕入予定日
    var shiire_YoteiYmd = document.getElementById('shiire_YoteiYmd');

    // 必須入力
    if (shiire_YoteiYmd.value == '') {
      alert(Message.dateErrorEmpty);
      moveFocusStepLoop('shiire_YoteiYmd');
      return false;
    }

    var ntu = shiire_YoteiYmd.value;

    // 日付を正しい形にする
    var r = DateFunc.convertDate(ntu);

    if (r == null) {
      alert(Message.dateErrorInvalidDate);
      moveFocusStepLoop('shiire_YoteiYmd');
      return false;
    }

    shiire_YoteiYmd.value = r.format('YYYY/MM/DD');

    //今日より300日離れているか確認
    var dff = DateFunc.checkThreeHundredDays(r);
    if (dff == false) {
      moveFocusStepLoop('shiire_YoteiYmd');
      return false;
    }

    //仕入予定日が本日より前の日付か確認
    var nowDate = JxchkDateNowDate(shiire_YoteiYmd.value);

    if (nowDate > 0) {
      // 1以上の場合、過去日
      if (confirm('仕入予定日が本日より前の日付になっています。よろしいですか？') == true) {
        // yes
      } else {
        // no
        moveFocusStepLoop('shiire_YoteiYmd');
        return false;
      }
    }

  } else if (ty == 7) {
    // 仕入日
    var shiire_Ymd = document.getElementById('shiire_Ymd');

    // 必須入力
    if (shiire_Ymd.value == '') {
      alert(Message.dateErrorEmpty);
      moveFocusStepLoop('shiire_Ymd');
      return false;
    }

    var ntu = shiire_Ymd.value;

    // 日付を正しい形にする
    var r = DateFunc.convertDate(ntu);

    if (r == null) {
      alert(Message.dateErrorInvalidDate);
      moveFocusStepLoop('shiire_Ymd');
      return false;
    }

    shiire_Ymd.value = r.format('YYYY/MM/DD');

    //今日より300日離れているか確認
    var dff = DateFunc.checkThreeHundredDays(r);
    if (dff == false) {
      moveFocusStepLoop('shiire_Ymd');
      return false;
    }

    //仕入日が本日より前の日付か確認
    var nowDate = JxchkDateNowDate(shiire_Ymd.value);

    if (nowDate > 0) {
      // 1以上の場合、過去日
      if (confirm('仕入日が本日より前の日付になっています。よろしいですか？') == true) {
        // yes
      } else {
        // no
        moveFocusStepLoop('shiire_Ymd');
        return false;
      }
    }
  }

  return true;
}

// 見積→受注時は加工日を納期と連動させる
// ip: 1:角 2:丸 3:リング 4:型
// ty: 1:見積からの遷移 0:通常新規登録
function JxsetMtoJKakoubi(ip, ty) {

  // 見積からのみ実施
  if (ty == 1) {

    var kakobi = document.getElementById('kakobi');
    var nouki = document.getElementById('nouki');

    var r = DateFunc.convertDate(nouki.value);

    kakobi.value = DateFunc.getLastBusinessDay(nouki.value);

  }
}

function checkShiireLock(date) {

  var result = false;

  $.ajax({
    type    : "POST",
    url     : "/charge/frm_shiiretsukilockcheck_ajax.php",
    dataType: "json",
    async: false,
    crossDomain	: false,
    scriptCharset	: 'utf-8',
    data: {
      "ymd": date
    },
    success : function (res) {
      if (res.result == 1) {
        // 追加不可の場合メッセージを表示して終了
        alert(res.message);

        result = true;

      }
    },
    error				: function(XMLHttpRequest, textStatus, errorThrown){
      alert("エラーが発生しました。 :" +errorThrown + XMLHttpRequest.responseText)
      result = true;
    }
  });

  return result;
}

/**
 * 納期blur時処理
 * @param ip 1:角 2:丸 3:リング 4:型
 * @param ty 1:加工日連動あり 0:連動なし
 */
function blurNouki(ip, ty) {
  // 納期チェックが完了したら加工日処理実行
  if (setOrderSagyoDate(5)) {
    JxsetMtoJKakoubi(ip,ty);
  }
}

// 現在より前・後の確認
function JxchkDateNowDate(cDate) {

  // yyyy/mm/dd形式
  if (!cDate.match(/^\d{4}\/[\d]+\/[\d]+$/)) {
    return -1;
  }

  var parts = cDate.split('/');
  var nYear = Number(parts[0]);
  var nMonth = Number(parts[1]) - 1;
  var nDay = Number(parts[2]);

  if (0 <= nMonth && nMonth <= 11 && 1 <= nDay && nDay <= 31) {

    var date1 = new Date(nYear, nMonth, nDay);	// 入力日

    var date2 = new Date();

    var msDiff = date2.getTime() - date1.getTime();
    var daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));

    return daysDiff;
  }

  return -1;

}

function setForcusDate(ty) {

  var juchubi = document.getElementById('juchubi');
  var setsubi = document.getElementById('setsubi');
  var netsubi = document.getElementById('netsubi');
  var kakobi = document.getElementById('kakobi');
  var nouki = document.getElementById('nouki');
  var shiire_YoteiYmd = document.getElementById('shiire_YoteiYmd');
  var shiire_Ymd = document.getElementById('shiire_Ymd');

  if (ty == 1) {
    // 受注日
    focusElementSelect(juchubi);
  } else if (ty == 2) {
    // 切断日
    focusElementSelect(setsubi);
  } else if (ty == 3) {
    // 熱処理日
    focusElementSelect(netsubi);
  } else if (ty == 4) {
    // 加工日
    focusElementSelect(kakobi);
  } else if (ty == 5) {
    // 納期
    focusElementSelect(nouki);
  } else if (ty == 6) {
    // 仕入予定日
    focusElementSelect(shiire_YoteiYmd);
  } else if (ty == 7) {
    // 仕入日
    focusElementSelect(shiire_Ymd);
  }

}

// 日付として妥当性チェック(YYYY/MM/DD形式版)
function JxchkDateFormatFull(cDate) {

  // 5桁以下なら月日のみとみなして年を付加
  if (cDate.length < 6) {
    var ndt = new Date();
    var yy = ndt.getFullYear();

    cDate = yy + '/' + cDate;
  }

  // yyyy/mm/dd形式
  if (!cDate.match(/^\d{4}\/[\d]+\/[\d]+$/)) {
    return false;
  }

  var parts = cDate.split('/');
  var nYear = Number(parts[0]);
  var nMonth = Number(parts[1]) - 1;
  var nDay = Number(parts[2]);

  // 月,日の妥当性チェック
  if (0 <= nMonth && nMonth <= 11 && 1 <= nDay && nDay <= 31) {

    var dt = new Date(nYear, nMonth, nDay);

    if (isNaN(dt)) {
      // 日付型へ変換失敗
      return false;
    } else if (dt.getFullYear() == nYear && dt.getMonth() == nMonth &&
        dt.getDate() == nDay) {
      // 正しい
      return true;
    } else {
      // 日として正しくない
      return false;
    }

  } else {
    // 範囲エラー
    return false;
  }

}

// 日付を YYYY/MM/DD 形式にする
function JxgetDateFormatFull(cDate) {

  var rtn = '';

  // 5桁以下なら月日のみとみなして年を付加
  if (cDate.length < 6) {
    var ndt = new Date();
    var yy = ndt.getFullYear();

    cDate = yy + '/' + cDate;
  }

  // yyyy/mm/dd形式
  if (!cDate.match(/^\d{4}\/[\d]+\/[\d]+$/)) {
    return rtn;
  }

  var parts = cDate.split('/');
  var nYear = Number(parts[0]);
  var nMonth = Number(parts[1]) - 1;
  var nDay = Number(parts[2]);

  // 月,日の妥当性チェック
  if (0 <= nMonth && nMonth <= 11 && 1 <= nDay && nDay <= 31) {

    var dt = new Date(nYear, nMonth, nDay);

    if (isNaN(dt)) {
      // 日付型へ変換失敗
      return rtn;
    } else if (dt.getFullYear() == nYear && dt.getMonth() == nMonth &&
        dt.getDate() == nDay) {
      // 正しい

      nMonth++;

      if (nMonth < 10) {
        nMonth = '0' + nMonth;
      }
      if (nDay < 10) {
        nDay = '0' + nDay;
      }

      rtn = nYear + '/' + nMonth + '/' + nDay;

      return rtn;

    } else {
      // 日として正しくない
      return rtn;
    }

  } else {
    // 範囲エラー
    return rtn;
  }

  return rtn;
}

// 現在から300日離れていないかチェック
// cDate:YYYY/MM/DD形式
function JxchkDatePeriodArea(cDate) {

  // yyyy/mm/dd形式
  if (!cDate.match(/^\d{4}\/[\d]+\/[\d]+$/)) {
    return 0;
  }

  var parts = cDate.split('/');
  var nYear = Number(parts[0]);
  var nMonth = Number(parts[1]) - 1;
  var nDay = Number(parts[2]);

  if (0 <= nMonth && nMonth <= 11 && 1 <= nDay && nDay <= 31) {

    var date1 = new Date(nYear, nMonth, nDay);	// 入力日

    var date2 = new Date();

    var msDiff = date2.getTime() - date1.getTime();
    var daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    daysDiff++;

    return daysDiff;
  }

  return 0;
}

// 日付に加算する、加算された日が日曜なら＋１する
// cDate : 日付(yyyy/mm/dd)
// adday : 加算日数
function JxGetDateAddDaySunday(cDate) {

  var parts = cDate.split('/');
  var nYear = Number(parts[0]);
  var nMonth = Number(parts[1]) - 1;
  var nDay = Number(parts[2]);

  var date1 = new Date(nYear, nMonth, nDay);	// 入力日

  var wk = date1.getDay();

  if (wk == 6) {
    // 現在日が土曜なので＋２
    date1.setDate(date1.getDate() + 2);
  } else {
    // ＋１する
    date1.setDate(date1.getDate() + 1);
  }

  // 型 yyyy/mm/dd形式へ
  var nYear = date1.getFullYear();
  var nMonth = date1.getMonth() + 1;
  var nDay = date1.getDate();

  if (nMonth < 10) {
    nMonth = '0' + nMonth;
  }
  if (nDay < 10) {
    nDay = '0' + nDay;
  }

  rtn = nYear + '/' + nMonth + '/' + nDay;

  return rtn;
}

// 日付として妥当かチェック
// 戻り値:1:OK -1:NG 0:空欄
function chkDateFormat(ckDate) {

  var rtn = 0;

  if (ckDate == '') {
    return rtn;
  }

  if (ckDate.length != 8) {
    return -1;
  }

  var yy = ckDate.substr(0, 4);
  var mm = ckDate.substr(4, 2);
  var dd = ckDate.substr(6, 2);

  var di = new Date(yy, mm - 1, dd);

  if (di.getFullYear() == yy && di.getMonth() == mm - 1 && di.getDate() == dd) {
    return 1;
  } else {
    return -1;
  }

}

// 何曜日か
// -1;NG
function chkDateDay(ckDate) {

  var ck = chkDateFormat(ckDate);

  if (ck != 1) {
    return -1;
  }

  var yy = ckDate.substr(0, 4);
  var mm = ckDate.substr(4, 2);
  var dd = ckDate.substr(6, 2);

  var di = new Date(yy, mm - 1, dd);

  var wk = di.getDay();

  return wk;
}

// 現在日と入力の差（300以上はエラー)
function comDayDiff(ckDate) {

  // 現在日
  var nd = new Date();

  // 入力日
  var yy = ckDate.substr(0, 4);
  var mm = ckDate.substr(4, 2);
  var dd = ckDate.substr(6, 2);

  var di = new Date(yy, mm - 1, dd);

  // 入力日と差
  var msDiff = di.getTime() - nd.getTime();
  var daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));

  return ++daysDiff;
}

// 日付の加算
function onDay(ckDate, addDay) {

  var ck = chkDateFormat(ckDate);

  if (ck != 1) {
    return -1;
  }

  var yy = ckDate.substr(0, 4);
  var mm = ckDate.substr(4, 2);
  var dd = ckDate.substr(6, 2);

  var di = new Date(yy, mm - 1, dd);

  di.setDate(di.getDate() + Number(addDay));

  var year = di.getFullYear();
  var month = di.getMonth() + 1;
  var day = di.getDate();

  month = numDay(month);
  day = numDay(day);

  var str = '' + year + month + day;

  return str;
}

function numDay(ckDate) {

  ckDate += '';
  if (ckDate.length === 1) {
    ckDate = '0' + ckDate;
  }

  return ckDate;
}

// 少数以下０の場合は表示しない
function comJsPointZero(inTxt) {

  var pnt = 0;

  if (inTxt == '') {
    return pnt;
  }

  if (isFinite(inTxt) == false) {
    return pnt;
  }

  var inInt = parseFloat(inTxt, 10);

  var buf = inInt.toFixed(1);
  var lf = buf.substr(buf.length - 1, 1);

  if (lf == '0') {
    // 少数1位は0 -> 少数表示なし

    pnt = inInt.toFixed(0);

  } else {

    buf = inInt.toFixed(2);
    lf = buf.substr(buf.length - 1, 1);

    if (lf == '0') {
      // 少数2位は0 -> 少数1位まで表示

      pnt = inInt.toFixed(1);

    } else {
      // 少数2位も値あり -> 少数2位まで表示

      pnt = inInt.toFixed(2);

    }
  }

  return pnt;
  
}

// 日曜日判定
function JxchkDateSunday(cDate) {

  var rtn = 0;	// 0:通常 1:日曜

  var parts = cDate.split('/');
  var nYear = Number(parts[0]);
  var nMonth = Number(parts[1]) - 1;
  var nDay = Number(parts[2]);

  var nDate = new Date(nYear, nMonth, nDay);

  var yobi = nDate.getDay();	// 日曜 0

  if (yobi == 0) {
    rtn = 1;
  }

  return rtn;
}

// 第１・３土曜かの判断
function JxchkDateYesterday(cDate) {

  var rtn = 0;	// 0:通常 1:第１・３土曜

  var parts = cDate.split('/');
  var nYear = Number(parts[0]);
  var nMonth = Number(parts[1]) - 1;
  var nDay = Number(parts[2]);

  var nDate = new Date(nYear, nMonth, nDay);

  var yobi = nDate.getDay();	// 土曜は 6

  var c = 1;
  for (var i = nDate.getDate(); i > 7; i -= 7)
    c++;

  if (yobi == 6) {
    if (c == 1 || c == 3) {
      rtn = 1;
    }
  }

  return rtn;
}

//祝日判定
// 戻り値：祝日名　（祝日でない場合空)
function comJsGetHoliday(cDate) {

  var parts = cDate.split('/');
  var nYear = Number(parts[0]);
  var nMonth = Number(parts[1]) - 1;
  var nDay = Number(parts[2]);

  var nDate = new Date(nYear, nMonth, nDay);

  var nen, tsuki, hi, yobi;

  // 2007年以前は取得不可
  if (nDate.getFullYear() < 2007) {
    return '';
  }

  nen = nDate.getFullYear();
  tsuki = nDate.getMonth() + 1;
  hi = nDate.getDate();
  yobi = nDate.getDay();

  if (tsuki == 1 && hi == 1) {
    return '元日';
  }
  if (tsuki == 2 && hi == 11) {
    return '建国記念の日';
  }
  if (tsuki == 4 && hi == 29) {
    return '昭和の日';
  }
  if (tsuki == 5 && hi == 3) {
    return '憲法記念日';
  }
  if (tsuki == 5 && hi == 4) {
    return 'みどりの日';
  }
  if (tsuki == 5 && hi == 5) {
    return 'こどもの日';
  }
  if (nen >= 2016 && tsuki == 8 && hi == 11) {
    return '山の日'; //2016-8-11から実施。
  }
  if (tsuki == 11 && hi == 3) {
    return '文化の日';
  }
  if (tsuki == 11 && hi == 23) {
    return '勤労感謝の日';
  }
  if (tsuki == 12 && hi == 23) {
    return '天皇誕生日';
  }
  if (tsuki == 1 && yobi == 1 && 7 < hi && hi < 15) {
    return '成人の日'; //1月第2月曜日;
  }
  if (tsuki == 7 && yobi == 1 && 14 < hi && hi < 22) {
    return '海の日'; //7月第3月曜日
  }
  if (tsuki == 9 && yobi == 1 && 14 < hi && hi < 22) {
    return '敬老の日'; //9月第3月曜日
  }
  if (tsuki == 10 && yobi == 1 && 7 < hi && hi < 15) {
    return '体育の日'; //10月第2月曜日
  }
  if (tsuki == 3 && hi == comJsShunbun(nen)) {
    return '春分の日';
  }
  if (tsuki == 9 && hi == comJsShubun(nen)) {
    return '秋分の日';
  }

  return '';

}

//春分の日判定
function comJsShunbun(nen) {
  if (nen < 1900 || nen > 2099) {
    return 0;
  }
  if (nen % 4 === 0) {
    return nen <= 1956 ? 21 : (nen <= 2088 ? 20 : 19);
  } else if (nen % 4 == 1) {
    return nen <= 1989 ? 21 : 20;
  } else if (nen % 4 == 2) {
    return nen <= 2022 ? 21 : 20;
  } else {
    return nen <= 1923 ? 22 : (nen <= 2055 ? 21 : 20);
  }
}

//秋分の日判定
function comJsShubun(nen) {
  if (nen < 1900 || nen > 2099) {
    return 0;
  }
  if (nen % 4 === 0) {
    return nen <= 2008 ? 23 : 22;
  } else if (nen % 4 == 1) {
    return nen <= 1917 ? 24 : (nen <= 2041 ? 23 : 22);
  } else if (nen % 4 == 2) {
    return nen <= 1946 ? 24 : (nen <= 2074 ? 23 : 22);
  } else {
    return nen <= 1979 ? 24 : 23;
  }
}

/**
 * 担当入力時の長さ確認
 *
 * @param {number} bx 1:得意先 2:届け先 3:材料外注先 4:熱処理外注先
 * @returns {boolean} 正常時true それ以外false
 */
function comJsChkTantoLength(bx) {

  var tanto_new;
  if (bx == 1) {
    tanto_new = document.getElementById('tanto_new');
  } else if (bx == 2) {
    tanto_new = document.getElementById('tdkTanto_new');
  } else if (bx == 3) {
    tanto_new = document.getElementById('gtanto_new');
  } else if (bx == 4) {
    tanto_new = document.getElementById('ngtanto_new');
  } else {
    return false;
  }

  var inByte = getBytes(tanto_new.value);
  if (inByte > 20) {
    alert('担当者欄は半角20文字までです');
    elemFocusSelect(tanto_new);

    return false;
  }

  if (tanto_new.value.match(/\(|\)/)) {
    alert('担当者名に半角かっこは使用できません。');
    elemFocusSelect(tanto_new);

    return false;
  }

  return true;
}

// 熱処理外注の選択必須チェック
function comJsChkNetsuMustSelect() {

  var netsu_code = document.getElementById('netsu_code');	// 熱処理コード(SELECT BOX)
  var cknetgai = document.getElementById('cknetgai');	// 熱処理外注使用(CHECK BOX)
  var ngai_cd = document.getElementById('ngai_cd');	// 熱処理外注コード(TEXT BOX)
  var ngai_name = document.getElementById('ngai_name');	// 熱処理外注名(LABEL)

  var netsu_text = netsu_code.options[netsu_code.selectedIndex].text;

  if (netsu_text.indexOf('(H)') >= 0) {
    // H系の場合熱処理外注必須

    if (cknetgai.checked == true) {
      // 外注チェックあり
      if (ngai_cd.value == '') {
        // 外注コード空欄
        alert('熱処理外注先が選択されていません。');
        return false;
      }
    } else {
      // チェックなし→エラー
      alert('熱処理外注先が選択されていません。');
      return false;
    }
  }

  return true;
}

// 材料外注先仕入単価のチェック
// bx:0:リング、型、1～3:角・丸の行
function comJsChkGaiShireTanka(bx) {

  if (bx == 0) {
    var gt = document.getElementById('gt');
  } else if (bx == 1) {
    var gt = document.getElementById('gt1');
  } else if (bx == 2) {
    var gt = document.getElementById('gt2');
  } else if (bx == 3) {
    var gt = document.getElementById('gt3');
  } else {
    return false;
  }

  if (gt.value == '') {
    //
  } else {
    if (!isFinite(gt.value)) {
      err_value = Message.orderInputUnitPriceNotNumber;
      alert(err_value);
      setTimeout(function(){ 
        gt.focus();
        gt.select();
      }, 1);
      return false;
    } else {

      // 少数があれば丸める
      gt.value = Math.round(parseFloat(gt.value));

      if (gt.value >= 100000000 || gt.value <= 0) {
        err_value = Message.orderInputUnitPriceOutOfRange;
        alert(err_value);
        setTimeout(function(){ 
          gt.focus();
          gt.select();
        }, 1);
        return false;
      }
    }
  }

  return true;
}

// 数値へカンマ付け（小数点以下対応）
function comJsAddCommaDec(val) {
  return ('' + val).replace(/^([+-]?\d{1,3})((\d{3})+)((\.\d+)?)$/g,
      function(s, p1, p2, p3, p4) {
        return p1 + p2.replace(/(\d{3})/g, ',$1') + p4;
      });
}

/**
 * 数値にカンマを付与する。
 *
 * @param val 数値
 */
function addComma(val) {
  return comJsAddCommaDec(val);
}

/**
 * 数値を指定桁数で四捨五入し、かつカンマを付与する。
 *
 * @param {number | string} val 数値または数値の文字列
 * @param {number} decimal 表示する小数点以下の桁数
 * @return {string} カンマ変換した文字列
 */
function rComma(val, decimal) {
  return comJsAddCommaDec(mRound(val, decimal).toFixed(decimal));
}

/**
 * 数値を指定桁数で四捨五入し、かつカンマを付与する。
 * 小数点以下の0は除去する。
 *
 * @param {number | string} val 数値または数値の文字列
 * @param {number} decimal 表示する小数点以下の桁数
 * @return {string} カンマ変換した文字列
 */
function rCommaDelZero(val, decimal) {
  var commaNum = rComma(val, decimal);
  return commaNum.replace(/\.?0+$/, '');
}

/**
 * 数値を指定桁数で四捨五入し、かつカンマを付与する。(空欄データは変換しないver
 *
 * @param {number | string} val 数値または数値の文字列
 * @param {number} decimal 表示する小数点以下の桁数
 * @return {string} カンマ変換した文字列
 */
function rCommaBlank(val, decimal) {
  if (val == '') {
    return val;
  }
  return comJsAddCommaDec(mRound(val, decimal).toFixed(decimal));
}

// 入力範囲チェック
// id 1:板厚 2:幅（外径）3:長さ（内径）4:単価 5:重量 6:値引き 7:員数
// bx
function comJsChkInputData(id, bx) {

  switch (id) {
    case 1:		// 板厚

      break;
    case 2:		// 幅（外径）

      break;
    case 3:		// 長さ（内径）

      break;
    case 4:		// 単価

      break;
    case 5:		// 重量

      break;
    case 6:		// 値引き

      break;
    case 7:		// 員数

      break;
  }

}

/**
 * 四捨五入を行う。(マイナス値対応)
 *
 * @param {number} num 四捨五入を行う値
 * @param {number} decimal 小数点以下の桁数
 * @returns {Number} 四捨五入を行った値
 */
function mRound(num, decimal) {
  // 小数点が未入力なら0を設定
  if (decimal == undefined) {
    decimal = 0;
  }

  var isMinus = false;
  // マイナス値の場合はフラグを立て一時的にプラスにする
  if (num < 0) {
    isMinus = true;
    num = -num;
  }

  // 小数点桁数分10倍する
  var dNum = Math.pow(10, decimal);

  var result = Math.round(num * dNum) / dNum;

  if (isMinus) {
    result = -result;
  }

  return result;

}

/**
 * 四捨五入を行う。(マイナス値対応)
 * jisRound使用版。
 *
 * @param {number} num 四捨五入を行う値
 * @param {number} decimal 小数点以下の桁数
 * @returns {Number} 四捨五入を行った値
 */
function jmRound(num, decimal) {
  // 小数点が未入力なら0を設定
  if (decimal == undefined) {
    decimal = 0;
  }

  var isMinus = false;
  // マイナス値の場合はフラグを立て一時的にプラスにする
  if (num < 0) {
    isMinus = true;
    num = -num;
  }

  // 小数点桁数分10倍する
  var dNum = Math.pow(10, decimal);

  var result = jisRound(num * dNum) / dNum;

  if (isMinus) {
    result = -result;
  }

  return result;

}

/**
 * 無限ループが発生しないようフォーカスが当てられた要素のonblurイベントを無効化してフォーカスを移動する。
 * @param {string} focusId 移動するID
 * @returns {Boolean} 終了時trueをかえす。
 */
function moveFocus(focusId) {
  var activeElement = document.activeElement;
  var activeBlur = activeElement.onblur;

  var activeElementJ = $(document.activeElement);

  activeElement.onblur = null;

  // jQueryのイベントハンドラーがあれば削除
  var events = $._data(activeElementJ.get(0)).events;
  if (events != undefined && events.blur != undefined) {
    var activeBlurJ = events.blur[0].handler;
    activeElementJ.unbind('blur');
  }

  var focusElement = document.getElementById(focusId);
  focusElement.focus();

  setTimeout(function() {
    activeElement.onblur = activeBlur;

    // jQueryのイベントハンドラー復活処理
    if (activeBlurJ != undefined) {
      activeElementJ.bind('blur', activeBlurJ);
    }
  }, 10);



  return true;
}
;

/**
 * 引数のエレメントに対し非同期でfocusとselectを実施する
 * @param elem
 */
function elemFocusSelect(elem) {
  setTimeout(function () {
    elem.focus();
    elem.select();
  }, 1);
}

/**
 * 引数のエレメントに対し非同期でfocusを実施する
 * @param elem
 */
function elemFocus(elem) {
  setTimeout(function () {
    elem.focus();
  },1);
}

/**
 * 文字数をカウントする。
 * 全角を2文字・半角を1文字でカウントする。
 *
 * @param {String} str カウントする文字列
 * @returns {Number} 文字列のカウント
 */
function getCountChar(str) {
  var len = 0;
  str = escape(str);

  for (var i = 0; i < str.length; i++, len++) {
    if (str.charAt(i) === '%') {
      if (str.charAt(++i) === 'u') {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
}

/**
 * 文字数チェックを行い、超えている場合はアラートを表示しフォーカスを移動する。
 *
 * @param {String} id チェックを行う要素のID
 * @param {type} rowName アラート時に表示する項目名
 * @param {type} maxLength チェックを行う要素の最大文字数(半角)
 * @returns {Boolean} 指定文字数内の場合true
 */
function checkStringCount(id, rowName, maxLength) {

  // idから要素を取得
  var strElement = document.getElementById(id);

  if (!strElement) {
    alert('指定したIDの要素を取得できませんでした。');
    return false;
  }

  // 文字数を取得
  var strLength = getCountChar(strElement.value);

  // 最大文字数を超えていればアラートを表示しフォーカス移動
  if (strLength > maxLength) {
    alert(rowName + 'は半角' + maxLength + '文字までです。');
    elemFocus(strElement);
    return false;
  }

  return true;

}

function checkStringCountStepLoop(id, rowName, maxLength) {

  // idから要素を取得
  var strElement = document.getElementById(id);

  if (!strElement) {
    alert('指定したIDの要素を取得できませんでした。');
    return false;
  }

  // 文字数を取得
  var strLength = getCountChar(strElement.value);

  // 最大文字数を超えていればアラートを表示しフォーカス移動
  if (strLength > maxLength) {
    alert(rowName + 'は半角' + maxLength + '文字までです。');
    moveFocus(id);
    return false;
  }

  return true;
}

function comJsCkBarcode(code) {

  if (code.length != 13)
    return false;

  var regExp = /[^\d]/;
  if (regExp.test(code)) {
    return false;
  }

  var sum = 0;
  for (var i = 1; i < 12; i += 2) {
    sum += parseInt(code.substr(i, 1));
  }
  sum *= 3;

  for (var i = 0; i < 12; i += 2) {
    sum += parseInt(code.substr(i, 1));
  }

  var d = sum % 10;
  var x = (10 - d) % 10;
  return (code.substr(12, 1) == x);
}

// 仕上り寸法テキストを作成する(basMitsu.bas)
// $kcd;形状コード種 (1;角・型(角)、3:丸・型(丸)、2:リング)
// $sita :板厚
// $sHaba:幅
// $snaga:長さ
function comJsMakeShiagariTxt(kcd, sita, sHaba, snaga) {

  var sKmemo = '';

  switch (kcd) {
    case 1:	// 角
      sKmemo = '仕 ' + sita + '×' + sHaba + '×' + snaga;
      break;
    case 2:	// リング
      sKmemo = '仕 ' + sita + '×' + sHaba + 'φ×' + snaga + 'φ';
      break;
    case 3:	// 丸
      sKmemo = '仕 ' + sita + '×' + sHaba + 'φ';
      break;
  }

  return sKmemo;
}

/**
 *  文字列を指定文字数で省略する。
 *  半角1文字、全角2文字でカウント。
 *
 * @param {String} str トリムする文字列
 * @param {number} byteSize 表示する文字数
 * @returns {String} トリムを行った文字列
 */
function trimStr(str, byteSize) {
  var byte = 0, trimStr = '';
  for (var j = 0, len = str.length; j < len; j++) {
    str.charCodeAt(j) < 0x100 ? byte++ : byte += 2;
    trimStr += str.charAt(j);
    if (byte >= byteSize) {
      trimStr = trimStr.substr(0, j - 2) + '…';
      break;
    }
  }
  return trimStr;
}

/**
 * 指定の要素の背景色を変更する
 *
 * @param {string} elementId 変更を行う要素のID
 * @param {string} backgroundColor 変更を行う背景色
 * @param {string} foreColor 変更を行う文字色
 * @returns {boolean} 要素が見つからなかった場合false 正常終了時true
 */
function addBackGroundColor(elementId, backgroundColor, foreColor) {

  var target = document.getElementById(elementId);

  // 見つからなかった場合はfalseを返す
  if (!target) {
    return false;
  }

  // 背景色と文字色を変更する
  target.style.backgroundColor = backgroundColor;
  target.style.color = foreColor;

  return true;
}

/**
 * 背景色をフォーカス時用に変更する。
 *
 * @param {string} elementId 変更を行う要素のID
 * @returns {boolean} 要素が見つからなかった場合false 正常終了時true
 */
function addBackGroudColorFocus(elementId) {

  // 背景色を青・文字色を白に変更
  var result = addBackGroundColor(elementId, 'blue', 'white');

  return result;
}

/**
 * 背景色を削除する。
 *
 * @param {string} elementId 変更を行う要素のID
 * @returns {boolean} 要素が見つからなかった場合false 正常終了時true
 */
function removeBackGroundColor(elementId) {

  var target = document.getElementById(elementId);

  // 見つからなかった場合はfalseを返す
  if (!target) {
    return false;
  }

  // 背景色を戻す
  target.style.backgroundColor = '';
  target.style.color = '';

  return true;

}

/**
 * カンマ付き数値文字列を数値に変換する。
 *
 * @param {string} str 数値文字列
 * @return {Number | null} 変換に成功時数値 失敗時null
 */
function convertLocaleStringToInteger(str) {

  if (str === '') {
    str = '0';
  }

  var number = parseInt((str + '').replace(/^\s+|\s+$|,/g, ''), 10);

  if (isNaN(number)) {
    // alert("文字列から数値に変換できませんでした。");
    return null;
  }

  return number;
}

/**
 * カンマ付き数値文字列を数値に変換する。
 * convertLocaleStringToIntegerの短縮
 *
 * @param {string} str 数値文字列
 * @return {Number | null} 変換に成功時数値 失敗時null
 */
function csti(str) {
  return convertLocaleStringToInteger(str);
}

/**
 * カンマ付き数値文字列を小数付数値に変換する。
 *
 * @param {string} str 数値文字列
 * @return {Number | null} 変換に成功時数値 失敗時null
 */
function convertLocaleStringToFloat(str) {

  if (str === '') {
    str = '0';
  }

  var number = parseFloat((str + '').replace(/^\s+|\s+$|,/g, ''));

  if (isNaN(number)) {
    // alert("文字列から数値に変換できませんでした。");
    return null;
  }

  return number;
}

/**
 * カンマ付き数値文字列を数値に変換する。
 * convertLocaleStringToFloatの短縮
 *
 * @param {string} str 数値文字列
 * @return {Number | null} 変換に成功時数値 失敗時null
 */
function cstf(str) {
  return convertLocaleStringToFloat(str);
}


/**
 * 半角カタカナを全角カタカナに変換
 * http://qiita.com/hrdaya/items/291276a5a20971592216
 * を使用
 *
 * @param {String} str 変換したい文字列
 */
function convertHankana2Zenkana(str) {
  var kanaMap = {
    'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
    'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
    'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
    'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
    'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
    'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
    'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
    'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
    'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
    'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
    'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
    'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
    'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
    'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
    'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
    'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
    'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
    'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
    '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・',
  };

  var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
  return str.replace(reg, function(match) {
    return kanaMap[match];
  }).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
}

/**
 * 半角から全角に置き換え
 *
 * チルダは全角チルダに変換
 *
 * http://qiita.com/hrdaya/items/291276a5a20971592216
 * を使用
 *
 * @param {String} str 変換したい文字列
 * @param {Boolean} tilde チルダ falseを指定した場合は変換なし
 * @param {Boolean} mark 記号 falseを指定した場合は変換なし
 * @param {Boolean} hankana 半角カナ記号 falseを指定した場合は変換なし
 * @param {Boolean} space スペース falseを指定した場合は変換なし
 * @param {Boolean} alpha 英字 falseを指定した場合は変換なし
 * @param {Boolean} num 数字 falseを指定した場合は変換なし
 */
function convertHanToZen(str, tilde, mark, hankana, space, alpha, num) {
  if (alpha !== false) {
    str = str.replace(/[A-Za-z]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 65248);
    });
  }
  if (num !== false) {
    str = str.replace(/\d/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 65248);
    });
  }
  if (mark !== false) {
    var reg = /[!"#\$%&'\(\)\*\+,\-\.\/:;<=>\?@\[\\\]\^_`\{\|\}]/g;
    str = str.replace(reg, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 65248);
    });
  }
  if (tilde !== false) {
    str = str.replace(/~/g, '～');
  }
  if (space !== false) {
    str = str.replace(/ /g, '　');
  }
  if (hankana !== false) {
    var map = {'｡': '。', '､': '、', '｢': '「', '｣': '」', '･': '・'};
    var reg = new RegExp('(' + Object.keys(map).join('|') + ')', 'g');
    str = str.replace(reg, function(match) {
      return map[match];
    });
  }
  return str;
};

/**
 * 文字列の先頭および末尾の連続する「半角空白・タブ文字・全角空白」を削除します
 * http://www.m-bsys.com/code/trim
 * を使用
 *
 * @return {string}
 */
String.prototype.trimZen = function() {
  return this.replace(/^[\s　]+|[\s　]+$/g, '');
};

/**
 * 小数点第n位までの表示に変換する
 * @param number
 * @param n
 * @returns
 */
function setFloatFormat(number, n) {
  var _pow = Math.pow(10, n);
  var val = new String(Math.round(number * _pow) / _pow);
  if (val.indexOf('.0') > 0) {
    return val.replace('.0', '');
  } else {
    return val;
  }
}

/**
 * 見積書・受注書をメール送信する
 * @param ty       :1:受注 2:見積
 * @param keijo    :01:角 02:丸 03:リング 11:型切(角) 12:型切(丸)
 * @param tcd      :取引先コード
 * @param sno      :作業番号(見積の場合は0)
 * @param snen     :作業年(見積の場合は0)
 * @param juchu    :受注番号(見積の場合は0)
 * @param mitumori :見積番号(受注の場合は0)
 * @param eda
 * @param kako1
 * @param kako2
 * @param kako3
 * @param akakuro
 * @param sflg
 */
function mailSendOrderEstimate(
    ty, keijo, tcd, sno, snen, juchu, mitumori, eda, kako1, kako2, kako3,
    akakuro, sflg) {

  // 見積種別取得
  var estimateTypeElement = document.getElementById('estimateType');
  var estimateType = '0';
  if (estimateTypeElement !== null) {
    estimateType = document.getElementById('estimateType').value;
  }

  window.open('/common/mailSendOrderEstimate.php?ty=' + ty + '&keijo=' + keijo +
      '&tcd=' + tcd + '&sno=' + sno + '&snen=' + snen + '&juchu=' + juchu +
      '&mitumori=' + mitumori + '&eda=' + eda + '&kako1=' + kako1 + '&kako2=' +
      kako2 + '&kako3=' + kako3 + '&akakuro=' + akakuro + '&sflg=' + sflg +
      '&estimateType=' + estimateType, '',
      'width=1180,height=655,scrollbars=yes,resizable=yes');
}

/**
 * ファイルパス関連の処理
 * @type {{protocol: function(*): *, domain: function(*): *, topLevelDomain: function(*=): *, dirname: function(*): *, rootRelative: function(*): *, fullBasename: function(*=): string, basename: function(*=): *, filename: function(*=): *, extension: function(*=): *, param: function(*): *, params: function(*=), hash: function(*=): string}}
 */
var pathinfo = {
  // プロトコル
  protocol: function(path) {
    var result = path.replace(/\\/g, '/').match(/^([^/]+):/, '');
    if (result) {
      result = result[1];
    } else {
      result = '';
    }
    return result;
  },

  // ドメイン名
  domain: function(path) {
    var result = path.replace(/\\/g, '/').match(/\/\/([^/]*)/);
    if (result) {
      result = result[1];
    } else {
      result = '';
    }
    return result;
  },

  // トップレベルドメイン名
  topLevelDomain: function(path) {
    var result = this.domain(path).match(/\.([^.]+)$/);
    if (result) {
      result = result[1];
    } else {
      result = '';
    }
    return result;
  },

  // 直前のディレクトリ名まで
  dirname: function(path) {
    var result = path.replace(/\\/g, '/').replace(/\/[^/]*$/, '');
    if (result.match(/^[^/]*\.[^/\.]*$/)) {
      result = '';
    }
    return result;
  },

  // ルート相対
  rootRelative: function(path) {
    var result = path.replace(/\\/g, '/').replace(/^[^/]*\/\/[^/]*/, '');
    return result;
  },

  // ファイル名以降すべて
  fullBasename: function(path) {
    var result = '';
    if (path) {
      var paths = path.split('/');

      result = paths.pop();
    }
    return result;
  },

  // 拡張子を含めたファイル名
  basename: function(path) {
    var result = this.fullBasename(path).replace(/[\?#].*$/g, '');
    return result;
  },

  // 拡張子を除いたファイル名
  filename: function(path) {
    var result = this.basename(path).replace(/\.[^.]+$/, '');
    return result;
  },

  // 拡張子
  extension: function(path) {
    var result = this.basename(path).match(/\.([^.]+)$/);
    if (result) {
      result = result[1];
    } else {
      result = '';
    }
    return result;
  },

  // パラメータ（文字列）
  param: function(path) {
    var result = path.match(/\?([^?]*)$/);
    if (result) {
      result = result[1];
    } else {
      result = '';
    }
    return result;
  },

  // パラメータ（配列）
  params: function(path) {
    var param = this.param(path),
        params = {};
    if (param !== '') {
      var tmpParams = param.split('&');
      var keyValue = [];
      for (var i = 0, len = tmpParams.length; i < len; i++) {
        keyValue = tmpParams[i].split('=');
        params[keyValue[0]] = keyValue[1];
      }
    }
    return params;
  },

  // ハッシュ
  hash: function(path) {
    var result = '';
    if (path) {
      var paths = path.split('/');
      var matchs = paths.pop().match(/#([^#]+)$/);
      if (matchs && matchs[1]) {
        result = matchs[1];
      }
    }
    return result;
  }

};

/**
 * JIS丸めで四捨五入を実施する。
 * @param {number} num 数値
 * @returns {number} 四捨五入した値
 */
function jisRound(num) {
  var n = parseFloat(num);
  var r = (0 < n) ? n % 2 : (n % 2) + 2;
  return (1 < r) ?  Math.floor(n + 0.5) : Math.ceil(n - 0.5);
}

/**
 * 型切（角）の重量を計算する。
 * @param thickness
 * @param width
 * @param height
 * @param ratio
 * @returns {Number}
 */
function calcCuttingSquareWeight(thickness, width, height, ratio) {
  return jmRound(thickness * width * height * ratio, 2);
}

/**
 * 型切（丸）の重量を計算する。
 * @param thickness
 * @param width
 * @param ratio
 * @param pai
 * @returns {Number}
 */
function calcCuttingCircleWeight(thickness, width, ratio, pai) {
  return jmRound(thickness * (width / 2) * (width / 2) * pai * ratio, 2);
}

/**
 * 角の重量を計算する。
 * @param thickness
 * @param width
 * @param height
 * @param ratio
 * @returns {Number}
 */
function calcSquareWeight(thickness, width, height, ratio) {
  return thickness * width * height * ratio;
}

/**
 * 型切（丸）の重量を計算する。
 * @param thickness
 * @param width
 * @param ratio
 * @param pai
 * @returns {Number}
 */
function calcCircleWeight(thickness, width, ratio, pai) {
  return thickness * (width / 2) * (width / 2) * pai * ratio;
}

/**
 * 無限ループが発生しないようフォーカスが当てられた要素のonblurイベントを無効化してフォーカスを移動する。
 * @param {string} focusId 移動するID
 * @returns {Boolean} 終了時trueをかえす。
 */
function moveFocusStepLoop(focusId) {
  var focusElement = document.getElementById(focusId);
  // console.log(focusElement)
  var activeBlur = focusElement.onblur;
  var focusElementJ = $(document.getElementById(focusId));

  focusElement.onblur = null;

  var events = $._data(focusElementJ.get(0)).events;
  if (events != undefined && events.blur != undefined) {
    var activeBlurJ = events.blur[0].handler;
    focusElementJ.unbind("blur");
  }

  // elemFocusSelect(focusElement);

  focusElement.focus();
  focusElement.select();

  setTimeout(function () {
    focusElement.onblur = activeBlur;

    // jQueryのイベントハンドラー復活処理
    if (activeBlurJ != undefined) {
      focusElementJ.bind("blur", activeBlurJ);
    }
  }, 10);

  return true;
}

/**
 * フォーカスされていたらselectする。
 * @param elem
 */
function focusElementSelect(elem) {

  let activeElement = document.activeElement;

  if(elem === activeElement) {
    elem.select();
  }
}