/*
 * メッセージ関数
 */

/**
 * メッセージを管理する。
 *
 * @type mixed
 */
Message = new function () {

    //*****************************************************************************************
    //  共通メッセージ生成
    //*****************************************************************************************

    /**
     * 数値が入力されていない場合のメッセージを生成。
     *
     * @private
     * @param {String} name 項目名
     * @returns {String} 生成した文字列
     */
    this._numberEmpty = function (name) {
        return name + "が入力されていません。";
    };

    /**
     * 文字列が入力されていない場合のメッセージを生成。
     *
     * @private
     * @param {String} name 項目名
     * @returns {String} 生成した文字列
     */
    this._stringEmpty = function (name) {
        return name + "が入力されていません。";
    };

    /**
     * 入力値が数値以外の場合のメッセージを生成。
     *
     * @private
     * @param {String} name 項目名
     * @returns {String} 生成した文字列
     */
    this._notNumber = function (name) {
        return name + "には、数値を入力してください。";
    };

    this.notNumber = function (name) {
        return this._notNumber(name);
    };

    /**
     * 文字数制限をオーバーした場合のメッセージを生成。
     *
     * @private
     * @param {String} name 項目名
     * @param {String | number} limit 制限文字数
     * @returns {String} 生成した文字列
     */
    this._chercterLimitover = function (name, limit) {
        return name + "欄は半角" + limit + "文字までです。";
    };

    //#176
    //#181 #182 #183 #184 #185 #186 #187 #188
    /**
     * 上記外部呼出用
     * 文字数制限をオーバーした場合のメッセージを生成。
     *
     * @private
     * @param {String} name 項目名
     * @param {String | number} limit 制限文字数
     * @returns {String} 生成した文字列
     */
    this.chercterLimitover = function (name, limit) {
        return this._chercterLimitover(name, limit);
    };

    /**
     * 数値の桁数制限をオーバーした場合のメッセージを生成。
     *
     * @private
     * @param {String} name 項目名
     * @param {String | number} limit 制限文字数
     * @returns {String} 生成した文字列
     */
    this._numberLimitover = function (name, limit) {
        return name + "は" + limit + "桁までです。";
    };

    /**
     * 未選択時のメッセージを生成。
     *
     * @param {String} name
     * @returns {String}
     */
    this._notSelected = function (name) {
        return name + "が選択されていません。";
    };

    /**
     * 範囲外時のメッセージを生成。
     *
     * @param {String} name 項目名
     * @param {String} min 表示する最小値
     * @param {String} max 表示する最大値
     * @param {boolean} minAndOver trueで「以上」 falseで「より大きい」の記号を出力
     * @param {boolean} maxAndLess trueで「以下」 falseで「未満」の記号を出力
     * @returns {String} 生成した文字列
     */
    this._outOfRange = function (name, min, max, minAndOver, maxAndLess) {

        // 以上、以下の値がない場合はfalseを設定
        if (minAndOver == undefined) {
            minAndOver = false;
        }
        if (maxAndLess == undefined) {
            maxAndLess = false;
        }

        var message = "入力値が誤っています。\n（" + min;

        if (minAndOver) {
            message += "<=";
        } else {
            message += "<";
        }

        message += name;

        if (maxAndLess) {
            message += "<=";
        } else {
            message += "<";
        }

        message += max + ")";

        return message;
    };

    this.outOfRange = function (name, min, max, minAndOver, maxAndLess) {
        return this._outOfRange(name, min, max, minAndOver, maxAndLess);
    };

    /**
     * 件数超過時のメッセージを生成。
     *
     * @param {String} num
     * @returns {String}
     */
    this.search_limit_num = function (num) {
        return num + "以上のデータがあります。";
    };

    /**
     * 画面を閉じる時の確認メッセージ
     * @type {string}
     */
    this.confirmClose = "閉じてもよろしいですか？";

    /**
     * 空欄で登録するときの確認メッセージ
     */
    this.confirmEmpty = function(name) {
    	return name + "が空欄ですがよろしいですか？";
    }

    /** #3 19 1 ロック解除
     * 加工キーのコピーに失敗後、画面を閉じる時のメッセージ
     * @type {string}
     */
    this.failedToCopyTheKakouKeyAndClose = "加工キーのコピーに失敗しました。\n画面を閉じます。";


    //*****************************************************************************************
    // 入力関連メッセージ
    //*****************************************************************************************
    /**
     * 続けて入力するか確認のメッセージ
     *
     * @type {String}
     */
    this.confirmContinueInput = "続けて入力しますか？";


    //*****************************************************************************************
    // 日付メッセージ・エラー
    //*****************************************************************************************

    /**
     * 日付が空欄時のエラーメッセージ
     *
     * @type String
     */
    this.dateErrorEmpty = "yyyy/mm/dd形式で入力してください。";

    /**
     * 日付が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.dateErrorNotNumber = "yyyy/mm/dd形式で入力してください。";

    /**
     * 日付が不正時のエラーメッセージ
     *
     * @type String
     */
    this.dateErrorInvalidDate = "yyyy/mm/dd形式で入力してください。";

    //*****************************************************************************************
    // バリデーター メッセージ
    //*****************************************************************************************

    this.validateFilenameError = "ファイル・フォルダ名に使用できない文字が使用されています。";

    //*****************************************************************************************
    // マスタメンテ・スタンプマスタ メッセージ
    //*****************************************************************************************

    //*****************************************************************************************
    // マスタメンテ・ログインマスタ メッセージ
    //*****************************************************************************************

    /**
     * フォルダ名が未入力時のエラーメッセージ
     * @type {String}
     */
    this.loginFolderNameEmpty = this._stringEmpty("フォルダ名");

    /**
     * チェックがない場合のエラーメッセージ
     * @type {string}
     */
    this.stampErrorAllUnchecked = "1つ以上にチェックを入れてください。"

    /**
     * 合わせ印が変更される場合の確認メッセージ
     * @type {string}
     */
    this.stampConfirmChangeCombine = "合わせ印が変更されますがよろしいですか？";

    //*****************************************************************************************
    // 受注入力メッセージ
    //*****************************************************************************************

    /**
     * 受注登録時の確認メッセージ
     *
     * @type String
     */
    this.orderInputConfirmRegist = "登録します。よろしいですか。";

    /**
     * 受注新規入力時の確認メッセージ
     *
     * @type String
     */
    this.orderInputConfirmNewForm = "新規入力を行いますか？";

    /**
     * 受注コピー時の確認メッセージ
     *
     * @type String
     */
    this.orderInputConfirmCopy = "コピーを行いますか？";

    /**
     * 削除用パスワード入力時ダイアログのメッセージ
     *
     * @type String
     */
    this.orderInputDeletePasswordInput = "削除用パスワードを入力してください。";

    /**
     * 受注済の見積データの受注入力時の確認メッセージ
     *
     * @type String
     */
    this.orderInputConfirmOpenOrderAlready = "このデータは受注済みです。別の受注データを作成しますか？";

    /**
     * 更新時プレビュー画面にて金額未定のチェック解除時の確認メッセージ
     *
     * @type {string}
     */
    this.orderInputConfirmUncheckUndecided = "確定後は通常のロック動作に戻ります。よろしいですか？";

    /**
     * 型切にて合計金額が0で確定ボタン押下時の確認メッセージ
     *
     * @type {string}
     */
    this.orderInputConfirmTotalAmountZero = "合計金額が0ですがよろしいですか？";

    /**
     * 型切りにて切代の形状変更時の確認メッセージ
     *
     * @type {string}
     */
    this.orderInputConfirmCuttingChange = "形状が変更されました。切代単価を設定しますか？";

    this.orderInputConfirmChangeShape = "形状を変更してもよろしいですか？";

    /**
     * 型切にて切代次行入力の確認メッセージ
     *
     * @type {string}
     */
    this.orderInputConfirmOnInputKirisiro = "切代を続けて入力しますか?";

    /**
     * 型切にて１行削除の確認メッセージ
     *
     * @type {string}
     */
    this.orderInputConfirmOnDeleteKirisiro = function (num) {
        return "選択された " + num + "番目のデータを削除します。";
    };

    /**
     * 注文請書入力画面を開き画面を受注入力画面を閉じる際の確認メッセージ
     * @type {string}
     */
    this.orderInputConfirmOpenEstimateWindowAndClose = "注文請書入力画面を開きこの画面を閉じます。よろしいですか？";
    
    //*****************************************************************************************
    // 入力チェックメッセージ・切代
    //*****************************************************************************************

    /**
     * 切代にてコピー時必須入力のチェック(板厚)
     *
     * @type {string}
     */
    this.kiridaiItaNoInput = "板厚を入力してください。";

    /**
     * 切代にてコピー時必須入力のチェック(幅)
     *
     * @type {string}
     */
    this.kiridaiTateNoInput = "幅（外径）を入力してください。";

    /**
     * 切代にてコピー時必須入力のチェック(長さ)
     *
     * @type {string}
     */
    this.kiridaiYokoNoInput = "長さ（内径）を入力してください。";

    /**
     * 切代にてコピー時必須入力のチェック(員数)
     *
     * @type {string}
     */
    this.kiridaiInsuNoInput = "員数を入力してください。";

    /**
     * 切代にてコピー時必須入力のチェック(切代単価)
     *
     * @type {string}
     */
    this.kiridaiKTanNoInput = "切代単価を入力してください。";
    
    /**
     * 切代にてコピー時最大行数チェック
     *
     * @type {string}
     */
    this.kiridaiMaxInputError = "登録できる最大数を超えました。";

    //*****************************************************************************************
    // 入力チェックメッセージ・エラー
    //*****************************************************************************************

    /**
     * 社内コメントが文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputCommentLimitOver = this._chercterLimitover("社内コメント", 80);

    /**
     * 摘要が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputSummaryLimitOver = this._chercterLimitover("摘要", 80);

    /**
     * 作業メモが文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputWorkMemoLimitOver = this._chercterLimitover("作業メモ", 60);

    /**
     * 加工内容が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputProcessContentLimitOver = this._chercterLimitover("加工内容", 40);

    /**
     * 材料補足が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSupliLimitOver = this._chercterLimitover("材料補足", 40);

    /**
     * 熱処理補足が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatSupliLimitOver = this._chercterLimitover("熱処理補足", 40);

    /**
     * 決済条件が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputTermOfPaymentiLimitOver = this._chercterLimitover("決済条件", 40);

    /**
     * 受け渡し場所が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputDeliveryPlaceLimitOver = this._chercterLimitover("受渡場所", 40);

    /**
     * 有効期限が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputExpireDateLimitOver = this._chercterLimitover("有効期限", 40);

    /**
     * 備考が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputNotesLimitOver = this._chercterLimitover("備考", 80);

    /**
     * 単価が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUnitPriceEmpty = this._numberEmpty("単価");

    /**
     * 員数が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMemberEmpty = this._numberEmpty("員数");

    /**
     * 長さが無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalEmpty = this._numberEmpty("長さ");

    /**
     * 内径が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputIDEmpty = this._numberEmpty("内径");

    /**
     * 長さ(内径)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalOrIDEmpty = this._numberEmpty("長さ(内径)");

    /**
     * 幅が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalEmpty = this._numberEmpty("幅");

    /**
     * 外径が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputODEmpty = this._numberEmpty("外径");

    /**
     * 幅(外径)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalOrODEmpty = this._numberEmpty("幅(外径)");

    /**
     * 板厚が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputThicknessEmpty = this._numberEmpty("板厚");

    /**
     * 単価(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUnitPrice2LineEmpty = this._numberEmpty("単価(2行目)");

    /**
     * 員数(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMember2LineEmpty = this._numberEmpty("員数(2行目)");

    /**
     * 長さ(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontal2LineEmpty = this._numberEmpty("長さ(2行目)");

    /**
     * 内径(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputID2LineEmpty = this._numberEmpty("内径(2行目)");

    /**
     * 長さ(内径)(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalOrID2LineEmpty = this._numberEmpty("長さ(内径)(2行目)");

    /**
     * 幅(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVertical2LineEmpty = this._numberEmpty("幅(2行目)");

    /**
     * 外径(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputOD2LineEmpty = this._numberEmpty("外径(2行目)");

    /**
     * 幅(外径)(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalOrOD2LineEmpty = this._numberEmpty("幅(外径)(2行目)");

    /**
     * 板厚(2行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputThickness2LineEmpty = this._numberEmpty("板厚(2行目)");

    /**
     * 単価(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUnitPrice3LineEmpty = this._numberEmpty("単価(3行目)");

    /**
     * 員数(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMember3LineEmpty = this._numberEmpty("員数(3行目)");

    /**
     * 長さ(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontal3LineEmpty = this._numberEmpty("長さ(3行目)");

    /**
     * 内径(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputID3LineEmpty = this._numberEmpty("内径(3行目)");

    /**
     * 長さ(内径)(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalOrID3LineEmpty = this._numberEmpty("長さ(内径)(3行目)");

    /**
     * 幅(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVertical3LineEmpty = this._numberEmpty("幅(3行目)");

    /**
     * 外径(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputOD3LineEmpty = this._numberEmpty("外径(3行目)");

    /**
     * 幅(外径)(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalOrOD3LineEmpty = this._numberEmpty("幅(外径)(3行目)");

    /**
     * 板厚(3行目)が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputThickness3LineEmpty = this._numberEmpty("板厚(3行目)");


    /**
     * 比重が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputSGEmpty = this._numberEmpty("比重");

    /**
     * 材料外注単価が無入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontractUnitPriceEmpty = this._numberEmpty("材料外注単価");

    /**
     * 熱処理が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatNotSelected = this._notSelected("熱処理");

    /**
     * 材質が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialNotSelected = this._notSelected("材質");

    /**
     * 受注者が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputContractorNotSelected = this._notSelected("受注者");

    /**
     * 得意先が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputCustomerEmpty = this._notSelected("得意先");

    /**
     * 材料単価が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontractEmpty = this._stringEmpty("材料単価");

    /**
     * 材料単価(2行目)が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontract2LineEmpty = this._stringEmpty("材料単価(2行目)");

    /**
     * 材料単価(3行目)が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontract3LineEmpty = this._stringEmpty("材料単価(3行目)");

    /**
     * 値引きが未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputDiscountEmpty = this._stringEmpty("値引き");

    /**
     * 熱処理重量が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatmentWeightEmpty = this._stringEmpty("熱処理重量");

    /**
     * 熱処理単価が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatmentUnitPriceEmpty = this._stringEmpty("熱処理単価");

    /**
     * スクラップ単価が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputScrapUnitPriceEmpty = this._stringEmpty("スクラップ単価");

    /**
     * 切代単価が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputCuttingSpaceUnitPriceEmpty = this._stringEmpty("切代単価");

    /**
     * 受付方法が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputAcceptanceEmpty = this._stringEmpty("受付方法");

    /**
     * 見積担当が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputEstimatorNotSelected = this._notSelected("見積担当");

    /**
     * 有効期限が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputExpireDateEmpty = this._stringEmpty("有効期限");

    /**
     * 受渡場所が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputDeliveryPlaceEmpty = this._stringEmpty("受渡場所");

    /**
     * 決済条件が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputTermOfPaymentEmpty = this._stringEmpty("決済条件");

    /**
     * 単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUnitPriceNotNumber = this._notNumber("単価");

    /**
     * 員数が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMemberNotNumber = this._notNumber("員数");

    /**
     * 長さが数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalNotNumber = this._notNumber("長さ");

    /**
     * 内径が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputIDNotNumber = this._notNumber("内径");

    /**
     * 長さ(内径)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalOrIDNotNumber = this._notNumber("長さ(内径)");

    /**
     * 幅が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalNotNumber = this._notNumber("幅");

    /**
     * 外径が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputODNotNumber = this._notNumber("外径");

    /**
     * 幅(外径)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalOrODNotNumber = this._notNumber("幅(外径)");

    /**
     * 板厚が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputThicknessNotNumber = this._notNumber("板厚");

    /**
     * 単価(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUnitPrice2LineNotNumber = this._notNumber("単価(2行目)");

    /**
     * 員数(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMember2LineNotNumber = this._notNumber("員数(2行目)");

    /**
     * 長さ(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontal2LineNotNumber = this._notNumber("長さ(2行目)");

    /**
     * 内径(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputID2LineNotNumber = this._notNumber("内径(2行目)");

    /**
     * 長さ(内径)(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalOrID2LineNotNumber = this._notNumber("長さ(内径)(2行目)");

    /**
     * 幅(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVertical2LineNotNumber = this._notNumber("幅(2行目)");

    /**
     * 外径(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputOD2LineNotNumber = this._notNumber("外径(2行目)");

    /**
     * 幅(外径)(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalOrOD2LineNotNumber = this._notNumber("幅(外径)(2行目)");

    /**
     * 板厚(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputThickness2LineNotNumber = this._notNumber("板厚(2行目)");

    /**
     * 単価(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUnitPrice3LineNotNumber = this._notNumber("単価(3行目)");

    /**
     * 員数(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMember3LineNotNumber = this._notNumber("員数(3行目)");

    /**
     * 長さ(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontal3LineNotNumber = this._notNumber("長さ(3行目)");

    /**
     * 内径(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputID3LineNotNumber = this._notNumber("内径(3行目)");

    /**
     * 長さ(内径)(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalOrID3LineNotNumber = this._notNumber("長さ(内径)(3行目)");

    /**
     * 幅(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVertical3LineNotNumber = this._notNumber("幅(3行目)");

    /**
     * 外径(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputOD3LineNotNumber = this._notNumber("外径(3行目)");

    /**
     * 幅(外径)(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalOrOD3LineNotNumber = this._notNumber("幅(外径)(3行目)");

    /**
     * 板厚(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputThickness3LineNotNumber = this._notNumber("板厚(3行目)");

    /**
     * 比重が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputSGNotNumber = this._notNumber("比重");

    /**
     * 材料単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontractNotNumber = this._notNumber("材料単価");

    /**
     * 材料単価(2行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontract2LineNotNumber = this._notNumber("材料単価(2行目)");

    /**
     * 材料単価(3行目)が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontract3LineNotNumber = this._notNumber("材料単価(3行目)");

    /**
     * 値引きが数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputDiscountNotNumber = this._notNumber("値引き");

    /**
     * 熱処理重量が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatmentWeightNotNumber = this._notNumber("熱処理重量");

    /**
     * 熱処理単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatmentUnitPriceNotNumber = this._notNumber("熱処理単価");

    // ##132
    /**
     * スクラップ重量が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputScrapjuryoNotNumber = this._notNumber("スクラップ重量");

    /**
     * orderInputScrapUnitPriceOutOfRangeJスクラップ単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputScrapUnitPriceNotNumber = this._notNumber("スクラップ単価");

    /**
     * 切代単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputCuttingSpaceUnitPriceNotNumber = this._notNumber("切代単価");

    /**
     * 切代チェックありで未入力の場合
     *
     * @type String
     */
    this.orderInputCuttingNoInputAll = "切代を登録してください。";


    /**
     * 見積書番号が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputEstimateNotNumber = this._notNumber("見積書番号");

    /**
     * 材料外注単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontractUnitPriceNotNumber = this._notNumber("材料外注単価");

    /**
     * 材料外注先が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontractorNotSelected = this._notSelected("材料外注先");

    /**
     * 熱処理外注先が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatSubcontractorNotSelected = this._notSelected("熱処理外注先");

    /**
     * 板厚が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputThicknessOutOfRange = this._outOfRange("板厚", "０", "１００００");

    /**
     * 幅が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalOutOfRange = this._outOfRange("幅", "０", "１００００");

    /**
     * 外径が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputODOutOfRange = this._outOfRange("外径", "０", "１００００");

    /**
     * 幅(外径)が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputVerticalOrODOutOfRange = this._outOfRange("幅(外径)", "０", "１００００");

    /**
     * 長さが範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalOutOfRange = this._outOfRange("長さ", "０", "１００００");

    /**
     * 内径が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputIDOutOfRange = this._outOfRange("内径", "０", "１００００");

    /**
     * 長さ(内径)が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHorizontalOrIDOutOfRange = this._outOfRange("長さ(内径)", "０", "１００００");

    /**
     * 員数が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMemberOutOfRange = this._outOfRange("員数", "０", "１０００００");

    /**
     * 単価が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUnitPriceOutOfRange = this._outOfRange("単価", "０", "１００００００００");

    /**
     * 値引きが範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputDiscountOutOfRange = this._outOfRange("値引き", "－１００００００００", "１００００００００");

    /**
     * 熱処理単価が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatUnitPriceOutOfRange = this._outOfRange("熱処理単価", "０", "１００００００００", true);

    /**
     * 熱処理重量が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputHeattreatWeightOutOfRange = this._outOfRange("熱処理重量", "０", "１００００００００");

    // ##132
    /**
     * スクラップ重量が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputScrapJuryoOutOfRange = this._outOfRange("スクラップ重量", "－１０００００", "１０００００");

    /**
     * スクラップ単価が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputScrapUnitPriceOutOfRange = this._outOfRange("スクラップ単価", "－１００００００００", "１００００００００");

    /**
     * 切代単価が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputCuttingSpaceUnitPriceOutOfRange = this._outOfRange("切代単価", "０", "１００００００００", true);

    /**
     * 切代単価が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.krscOrderInputCuttingSpaceUnitPriceOutOfRange = this._outOfRange("切代単価", "０", "１００００００００");

    /**
     * 材料外注単価が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputMaterialSubcontractUnitPriceOutOfRange = this._outOfRange("材料外注単価", "０", "１００００００００");

    /**
     * アップロード失敗時のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUploadFailure = "アップロードに失敗しました。";

    /**
     * 対象見積を選択せずファイルをアップロードした時のエラーメッセージ
     *
     * @type String
     */
    this.orderInputUploadNotSelectEstimate = "対象見積を選択してください。";

    /**
     *  パスワード不一致時のエラーメッセージ
     *
     * @type String
     */
    this.orderInputNotMatchPassword = "正しいパスワードを入力してください。";

    /**
     *  アップロードファイル拡張子が対象外の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputNotMatchExtension = "対象ファイルはjpg,pngのみです。";

    /**
     * 内径が外径以上の場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputIDAndOverOD = "内径は外径より小さい値を入力してください。";

    /**
     * 見積書番号の桁数が一致しない場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputEstimateNumberOfDigit = "見積書番号の桁が間違っています。";

    /**
     * 得意先コードが見つからなかった場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputCustomerCodeNotFound = "得意先コードがない、又は使用禁止になっています。";

    /**
     * 外注コードが見つからなかった場合のエラーメッセージ
     *
     * @type String
     */
    this.orderInputSubcontractCodeNotFound = "この外注コードは有りません。";

    /**
     * 納期が受注日より前になっている場合のエラーメッセージ
     *
     * @type {string}
     */
    this.orderInputDeliveryDateEarly = "納期が受注日より前の日付になっています。"

    /**
     * 加工詳細入力が行われていない場合のエラーメッセージ
     * @type {string}
     */
    this.orderInputNotEnteredProceccing = "詳細入力ホタンから詳細を入力してください。";

    /**
     * 外注加工時に外注先が一致しない場合のエラーメッセージ
     * @type {string}
     */
    this.orderInputMismatchOutProcessingCode = "外注加工時の外注先の指定は1行目と同じにしてください。";

    /**
     * 同じ見積Noのウィンドウがすでに開かれている場合のエラーメッセージ
     * @type {string}
     */
    this.estimateOpenWindowAlready = "すでに内容変更・コピー・受注入力・見積作成のいずれかのウィンドウが開かれています。";

    /**
     * あえてでなく加工なしになっている場合のエラーメッセージ
     * @type {string}
     */
    this.orderInputNotProcessing = "加工ありになっていません。\n加工ありにするか「加工なし登録」にチェックを入れてください。";

    //*****************************************************************************************
    // 赤黒入力メッセージ
    //*****************************************************************************************

    /**
     * リセットボタン押下時の確認メッセージ
     *
     * @type String
     */
    this.akakuroConfirmReset = "入力項目を画面読み込み時の状態に戻しますか？";

    /**
     * 未確定で閉じるボタン押下時の確認メッセージ
     *
     * @type String
     */
    this.akakuroConfirmCloseWindow = "確定していませんがウィンドウを閉じてよろしいですか？";

    /**
     * 新規入力ボタン押下時の確認メッセージ
     * @type {string}
     */
    this.akakuroConfirmNewInput = "新規入力を行いますか？";

    /**
     * 登録ボタンを押下し行が追加された際の確認メッセージ
     * @type {string}
     */
    this.akakuroConfirmContinue = "続けて入力しますか？";


    //*****************************************************************************************
    // 赤黒入力チェックメッセージ・エラー
    //*****************************************************************************************

    /**
     * 板厚が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroThicknessNotNumber = this._notNumber("板厚");

    /**
     * 板厚が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroThicknessOutOfRange = this._outOfRange("板厚", "０", "１００００", true, false);

    /**
     * 幅が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroVerticalNotNumber = this._notNumber("幅");

    /**
     * 幅が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroVerticalOutOfRange = this._outOfRange("幅", "０", "１００００", true);

    /**
     * 長さが数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroHorizontalNotNumber = this._notNumber("長さ");

    /**
     * 長さが範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroHorizontalOutOfRange = this._outOfRange("長さ", "０", "１００００", true);

    /**
     * 員数が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroMemberNotNumber = this._notNumber("員数");

    /**
     * 員数が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroMemberOutOfRange = this._outOfRange("員数", "-１０００００", "１０００００");

    /**
     * 員数が0場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroMemberZero = "員数には0は入力できません。";

    /**
     * 重量が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroWeightNotNumber = this._notNumber("重量");

    /**
     * 重量が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroWeightOutOfRange = this._outOfRange("重量", "-１００００００", "１００００００");

    /**
     * 単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroUnitPriceNotNumber = this._notNumber("単価");

    /**
     * 単価が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroUnitPriceOutOfRange = this._outOfRange("単価", "-１００００００００", "１００００００００");

    /**
     * 加工賃が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroProcessingFeesNotNumber = this._notNumber("加工賃");

    /**
     * 加工賃が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroProcessingFeesOutOfRange = this._outOfRange("加工賃", "-１０００００００００", "１０００００００００");

    /**
     * 取引先コードが入力されていない場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroCustomerCodeNotInput = this._notSelected("取引先");

    /**
     * 取引先がそんざいしないか使用禁止の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroCustomerCodeError = "入力した取引先は存在しないか使用禁止になっています。";

    /**
     * 受注者が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroContractorNotSelected = this._notSelected("受注者");

    /**
     * 形状が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroShapeNotSelected = this._notSelected("形状");

    /**
     * 材質が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroMaterialNotSelected = this._notSelected("材質");

    /**
     * 注文番号が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroOrderNumberLimitOver = this._chercterLimitover("注文番号", 30);

    /**
     * 作業メモが文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroWorkMemoLimitOver = this._chercterLimitover("作業メモ", 60);

    /**
     * 社内コメントが文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroCommentLimitOver = this._chercterLimitover("社内コメント", 80);

    /**
     * 摘要が文字数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroSummaryLimitOver = this._chercterLimitover("摘要", 80);

    /**
     * 赤黒入力モード時、1行目の金額がマイナスでない場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroCheckAmount1LineError = "1行目は金額がマイナスになるように入力してください。";

    /**
     * 赤黒入力モード時、2行目の金額がプラスでない場合のエラーメッセージ
     *
     * @type String
     */
    this.akakuroCheckAmount2LineError = "2行目は金額がプラスになるように入力してください。";

    //*****************************************************************************************
    // 受注一覧・作業データ・見積データ検索メッセージ
    //*****************************************************************************************

    /**
     * 検索条件をクリアする際の確認メッセージ
     *
     * @type String
     */
    this.freeSearchConfirmClearForm = "検索条件を全てクリアしますか？";

    /**
     * 作業データ検索の結果件数が0件の場合の確認メッセージ
     *
     * @type String
     */
    this.freeSearchWorkDataNotFound = "該当する作業データが見つかりません。";

    /**
     * 見積検索の結果件数が0件の場合の確認メッセージ
     *
     * @type String
     */
    this.freeSearchEstimateDataNotFound = "該当する見積データが見つかりません。";

    /**
     * 見積検索の検索条件がない場合の確認メッセージ
     * @type {string}
     */
    this.estimateSearchConfirmNotCompletedCondition = "検索条件が入力されていません。\n検索を続行しますか？";

    /**
     * その他取引先の見積データを受注入力しようとした際のエラーメッセージ
     * @type {string}
     */
    this.estimateSearchOtherCustomer = "この取引先は受注入力できません。取引先の登録を行ってください。";

    //*****************************************************************************************
    // 受注一覧・作業データ・見積データ検索メッセージ・エラー
    //*****************************************************************************************

    /**
     * すでに同じ作業番号の赤黒入力画面が開かれている場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchAkakuroWindowAlreadyOpen = "同じ作業番号の赤黒入力画面が開かれています。";

    /**
     * すでに同じ作業番号の内容変更画面が開かれている場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchEditWindowAlreadyOpen = "同じ作業番号の内容変更画面が開かれています。";


    /**
     * 取消し処理済の作業データを変更しようとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchEditDeletedData = "このデータは削除済みです。";

    /**
     * 返品処理済の作業データを変更しようとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchEditReturnedData = "このデータは返品処理済みです。";

    /**
     * 締め処理済の作業データを変更しようとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchEditClosingData = "このデータは締め処理済みのため変更できません。";

    /**
     * 納品済の作業データを変更しようとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchEditCompletedDeliveryData = "納品完了済みのため変更できません。";

    /**
     * 変更禁止の作業データを変更しようとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchEditProhibitionData = "このデータは変更できません。";

    /**
     * ロック中の作業データを変更しようとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchEditLockData = "このデータは変更処理によってロックされています。";

    /**
     * 取消し処理済の作業データに取消処理を行おうとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchDeleteDeletedData = "このデータは削除済みです。";

    /**
     * 締め処理済の作業データに取消処理を行おうとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchDeleteClosingData = "このデータは締め処理済みのため取消できません。";

    /**
     * 変更禁止の作業データに取消し処理を行おうとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchDeleteProhibitionData = "このデータは取消できません。";

    /**
     * ロック中の作業データに取消し処理を行おうとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchDeleteLockData = "このデータは変更処理によってロックされています。";

    /**
     * 赤黒管理番号が設定されていない赤黒入力データに取消し処理を行おうとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchDeleteAkakuroControlNoData = "赤黒管理番号が設定されていないため、このデータは取り消しできません。";

    /**
     * 赤黒データに運賃入力を行おうとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchInputChargeAkakuroData = "このデータに対する運賃等の入力はできません。";

    /**
     * 赤黒データにコピーを行おうとした場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchCopyAkakuroData = "赤黒データはコピー出来ません。";

    /**
     * 締め日にチェックが入っていない場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchNotCheckedClosingDate = "締日のチェックを入れてください。";

    /**
     * 見積書を作成するデータに一つもチェックが入っていない場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchNotCheckedCreateEstimateData = "見積書を作成するデータを選択してください。";

    /**
     * 注文請書を作成するデータに一つもチェックが入っていない場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchNotCheckedCreateConfirmationOrderData = "注文請書を作成するデータを選択してください。";

    /**
     * 見積書を作成するデータの取引先が一致しなかった場合のエラーメッセージ
     *
     * @type String
     */
    this.freeSearchEstimateDataNotMatchCustomer = "取引先名が同じデータを選択してください。";

    /**
     * 見積検索にて検索結果0件でのPDF・CSVの出力時のエラーメッセージ
     * @type {string}
     */
    this.estimateSearchNotOutputData = "出力できる項目がありません。";

    //*****************************************************************************************
    // 金額が桁数制限をオーバーした場合のエラーメッセージ
    //*****************************************************************************************

    /**
     * 金額が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.kingakuNumberLimitOver = this._numberLimitover("金額", 10);

    /**
     * 金額（材料）が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.zr_kingakuNumberLimitOver = this._numberLimitover("金額（材料）", 10);

    /**
     * 金額（切代）が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.kr_kingakuNumberLimitOver = this._numberLimitover("金額（切代）", 10);

    /**
     * 金額（切代上段）が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.jo_kr_kingakuNumberLimitOver = this._numberLimitover("金額（切代上段）", 10);

    /**
     * 金額（切代下段）が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.ge_kr_kingakuNumberLimitOver = this._numberLimitover("金額（切代下段）", 10);

    /**
     * 金額（スクラップ）が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.sc_kingakuNumberLimitOver = this._numberLimitover("金額（スクラップ）", 10);

    /**
     * 金額（熱処理）が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.nt_kingakuNumberLimitOver = this._numberLimitover("金額（熱処理）", 10);

    /**
     * 一個当り小計が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.shokei_kingakuNumberLimitOver = this._numberLimitover("一個当り小計", 10);

    /**
     * 合計金額が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.gokei_kingakuNumberLimitOver = this._numberLimitover("合計金額", 10);

    /**
     * 金額（材料外注先）が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.gaiKin_kingakuNumberLimitOver = this._numberLimitover("金額（材料外注先）", 10);

    /**
     * 金額合計が桁数制限をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.kingo_kingakuNumberLimitOver = this._numberLimitover("金額合計", 10);

//*****************************************************************************************
// 注文管理メッセージ・エラー
//*****************************************************************************************
    this.chargeYearNotNumber = this._notNumber("年");

    /**
     * 締日コードが選択されていない場合のエラーメッセージ
     *
     * @type String
     */
    this.simeCodeNotSelected = this._notSelected("締日");

//*****************************************************************************************
// 請求入金管理・入金入力 メッセージ
//*****************************************************************************************

    /**
     * 取引先コメント・集金区分が入力されていない場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentDivisionAndCommentNotInput = "取引先コメント・集金区分が入力されていません。";

    /**
     * 1900年より前の日付が入力された場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentBeforeTheYear1900 = "1900年以上の年を入力してください。";

    /**
     * 入金金額が入力されていない場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentInputPaymentEmpty = this._numberEmpty("入金金額");

    /**
     * 入金金額が数値でない場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentInputPaymentNotNumber = this._notNumber("入金金額");

    /**
     * 備考の文字数をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentNotesLimitOver = this._chercterLimitover("備考", 30);

    /**
     * コメントの文字数をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentCommentLimitOver = this._chercterLimitover("コメント", 100);

    /**
     * 領収書宛名の文字数をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentAtenaLimitOver = this._chercterLimitover("領収書宛名", 60);

    /**
     * 但書の文字数をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentProvisoLimitOver = this._chercterLimitover("但書き", 80);

    /**
     * 区分が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentDivisionNotSelected = this._notSelected("区分");

    /**
     * 入金金額が範囲外の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.paymentInputPaymentOutRange = this._outOfRange("入金金額", "-９９９９９９９９９９", "９９９９９９９９９９");

    /**
     * 入金データの登録件数が99件を超えた場合のエラーメッセージ
     *
     * @type {string}
     */
    this.paymentRegistNumberOver = "最大登録件数を超えています(99件)。";

    /**
     * コメント入力画面を閉じる差異の確認メッセージ
     *
     * @type {string}
     */
    this.paymentConfirmCloseCommentWindow = "変更した内容は登録されません。よろしいですか?";

    /**
     * 登録行がある時に取引先コードを変更した場合の確認メッセージ
     *
     * @type {string}
     */
    this.paymentConfirmCodeChange = "編集中のデータは破棄されます。よろしいですか？";


    //*****************************************************************************************
    // 請求入金管理・仕入データ入力 メッセージ
    //*****************************************************************************************

    /**
     * 仕入コードが未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingCodeNotSelected = this._notSelected("仕入先コード");

    /**
     * 板厚が空の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingThicknessEmpty = this._numberEmpty("板厚");

    /**
     * 板厚が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingThicknessNotNumber = this._notNumber("板厚");

    /**
     * 板厚が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingThicknessOutOfRange = this._outOfRange("板厚", "０", "１００００", true);

    /**
     * 幅が空の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingVerticalEmpty = this._numberEmpty("幅");

    /**
     * 外径が空の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.stockingOuterDiameterEmpty = this._numberEmpty("外径");

    /**
     * 幅が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingVerticalNotNumber = this._notNumber("幅");

    /**
     * 外径が数値以外の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.stockingOuterDiameterNotNumber = this._notNumber("外径");

    /**
     * 幅が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingVerticalOutOfRange = this._outOfRange("幅", "０", "１００００", true);

    /**
     * 外径が範囲外の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.stockingOuterDiameterOutOfRange = this._outOfRange("外径", "０", "１００００", true);

    /**
     * 長さが空の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingHorizontalEmpty = this._numberEmpty("長さ");

    /**
     * 内径が空の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.stockingInnerDiameterEmpty = this._numberEmpty("内径");

    /**
     * 長さが数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingHorizontalNotNumber = this._notNumber("長さ");

    /**
     * 内径が数値以外の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.stockingInnerDiameterNotNumber = this._notNumber("内径");

    /**
     * 長さが範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingHorizontalOutOfRange = this._outOfRange("長さ", "０", "１００００", true);

    /**
     * 内径が範囲外の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.stockingInnerDiameterOutOfRange = this._outOfRange("内径", "０", "１００００", true);

    /**
     * 員数が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingMemberEmpty = this._numberEmpty("員数");

    /**
     * 員数が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingMemberNotNumber = this._notNumber("員数");

    /**
     * 員数が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingMemberOutOfRange = this._outOfRange("員数", "-１０００００", "１０００００");

    /**
     * 員数が0場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingMemberZero = "員数には0は入力できません。";

    /**
     * 重量が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingWeightEmpty = this._numberEmpty("重量");


    /**
     * 重量が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingWeightNotNumber = this._notNumber("重量");

    /**
     * 重量が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingWeightOutOfRange = this._outOfRange("重量", "-１００００００", "１００００００");

    /**
     * 単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingUnitPriceEmpty = this._numberEmpty("単価");

    /**
     * 単価が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingUnitPriceNotNumber = this._notNumber("単価");

    /**
     * 単価が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingUnitPriceOutOfRange = this._outOfRange("単価", "-１００００００００", "１００００００００");

    /**
     * 金額が空の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingAmountEmpty = this._numberEmpty("金額");

    /**
     * 金額が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingAmountNotNumber = this._notNumber("金額");

    /**
     * 金額が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingAmountOutOfRange = this._outOfRange("金額", "-１０００００００００", "１０００００００００");

    /**
     * 作業Noの文字数をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingWorkNoLimitOver = this._chercterLimitover("作業No", 8);

    /**
     * 発注Noの文字数をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingOrderNoLimitOver = this._chercterLimitover("発注No", 30);


    /**
     * 備考の文字数をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingNotesLimitOver = this._chercterLimitover("備考", 80);

    /**
     * 材質補足の文字数をオーバーした場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingMaterialSupliLimitOver = this._chercterLimitover("材質補足", 40);

    /**
     * 担当者が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingChargeNotSelected = this._notSelected("担当者");

    /**
     * 発注者が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingordererNotSelected = this._notSelected("発注者");

    /**
     * 形状が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingShapeNotSelected = this._notSelected("形状");

    /**
     * 材質が未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.stockingMaterialNotSelected = this._notSelected("材質");

    /**
     * 仕入れ先が見つからなかった場合のエラーメッセージ
     *
     * @type {string}
     */
    this.stockingSupplierNotFound = "該当する仕入先が見つかりません。";

    /**
     * 材質・板厚の組み合わせが在庫マスタに登録されていない場合のエラーメッセージ
     *
     * @type {string}
     */
    this.stockingThicknessNotFound = "この材質・板厚は在庫マスタに登録されていません。\n在庫マスタに材質・板厚を登録してください。";

    //*****************************************************************************************
    // 請求入金管理・支払データ入力 メッセージ
    //*****************************************************************************************

    /**
     * 支払金額が数値以外の場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentAmountNotNumber = this._notNumber("支払金額");

    /**
     * 支払金額が範囲外の場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentAmountOutOfRange = this._outOfRange("支払金額", "-１００００００００００", "１００００００００００");

    /**
     * 支払金額が未入力の場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentAmountEmpty = this._numberEmpty("支払金額");

    /**
     * 取引先コードが未選択の場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentCustomerCodeNotSelected = this._notSelected("取引先コード");

    /**
     * 取引先名が見つからなかった場合のエラーメッセージ
     *
     * @type String
     */
    this.paymentPayeeNotFound = "該当する支払先が見つかりません。";

    //*****************************************************************************************
    // 請求入金管理・引取スクラップ入力 メッセージ
    //*****************************************************************************************

    /**
     * 取引先コードが未選択の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapCustomerCodeNotSelected = this._notSelected("取引先コード");

    /**
     * 引取重量が空の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapWeightEmpty = this._numberEmpty("引取重量");

    /**
     * 引取重量が数値でない場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapWeightNotNumber = this._notNumber("引取重量");

    /**
     * 引取重量が範囲外の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapWeightOutOfRange = this._outOfRange("引取重量", "-１００００００", "１００００００");

    /**
     * 引取単価が空の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapUnitPriceEmpty = this._numberEmpty("引取単価");

    /**
     * 引取単価が数値でない場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapUnitPriceNotNumber = this._notNumber("引取単価");

    /**
     * 引取単価が範囲外の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapUnitPriceOutOfRange = this._outOfRange("引取単価", "-１００００００００", "１００００００００");

    /**
     * 引取金額が空の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapAmountEmpty = this._numberEmpty("引取金額");

    /**
     * 引取金額が数値でない場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapAmountNotNumber = this._notNumber("引取金額");

    /**
     * 引取金額が範囲外の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapAmountOutOfRange = this._outOfRange("引取金額", "-１００００００００００", "１００００００００００");

    /**
     * 備考が文字数をオーバーした場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapNotesLimitOver = this._chercterLimitover("備考", 30);

    /**
     * 種別が未選択の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.scrapAssortmentNotSelected = this._notSelected("種別");

    /**
     * 取引先が見つからなかった場合のエラーメッセージ
     *
     * @type {string}
     */
    this.scrapCustomerNotFound = "該当する取引先が見つかりません。";

    //*****************************************************************************************
    // 請求入金管理・来勘入力 メッセージ
    //*****************************************************************************************
    /**
     * 作業番号が空の場合のエラーメッセージ
     *
     * @type {String}
     */
    this.nextCountWorkNoEmpty = this._numberEmpty("作業番号");

    /**
     * 作業番号が数値でないの場合のエラーメッセージ
     *
     * @type {String}
     */
    this.nextCountWorkNoNotNumber = this._notNumber("作業番号");

    //*****************************************************************************************
    // 請求入金管理・売上問い合わせ メッセージ
    //*****************************************************************************************
    /**
     * 検索念が数値でない場合のエラーメッセージ
     *
     * @type {String}
     */
    this.salesInquiryYearNotNumber = this._notNumber("年");

    /**
     * 検索月が数値でない場合のエラーメッセージ
     *
     * @type {String}
     */
    this.salesInquiryMonthNotNumber = this._notNumber("月");

    //*****************************************************************************************
    // 画像編集 メッセージ
    //*****************************************************************************************

    /**
     * 画像編集画面を閉じる際の確認メッセージ
     * @type {string}
     */
    this.imageEditConfirmClose = "変更内容は保存されません。閉じてもよろしいですか？";

    /**
     * 画像保存する際の確認メッセージ
     * @type {string}
     */
    this.imageEditConfirmSave = "保存しますか?";

    /**
     * メール送信画面を閉じる際のメッセージ
     * @type {string}
     */
    this.imageMailConfirmClose = "この画面を閉じてもよろしいですか？";

    /**
     * メール送信画面のメールアドレス入力欄が正しくないときのメッセージ
     */
    this.mailAddressCheck = "contentのlength番目がメールアドレスの形式になっていません。";

    /**
     * メール送信画面の入力欄の文字数が指定より多いときにメッセージ
     */
    this.mailAddressLength = "contentの文字数がmaxlength文字を超えています。";

    /**
     * 宛先が未入力の際に表示するメッセージ
     */
    this.atesakiBlank = "宛先が入力されていません。";

    /**
     * メール送信画面の宛先以外が入力されていないときのメッセージ
     */
    this.mailAddressEmptyCheck = "contentが入力されていませんがよろしいですか？";

    this.mailContentChangeCheck = "初期値から変更されています。定型文で上書きされますがよろしいですか？";

    /**
     * 添付ファイルが1つも選択されていない際のメッセージ
     */
    this.clippedFileNoCheck = "添付ファイルが1つも選択されていませんがよろしいですか？";

    /**
     * 添付ファイルが1つも選択されていない際のメッセージ(エラー
     */
    this.clippedFileNoCheckError = "添付ファイルが1つも選択されていません。";

    /**
     * メールを送信していいか確認するメッセージ
     */
    this.sendMailConfirm = "この内容でメールを送信してもよろしいですか？";

    /**
     * メール送信が完了した際のメッセージ
     */
    this.sendMailSuccess = "メール送信が完了しました。画面を終了します。";

    /**
     * メール送信で失敗した際のメッセージ
     */
    this.sendMailError = "メールの送信に失敗しました。";

    /**
     * メール送信時のデータ追加に失敗した場合
     */
    this.sendMailAddError = "エラーが発生しました。";

    /**
     * 添付ファイル送信履歴画面の日付の形式チェックのメッセージ
     */
    this.clippedHistroyDateError = "yyyy/mm/dd形式で入力して下さい。";

    /**
     * 添付ファイル送信履歴画面の作業番号の形式チェックのメッセージ
     */
    this.clippedHistorySagyoError = "作業番号には、8桁の数値を入力してください。";

    /**
     * 添付ファイル送信履歴画面の取引先名が取得できなかった際のメッセージ
     */
    this.clippedHistoryTorihikiNameError = "取引先コードがない、又は使用禁止になっています。";
    /**
     * 添付ファイル送信履歴画面の検索結果がなかった際のメッセージ
     */
    this.clippedHistorySearchNoData = "該当する履歴が見つかりませんでした。";

    /**
     * 添付ファイル送信にてFAX番号が不正の場合のエラーメッセージ
     * @type {string}
     */
    this.sendFaxNoInvalid = "FAX番号が無効です。";

    /**
     * 定型文一覧画面で削除を押下した際のメッセージ
     */
    this.phraseDeleteCheck = "削除した定型文は元に戻すことができません。削除してよろしいですか?";

    /**
     * 定型文一覧画面で削除を押下した際デフォルト設定が変わってしまう場合に表示するメッセージ
     */
    this.phraseDeleteDefaultChange = "デフォルトの定型文が変更されますがよろしいですか？";

    /**
     * 定型文一覧画面で削除を押下した際削除する定型文が見つからない場合に表示するメッセージ
     */
    this.phraseDeletefound = "この定型文は既に削除されています。";

    /**
     * 定型文の削除に成功した際に表示するメッセージ
     */
    this.phraseDeleteSuccess = "定型文を削除しました。";

    /**
     * 定型文を保存していいかの質問メッセージ
     */
    this.savePhrase = "定型文を保存してよろしいですか？";

    /**
     * 更新が完了した際のメッセージ
     */
    this.savePhraseSuccess = "保存が完了しました。";

    /**
     * 更新中にほかのユーザーによって更新されていた場合のメッセージ
     */
    this.savePhraseReload = function(date) {
    	return "この定型文は[更新日時 "+ date + "]に更新されています。";
    }

    /**
     * 定型文一覧画面へ戻るときのメッセージ
     */
    this.backPhraseList = "入力した内容は保存されません。戻ってよろしいですか？";

    /**
     * 取引先一覧画面で取得する情報がないときのメッセージ
     */
    this.torihikiDataUndefined = "該当データがありません。";


    //*****************************************************************************************
    // 照会
    //*****************************************************************************************

    // 加工受注売上

    /**
     * 年度：数値以外入力時のエラーメッセージ
     *
     * @type String
     */
    this.year_notNumber = "年を数値で入力して下さい。";


    /**
     * 年度：数値範囲外のエラーメッセージ
     *
     * @type String
     */
    this.year_outOfRange = "１～２０９９の数字を入力して下さい。";

    /**
     * 年度：対象データが無い場合のメッセージ
     *
     * @type String
     */
    this.year_dataNothing = "対象データが有りません。";

    /**
     * 削除済みデータのメッセージ
     */
    this.sagyotoiawase_sakujyo = "このデータは削除されています。";

    /**
     * 返品済みデータのメッセージ
     */
    this.sagyotoiawase_henpin = "このデータは返品されています。";

    /**
     * 請求済みデータのメッセージ
     */
    this.sagyotoiawase_seikyusumi = "このデータは請求済みになっています。";

    /**
     * 月次処理済みデータのメッセージ
     */
    this.sagyotoiawase_getsujishori = "このデータは月次処理済みになっています。";

    /**
     * 実績問合せ：開始日不正
     */
    this.jisseki_KaishibiErrorNotDate = "検索開始日を正しく入力してください。";

    /**
     * 実績問合せ：得意先コード未入力
     */
    this.jisseki_TokuiCdNoInput = "得意先コードを入力してください。";

    /**
     * 実績問合せ：作業データなし
     */
    this.jisseki_KensakuNoData = "該当する作業データが見つかりません。";

    //*****************************************************************************************
    // FAX振分
    //*****************************************************************************************

    /**
     * 削除するフォルダにファイル・フォルダが存在する場合のエラーメッセージ
     * @type {string}
     */
    this.faxAllocateDeleteErrorFileExist = "選択フォルダにファイル・フォルダがあるため削除できません。\n" +
        "ファイルの移動、フォルダの移動・削除を行ってから\n" +
        "再度削除を実行してください。";

    /**
     * 削除する際の確認メッセージ
     * @type {string}
     */
    this.faxAllocateDeleteConfirm = "削除すると戻すことができません。削除してよろしいですか？";

    /**
     * ファイル上書きの確認メッセージ
     * @param fileName
     * @return {string}
     */
    this.faxAllocateConfirmUploadFile = function (fileName) {
        return fileName + "と同名のファイルが存在します。上書きしてよろしいですか？"
    };

    /**
     * tiffファイル以外がアップロードされた場合のエラーメッセージ
     * @type {string}
     */
    this.faxAllocateNotTiffFile = "tiffファイル(.tif, .tiff)以外はアップロードできません。";

    /**
     * 分類フォルダの最後の一つを削除しようとしたときに出すエラーメッセージ
     */
    this.faxAllocateDeleteLastOne = "フォルダをすべて消すことはできません。";


    //*****************************************************************************
    //納品画面
    //*****************************************************************************
    /**
     * 登録中止時
     */
    this.nouhinStop = "納品完了登録を中止しました。";


    //*****************************************************************************************
    // 実績：年次熱処理集計表
    //*****************************************************************************************
    /**
     * 年度　入力制限
     * @param limit
     * @return {string}
     */
    this.year_limit = function( limit )
    {
        return "集計表示できる年度は" + limit + "年度までです。"
    };

    //*****************************************************************************************
    // 実績：年次売上集計表
    //*****************************************************************************************
    /**
     * 年度　入力制限
     * @param limit
     * @return {string}
     */
    this.nenuriage_year_range = function()
    {
        return "１～２０９９の数字を入力して下さい。"
    };

};


