/* global httpObj, Message */
/*

 01:get_select_tokuisaki_name.php		見積入力画面：得意先名の取得
 02:get_select_tokuisaki_tanto_list.php	見積入力画面：得意先コードより、名称・担当者一覧の取得
 03:jsGetMitsumoriKingaku_Kado			見積入力画面：角
 get_mitsumori_kingaku_kado.php
 jsGetMitsumoriKingaku_Maru
 jsGetMitsumoriKingaku_RingG
 jsGetMitsumoriKingaku_RingN
 jsGetMitsumoriKingaku_Kata
 04:
 05:get_mitsumori_add_file.php			見積書の添付ファイルを取得
 jsMitsumoriAddFileDelete				見積書の添付ファイルを削除
 06:get_tokui_todoke_list.php			受注入力：得意先名、担当一覧＋届け先一覧
 07:										届け先・担当者・仮伝取得
 08:get_tokui_area_list.php				売上実績：得意先一覧（範囲）
 09:get_netsushori_tanka.php				熱処理単価取得(見積・形状：型)

 10:jsGetJuchuKingaku_Kado				受注(角)
 jsGetJuchuKingaku_Maru				受注(丸)
 jsGetJuchuKingaku_RingG				受注(リング:外径)
 jsGetJuchuKingaku_RingN				受注(リング:内径)
 jsGetJuchuKingaku_Kata				受注(型)
 jsJuchuAddFileDelete					受注：添付ファイル削除
 jsJuchuKadoSetKingakuAll				受注：合計額計算

 11:jsGetMitsumoriSearch_Gaichu			見積検索：外注名表示

 12:jsJyuchuAddFileList					受注書添付ファイル取得(作業データ一覧
 jsJyuchuAddFileDelete				受注書添付ファイル削除(作業データ一覧

 13:jsGetKiridaiTanka(i,ita,yoko);		切代単価取得
 jsGetKiridaiTanka2();				切代単価取得(外周のみ、金額計算

 14:jsGetKiridaiDataSet

 15:jsGetKakouPreData();					加工データの事前情報取得

 16:jsGetKakouMensakuTanka();			加工単価の取得

 17:jsGetMitsuSearchGaiName();			見積検索：外注コード検索







 x01:exGetExtension						拡張子取得
 */



// 01:見積入力画面：得意先名の取得 =============================================
// tok_cd : 得意先コード
function jsGetTokuisakiName(tok_cd) {

    if (httpObj) {
        httpObj.open("GET", "/common/get_select_tokuisaki_name.php?tok_cd=" + tok_cd);
        httpObj.onreadystatechange = handleHttpResponse01;
        httpObj.send(null);
    }
}


// 見積入力画面：得意先表示
function handleHttpResponse01() {

    if (httpObj.readyState === 4) {

        var res = httpObj.responseText.split("\t");

        var tok = document.getElementById("tokui_name");

//		tok.innerHTML = res[0];
        tok.value = res[0];

    }
}

// tok_cd : 得意先コード(HTML ver
function jsGetTokuisakiNameView(tok_cd,ty) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_select_tokuisaki_name_view.php?tok_cd=" + tok_cd + "&ty=" + ty + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse01b;
        httpObj.send(null);
    }
}


// 注文・照会・実績問合せ画面：得意先表示
function handleHttpResponse01b() {

    if (httpObj.readyState === 4) {

        var res = httpObj.responseText.split("\t");
/*
res[0]実行箇所 1:コード入力 2:得意先POPUP選択
res[1]得意先名
*/
        var tok = document.getElementById("tokui_name");
        //var tokuiButton = document.getElementById("tokuiButton");
        var tokui_cd = document.getElementById("tokui_cd");

        tok.innerHTML = res[0];

        // 空欄時エラー表示
        if(res[0] == "") {
            alert("得意先コードがありません。");

            elemFocusSelect(tokui_cd);
        }

        // 得意先poputから選択であれば検索実行を行う
        if(res[1] == 2) {
            // データあれば検索実行
            if(res[0] != "") {
                goSearch();
            }
        }
    }
}

// 02:得意先(外注)コードより、名称・担当者一覧の取得 ===========================
// tok_cd   : 得意先(外注)コード
// iSelFlag : 0:得意先 1:材料外注 2:熱処理外注 3:加工外注
//#712
//// iDisp    : 1:見積入力画面 2:見積入力 3:見積検索
// iDisp    : 1:見積（受注も）入力画面 2:明細入力（受注等） 3:見積検索 4:見積明細入力
function jsGetTokuisakiName_TantoList(tok_cd, iSelFlag, iDisp) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.onreadystatechange = handleHttpResponse02;
        httpObj.open("GET", "/common/get_select_tokuisaki_tanto_list.php?tok_cd=" + tok_cd + "&iSelFlag=" + iSelFlag + "&iDisp=" + iDisp + "&dt=" + dt);
        httpObj.send(null);
    }
}

// 得意先(外注)コードより、名称・担当者一覧の取得
function handleHttpResponse02() {

    if (httpObj.readyState === 4) {

        var res = httpObj.responseText.split("\t");

        var tokui_name = res[0];	// 得意先
        var iSelFlag = res[1];		// 0:得意先 1:材料外注 2:熱処理外注 3:加工
        var iDisp = res[2];		// 1:見積入力画面 2:見積入力
        var cnt = res[3];		// 件数

        if (iDisp == 1) {
            // 見積入力画面
            if (iSelFlag == 0) {
                // 得意先一覧
                var tkcd = document.getElementById("tokui_cd");		//
                var tok = document.getElementById("tokui_name");	// 名称表示
                var tanto = document.getElementById("tanto");		// 担当select box
                tok.value = tokui_name;
            }
        } else if (iDisp == 2) {
            //#712
            //// 見積明細入力
            // 明細入力（受注等）
            if (iSelFlag == 1) {
                // 材料外注
                var tkcd = document.getElementById("gai_cd");		//
                var tok = document.getElementById("gai_name");		// 名称表示
                //#712
                ////#659
                //var tokd = document.getElementById("gai_name_disp");		// 名称表示
                var tanto = document.getElementById("gtanto");		// 担当select box
                tok.value = tokui_name;
                //#712
                ////#659
                //tokd.innerHTML = tokui_name;
            } else if (iSelFlag == 2) {
                // 熱処理外注
                var tkcd = document.getElementById("ngai_cd");		//
                var tok = document.getElementById("ngai_name");		// 名称表示
                //#712
                ////#659
                var tokd = document.getElementById("ngai_name_disp");		// 名称表示
                var tanto = document.getElementById("ngtanto");		// 担当select box
                tok.value = tokui_name;
                //#712
                ////#659
                //tokd.innerHTML = tokui_name;
            }
        } else if (iDisp == 3) {
            // 見積検索
            if (iSelFlag == 0) {
                // 得意先

                var tkcd = document.getElementById("tokui_cd");		//
                var tok = document.getElementById("tokui_name");	// 名称表示
                var tanto = document.getElementById("tanto");		// 担当select box
                tok.value = tokui_name;

            } else if (iSelFlag == 1) {

                // 材料
                var tkcd = document.getElementById("tokui_cd_gai");		//
                var tok = document.getElementById("tokui_name_gai");		// 名称表示
                tok.value = tokui_name;

                if (tokui_name == "") {
                    tkcd.value = "";
                    alert(Message.orderInputSubcontractCodeNotFound);
                    elemFocusSelect(tkcd);
                    return false;
                }

                return;
            } else if (iSelFlag == 2) {
                // 熱処理
                var tkcd = document.getElementById("tokui_cd_net");		//
                var tok = document.getElementById("tokui_name_net");		// 名称表示
                tok.value = tokui_name;

                if (tokui_name === "") {
                    tkcd.value = "";
                    alert(Message.orderInputSubcontractCodeNotFound);
                    elemFocusSelect(tkcd);
                    return false;
                }

                return;
            } else if (iSelFlag == 3) {
                // 加工
                var tkcd = document.getElementById("tokui_cd_kako");		//
                var tok = document.getElementById("tokui_name_kako");		// 名称表示
                tok.value = tokui_name;

                if (tokui_name === "") {
                    tkcd.value = "";
                    alert(Message.orderInputSubcontractCodeNotFound);
                    elemFocusSelect(tkcd);
                    return false;
                }

                return;
            }
        //#712
        } else if (iDisp == 4) {
            // 見積明細入力
            if (iSelFlag == 1) {
                // 材料外注
                var tkcd = document.getElementById("gai_cd");		//
                var tok = document.getElementById("gai_name");		// 名称表示
                //#659
                var tokd = document.getElementById("gai_name_disp");		// 名称表示
                var tanto = document.getElementById("gtanto");		// 担当select box
                tok.value = tokui_name;
                //#659
                tokd.innerHTML = tokui_name;
            } else if (iSelFlag == 2) {
                // 熱処理外注
                var tkcd = document.getElementById("ngai_cd");		//
                var tok = document.getElementById("ngai_name");		// 名称表示
                //#659
                var tokd = document.getElementById("ngai_name_disp");		// 名称表示
                var tanto = document.getElementById("ngtanto");		// 担当select box
                tok.value = tokui_name;
                //#659
                tokd.innerHTML = tokui_name;
            }
        }

        // 担当select boxを一端クリア

        while (tanto.lastChild) {
            tanto.removeChild(tanto.lastChild);
        }

        if (tokui_name === "") {
            if (iDisp == 1) {
                // 見積入力(得意先
                tkcd.value = "";
                alert(Message.orderInputCustomerCodeNotFound);
                elemFocusSelect(tkcd);
                return false;
            } else if (iDisp == 2) {
                // 見積明細入力(外注
                tkcd.value = "";
                alert(Message.orderInputSubcontractCodeNotFound);
                elemFocusSelect(tkcd);
                return false;
            } else if (iDisp == 3) {
                // 見積検索
            }
        }

        // 空白選択行を追加
        tanto.length++;
        tanto.options[tanto.length - 1].value = "";
        tanto.options[tanto.length - 1].text = "";

        var i = 0;
        var ii = 4;
        var iii = 0;

        for (i = 0; i < cnt; i++) {

            iii = i + ii;

            tanto.length++;

            tanto.options[tanto.length - 1].value = res[iii];		// ID
            tanto.options[tanto.length - 1].text = res[iii + 1];	// name

            ii++;
        }
    }
}

// 03:見積入力画面：外注先コードより、名称・担当者一覧の取得 ===================
// gai_cd   : 得意先コード
// iSelFlag : 1: 2:
function jsGetGaichuName_TantoList(gai_cd, iSelFlag) {

    if (httpObj) {
        httpObj.open("GET", "/common/get_select_tokuisaki_tanto_list.php?tok_cd=" + tok_cd);
        httpObj.onreadystatechange = handleHttpResponse02;
        httpObj.send(null);
    }
}


/**
 * 見積入力画面：「角」
 * @param tokui 得意先コード
 * @param ita
 * @param keijyo
 * @param zai
 * @param jr
 * @param line
 * @param ty
 * @param bx
 */
function jsGetMitsumoriKingaku_Kado(tokui, ita, keijyo, zai, jr, line, ty, bx) {

    $.ajaxSetup({
        cache: false
    });

    $.ajax({
        type         : "get",
        async        : false,  // #ukeire #446
        url          : "/common/get_mitsumori_kingaku_kado.php",
        data         : {
            "tok_cd": tokui,
            "ita": ita,
            "keijyo": keijyo,
            "zai": zai,
            "jr": jr,
            "line": line,
            "ty": ty,
            "bx": bx
        },
        crossDomain  : false,
        dataType     : "text",
        scriptCharset: 'utf-8'
    }).done(function (data) {
        // 成功

        var res = data.split("\t");
        /*
         res[ 0 ]: 得意先コード
         res[ 1 ]: ランク
         res[ 2 ]: 単価
         res[ 3 ]: 重量
         res[ 4 ]: 行
         res[ 5 ]: 新規／更新
                     3:更新
         res[ 6 ]: 変化項目（トリガー）の番号
                     1:板厚
                     2:幅
                     3:長さ
                     4:員数
                     5:重量
                     6:単価
                     7:材質
                     8:熱処理
                     9:比重
         */

        var r = {};
        r[ 'tokui' ]   = res[ 0 ];
        r[ 'rank' ]    = res[ 1 ];
        r[ 'tanka' ]   = res[ 2 ];
        r[ 'juryo' ]   = res[ 3 ];
        r[ 'line' ]    = res[ 4 ];
        r[ 'new_up' ]  = res[ 5 ];
        r[ 'trigger' ] = res[ 6 ];


        var juryo = 0;

        var rank    = document.getElementById( 'rank'    + r[ 'line' ] );		// ランク(P
        var tanka   = document.getElementById( 'tanka'   + r[ 'line' ] );		// 単価
        var kingaku = document.getElementById( 'kingaku' + r[ 'line' ] );		// 金額

        rank.innerHTML = r[ 'rank' ];	// ランク

        // #ukeire #433
        //// #ukeire #411
        if( !( r[ 'new_up' ] == 3 && r[ 'trigger' ] == 1 ) )    //更新時の板厚変更時以外、単価設定
        {
            tanka.value = r[ 'tanka' ];		// 単価
        }

        if ( r[ 'juryo' ] == "" ) {
            juryo = 0;
        } else {
            juryo = r[ 'juryo' ];
        }

        if (tanka.value != "") {
            var wk1 = juryo * tanka.value;
            kingaku.innerHTML = wk1.toLocaleString();
        } else {
            kingaku.innerHTML = "";
        }

    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        // エラー
        console.log(textStatus);
    });
}

/**
 * 見積入力画面：「丸」
 * @param tokui 得意先コード
 * @param ita 板厚
 * @param keijyo 形状
 * @param zai 材質
 * @param jr 重量
 * @param line
 * @param ty
 * @param bx
 */
function jsGetMitsumoriKingaku_Maru(tokui, ita, keijyo, zai, jr, line, ty, bx) {

    $.ajaxSetup({
        cache: false
    });

    $.ajax({
        type         : "get",
        async        : false,  // #ukeire #446
        url          : "/common/get_mitsumori_kingaku_maru.php",
        data         : {
            "tok_cd": tokui,
            "ita": ita,
            "keijyo": keijyo,
            "zai": zai,
            "jr": jr,
            "line": line,
            "ty": ty,
            "bx": bx
        },
        crossDomain  : false,
        dataType     : "text",
        scriptCharset: 'utf-8'
    }).done(function (data) {
        // 成功
        var res = data.split("\t");
        /*
         res[ 0 ]: 得意先コード
         res[ 1 ]: ランク
         res[ 2 ]: 単価
         res[ 3 ]: 重量
         res[ 4 ]: 行
         res[ 5 ]: 新規／更新
                     3:更新
         res[ 6 ]: 変化項目（トリガー）の番号
                     1:板厚
                     2:幅
                     3:長さ
                     4:員数
                     5:重量
                     6:単価
                     7:材質
                     8:熱処理
                     9:比重
         */

        var r = {};
        r[ 'tokui' ]   = res[ 0 ];
        r[ 'rank' ]    = res[ 1 ];
        r[ 'tanka' ]   = res[ 2 ];
        r[ 'juryo' ]   = res[ 3 ];
        r[ 'line' ]    = res[ 4 ];
        r[ 'new_up' ]  = res[ 5 ];
        r[ 'trigger' ] = res[ 6 ];

        var Juryo = 0;

        var rank    = document.getElementById( 'rank'    + r[ 'line' ] );		// ランク(P
        var tanka   = document.getElementById( 'tanka'   + r[ 'line' ] );		// 単価
        var kingaku = document.getElementById( 'kingaku' + r[ 'line' ] );		// 金額

        rank.innerHTML = r[ 'rank' ];	// ランク

        // #ukeire #433
        //// #ukeire #411
        if( !( r[ 'new_up' ] == 3 && r[ 'trigger' ] == 1 ) )    //更新時の板厚変更時以外、単価設定
        {
            tanka.value = r[ 'tanka' ];		// 単価
        }

        if ( r[ 'juryo' ] == "") {
            Juryo = 0;
        } else {
            Juryo = r[ 'juryo' ];
        }

        if (tanka.value != "") {
            var wk1 = Juryo * tanka.value;
            kingaku.innerHTML = wk1.toLocaleString();
        } else {
            kingaku.innerHTML = "";
        }

    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        // エラー
        console.log(textStatus);
    });

}


// 見積入力画面：「リング：外径」
//#683
//#236
function jsGetMitsumoriKingaku_RingG(tokui, ita, keijyo, zai, jr, cPai, hiritu, ty, bx, nai) {

  $.ajax({
    type: 'get',
    async: false,
    url: "/common/get_mitsumori_kingaku_ringG.php?tok_cd=" + tokui + "&ita=" + ita
    + "&keijyo=" + keijyo + "&zai=" + zai
    + "&jr=" + jr + "&cPai=" + cPai
    + "&hiritu=" + hiritu
    + "&ty=" + ty + "&bx=" + bx + "&nai=" + nai,
    crossDomain: false,
    dataType: 'text',
    scriptCharset: 'utf-8',
    success: function(resp) {
      var res = resp.split("\t");

      /*
       0:tok_cd
       1:rank
       2:tanka
       3:重量
       4:切代単価
       5:pai
       6:hiju
       7:ita
       8:ｽｸﾗｯﾌﾟ単価

       9:ty
               3:変更

       10:bx:
               1:板厚
               2:外径
               3:単価選択
               4:単価
               5:材質
               9:比重
       */
      var ty = res[9];
      var bx = res[10];


      var rank = document.getElementById('rank');				// ランク
      var tanka = document.getElementById('tanka');			// 単価
      var kingaku = document.getElementById('kingaku');		// 金額
      var juryo = 0;

      var tanka_flag = document.getElementById('tanka_flag');					// 切代 単価選択
      var r_tanka_flag = tanka_flag.options[tanka_flag.selectedIndex].value;

      var kakou_flag = document.getElementsByName('kakou_flag');				// 加工フラグ
      var r_kakou_flag = 0;
      if (kakou_flag[0].checked) {
        r_kakou_flag = 1;
      }

      var r_kingaku = 0;

      if (res[2] == "") {
        res[2] = 0;
      }

      if (r_tanka_flag == 0) {

        if
        (
            ( bx == 1 && ( ty == 1 || ty == 2 ) )
            ||
            ( bx == 1 && ty == 3 && tanka.value === "" )
            ||
            ( bx == 2 && ty == 1 && tanka.value === "" )
            ||
            ( bx == 3 && tanka.value === "" )
            ||
            ( bx == 5 )
        ) {
          tanka.value = res[2];													// 単価
          rank.innerHTML = res[1];												// ランク
        }

      }
      else if (r_kakou_flag == 0 && bx == 1 && ( ty == 1 || ty == 2 ) ) {
        tanka.value = 0;														// 単価
      }

      if (res[3] == "") {
        juryo = 0;
      }
      else {
        juryo = res[3];
      }

      if (r_tanka_flag == 1) {
        r_kingaku = tanka.value;							// 金額
      }
      else {
        r_kingaku = juryo * tanka.value;					// 金額
      }
      kingaku.innerHTML = r_kingaku.toLocaleString();			// 金額


      // 切代単価の設定

      var r_nai = document.getElementById('kr_kingaku');		// 金額：切代

      var r_naitan = document.getElementById('kr_tanka');		// 切代 単価
      //#683
      var kr_naikei = document.getElementById('kr_naikei');	// 切代：内径
      //#683
      //#236
      if (bx == 5 && kr_naikei.value !== "") {
        r_naitan.value = res[4];
      }

      var kr_tanka_flag = document.getElementById('kr_tanka_flag');	// 切代 単価選択
      var r_naitan_flag = kr_tanka_flag.options[kr_tanka_flag.selectedIndex].value;

      // 内径があれば、切代：金額を再計算
      if (kr_naikei.value !== "") {

        var wkkri = kr_naikei.value * res[5] * res[7] * res[6];

        if (r_naitan_flag === 1) {
          // 一個
          var wkkri2 = parseInt(res[4]);
        } else {
          // 切断長
          var wkkri2 = Math.round(res[4] * wkkri);
        }

        r_nai.innerHTML = wkkri2.toLocaleString();

      }


      // スクラップ

      // 内径があれば、スクラップ：金額を再計算
      if (kr_naikei.value !== "") {

        // 前回とまったく変わっていなければ再計算しない
        var pThickness = document.getElementById("pThickness");
        var pOut = document.getElementById("pOut");
        var pIn = document.getElementById("pIn");
        var pScrapP = document.getElementById("pScrapP");

        var nThickness = document.getElementById("ita");
        var nOut = document.getElementById("tate");
        var nIn = document.getElementById("kr_naikei");
        var nScrapP = document.getElementById("sc_tanka");

        if(cstf(pThickness.value) != cstf(nThickness.value) ||
            cstf(pOut.value) != cstf(nOut.value) ||
            cstf(pIn.value) != cstf(nIn.value) || cstf(pScrapP.value) != cstf(nScrapP.value))
        {

          var hiritu = document.getElementById('hiritu');			// 比重
          var r_skljyu = document.getElementById('sc_juryo');		// スクラップ 重量
          var wk3 = 0;

          wk3 = ((parseFloat(kr_naikei.value) - 10) / 2) * ((parseFloat(kr_naikei.value) - 10) / 2) * res[5] * res[7] * hiritu.value;

          if (wk3 < 1) {
            wk3 = 1;
          }

          wk3 = Math.round(wk3);
          r_skljyu.innerHTML = wk3.toLocaleString();

          var r_skltan = document.getElementById('sc_tanka');		// スクラップ 単価
          if (r_skltan.value === "") {
            r_skltan.value = res[8];
            var stan = res[8];
          } else {
            var stan = r_skltan.value;
          }

          var r_sklkin = document.getElementById('sc_kingaku');	//金額
          var wk4 = stan * wk3;
          r_sklkin.innerHTML = wk4.toLocaleString();

          pThickness.value = nThickness.value;
          pOut.value = nOut.value;
          pIn.value = nIn.value;
          pScrapP.value = nScrapP.value;
        }

      }


      // 熱処理の設定

      var nt_tanka = document.getElementById('nt_tanka');	// 熱処理：単価
      var nt_juryo = document.getElementById('nt_juryo');	// 熱処理：重量

      // 熱処理：単価選択
      var CboNTanka = document.getElementById('nt_tanka_flag');
      var CboNTanka_sel = CboNTanka.selectedIndex;
      var nt_tanka_flag = CboNTanka.options[CboNTanka_sel].value;

      var r_netsu = document.getElementById('nt_kingaku');		// 金額：熱処理

      // 内径があれば、熱処理重量の再計算
      if (kr_naikei.value !== "") {

        var hiritu = document.getElementById('hiritu');			// 比重

        var njwk = juryo - ((kr_naikei.value / 2) * (kr_naikei.value / 2) * 3.14 * res[7] * hiritu.value);

        njwk = Math.round(njwk);
        if (njwk < 1) {
          njwk = 1;
        }

        nt_juryo.value = njwk;

        // 熱処理単価があれば金額再計算
        if (nt_tanka.value != "") {
          if (nt_tanka_flag == 1) {
            var nkwk = parseInt(nt_tanka.value);
          } else {
            var nkwk = njwk * nt_tanka.value;
          }

          r_netsu.innerHTML = nkwk.toLocaleString();

        }

      }


      // １個当たり小計の表示

      var r_naikin = r_nai.innerHTML;

      var r_skl = document.getElementById('sc_kingaku');		// 金額：スクラップ
      var r_sklkin = jxDelComma(r_skl.innerHTML);

      var r_netsu = document.getElementById('nt_kingaku');		// 金額：熱処理
      var r_netsukin = jxDelComma(r_netsu.innerHTML);

      var r_total = 0;	// 小計

      var r_neb = document.getElementById('nebiki');		// 値引
      var r_nebiki = r_neb.value;

      var r_shokei = document.getElementById('shokei');		// 一個あたり小計

      if (r_naikin.length == 0) {
        r_naikin = 0;
      } else {
        if (!isFinite(r_naikin)) {
          r_naikin = 0;
        } else {
          r_naikin = parseInt(r_naikin);
        }
      }

      if (r_sklkin.length == 0) {
        r_sklkin = 0;
      } else {
        if (!isFinite(r_sklkin)) {
          r_sklkin = 0;
        } else {
          r_sklkin = parseInt(r_sklkin);
        }
      }

      if (r_netsukin.length == 0) {
        r_netsukin = 0;
      } else {
        if (!isFinite(r_netsukin)) {
          r_netsukin = 0;
        } else {
          r_netsukin = parseInt(r_netsukin);
        }
      }

      if (r_nebiki.length == 0) {
        r_nebiki = 0;
      } else {
        if (!isFinite(r_nebiki)) {
          r_nebiki = 0;
        } else {
          r_nebiki = parseInt(r_nebiki);
        }
      }


      // ここで数値かチェック入れる(r_nebiki) ****

      r_kingaku = Number(r_kingaku);
      r_naikin = Number(r_naikin);
      r_netsukin = Number(r_netsukin);
      r_sklkin = Number(r_sklkin);
      r_nebiki = Number(r_nebiki);

      r_total = (r_kingaku + r_naikin + r_netsukin) - r_sklkin;	// 金額の合計
      r_total = r_total - r_nebiki;

      r_shokei.innerHTML = r_total.toLocaleString();		// １個あたり小計


      // 合計
      var r_in = document.getElementById('insu');		// 員数
      var r_insu = r_in.value;

      var r_gokei = document.getElementById('gokei');		// 合計

      if (r_insu.length == 0) {
        // 員数未入力なので、合計なし
        r_gokei.innerHTML = "";
      } else {
        // 員数あるので、合計計算

        // ここで数値かチェック入れる(r_insu) ****
        if (!isFinite(r_insu)) {
          r_gokei.innerHTML = "";
        } else {
          var gkk = r_insu * r_total;
          r_gokei.innerHTML = gkk.toLocaleString();
        }
      }
    }
  });

}

function handleHttpResponse03c1() {

    if (httpObj.readyState === 4) {

        var res = httpObj.responseText.split("\t");

        /*
         0:tok_cd
         1:rank
         2:tanka
         3:重量
         4:切代単価
         5:pai
         6:hiju
         7:ita
         8:ｽｸﾗｯﾌﾟ単価

         9:ty
                 3:変更

         10:bx:
                 1:板厚
                 2:外径
                 3:単価選択
                 4:単価
                 5:材質
                 9:比重
         */
        var ty = res[9];
        var bx = res[10];


        var rank = document.getElementById('rank');				// ランク
        var tanka = document.getElementById('tanka');			// 単価
        var kingaku = document.getElementById('kingaku');		// 金額
        var juryo = 0;

        var tanka_flag = document.getElementById('tanka_flag');					// 切代 単価選択
        var r_tanka_flag = tanka_flag.options[tanka_flag.selectedIndex].value;

        var kakou_flag = document.getElementsByName('kakou_flag');				// 加工フラグ
        var r_kakou_flag = 0;
        if (kakou_flag[0].checked) {
            r_kakou_flag = 1;
        }


        var r_kingaku = 0;


        if (res[2] == "") {
            res[2] = 0;
        }

        //#683
        if (r_tanka_flag == 0) {

            if
            (
                ( bx == 1 && ty == 1 )
                ||
                ( bx == 1 && ty == 3 && tanka.value === "" )
                ||
                ( bx == 2 && ty == 1 && tanka.value === "" )
                ||
                ( bx == 3 && tanka.value === "" )
                ||
                ( bx == 5 )
            ) {
                tanka.value = res[2];													// 単価
                rank.innerHTML = res[1];												// ランク
            }

        }
        else if (r_kakou_flag == 0 && bx == 1 && ty == 1) {
            tanka.value = 0;														// 単価
        }


        if (res[3] == "") {
            juryo = 0;
        }
        else {
            juryo = res[3];
        }

        if (r_tanka_flag == 1) {
            r_kingaku = tanka.value;							// 金額
        }
        else {
            r_kingaku = juryo * tanka.value;					// 金額
        }
        kingaku.innerHTML = r_kingaku.toLocaleString();			// 金額


        // 切代単価の設定

        var r_nai = document.getElementById('kr_kingaku');		// 金額：切代

        var r_naitan = document.getElementById('kr_tanka');		// 切代 単価
        var kr_naikei = document.getElementById('kr_naikei');	// 切代：内径

        if (bx == 5 && kr_naikei.value !== "") {
            r_naitan.value = res[4];
        }

        var kr_tanka_flag = document.getElementById('kr_tanka_flag');	// 切代 単価選択
        var r_naitan_flag = kr_tanka_flag.options[kr_tanka_flag.selectedIndex].value;

        // 内径があれば、切代：金額を再計算
        if (kr_naikei.value !== "") {

            var wkkri = kr_naikei.value * res[5] * res[7] * res[6];

            if (r_naitan_flag === 1) {
                // 一個
                var wkkri2 = parseInt(res[4]);
            } else {
                // 切断長
                var wkkri2 = Math.round(res[4] * wkkri);
            }

            r_nai.innerHTML = wkkri2.toLocaleString();

        }


        // スクラップ

        // 内径があれば、スクラップ：金額を再計算
        if (kr_naikei.value !== "") {

          // 前回とまったく変わっていなければ再計算しない
          var pThickness = document.getElementById("pThickness");
          var pOut = document.getElementById("pOut");
          var pIn = document.getElementById("pIn");
          var pScrapP = document.getElementById("pScrapP");

          var nThickness = document.getElementById("ita");
          var nOut = document.getElementById("tate");
          var nIn = document.getElementById("kr_naikei");
          var nScrapP = document.getElementById("sc_tanka");

          var hiritu = document.getElementById('hiritu');			// 比重
          var r_skljyu = document.getElementById('sc_juryo');		// スクラップ 重量
          var wk3 = 0;
          var wk4 = 0;

          if (cstf(pThickness.value) != cstf(nThickness.value) ||
              cstf(pOut.value) != cstf(nOut.value) ||
              cstf(pIn.value) != cstf(nIn.value) || cstf(pScrapP.value) != cstf(nScrapP.value))
          {
            wk3 = ((parseFloat(kr_naikei.value) - 10) / 2) * ((parseFloat(kr_naikei.value) - 10) / 2) * res[5] * res[7] * hiritu.value;

            if (wk3 < 1) {
              wk3 = 1;
            }

            wk3 = Math.round(wk3);
            r_skljyu.innerHTML = wk3.toLocaleString();

            var r_skltan = document.getElementById('sc_tanka');		// スクラップ 単価
            if (r_skltan.value === "") {
              r_skltan.value = res[8];
              var stan = res[8];
            } else {
              var stan = r_skltan.value;
            }

            var r_sklkin = document.getElementById('sc_kingaku');	//金額
            wk4 = stan * wk3;
            r_sklkin.innerHTML = wk4.toLocaleString();
          }

        }


        // 熱処理の設定

        var nt_tanka = document.getElementById('nt_tanka');	// 熱処理：単価
        var nt_juryo = document.getElementById('nt_juryo');	// 熱処理：重量

        // 熱処理：単価選択
        var CboNTanka = document.getElementById('nt_tanka_flag');
        var CboNTanka_sel = CboNTanka.selectedIndex;
        var nt_tanka_flag = CboNTanka.options[CboNTanka_sel].value;

        var r_netsu = document.getElementById('nt_kingaku');		// 金額：熱処理

        // 内径があれば、熱処理重量の再計算
        if (kr_naikei.value !== "") {

            var hiritu = document.getElementById('hiritu');			// 比重

            var njwk = juryo - ((kr_naikei.value / 2) * (kr_naikei.value / 2) * 3.14 * res[7] * hiritu.value);

            njwk = Math.round(njwk);
            if (njwk < 1) {
                njwk = 1;
            }

            nt_juryo.value = njwk;

            // 熱処理単価があれば金額再計算
            if (nt_tanka.value != "") {
                if (nt_tanka_flag == 1) {
                    var nkwk = parseInt(nt_tanka.value);
                } else {
                    var nkwk = njwk * nt_tanka.value;
                }

                r_netsu.innerHTML = nkwk.toLocaleString();

            }

        }


        // １個当たり小計の表示

        var r_naikin = r_nai.innerHTML;

        var r_skl = document.getElementById('sc_kingaku');		// 金額：スクラップ
        var r_sklkin = jxDelComma(r_skl.innerHTML);

        var r_netsu = document.getElementById('nt_kingaku');		// 金額：熱処理
        var r_netsukin = jxDelComma(r_netsu.innerHTML);

        var r_total = 0;	// 小計

        var r_neb = document.getElementById('nebiki');		// 値引
        var r_nebiki = r_neb.value;

        var r_shokei = document.getElementById('shokei');		// 一個あたり小計

        if (r_naikin.length == 0) {
            r_naikin = 0;
        } else {
            if (!isFinite(r_naikin)) {
                r_naikin = 0;
            } else {
                r_naikin = parseInt(r_naikin);
            }
        }

        if (r_sklkin.length == 0) {
            r_sklkin = 0;
        } else {
            if (!isFinite(r_sklkin)) {
                r_sklkin = 0;
            } else {
                r_sklkin = parseInt(r_sklkin);
            }
        }

        if (r_netsukin.length == 0) {
            r_netsukin = 0;
        } else {
            if (!isFinite(r_netsukin)) {
                r_netsukin = 0;
            } else {
                r_netsukin = parseInt(r_netsukin);
            }
        }

        if (r_nebiki.length == 0) {
            r_nebiki = 0;
        } else {
            if (!isFinite(r_nebiki)) {
                r_nebiki = 0;
            } else {
                r_nebiki = parseInt(r_nebiki);
            }
        }


        // ここで数値かチェック入れる(r_nebiki) ****

        r_kingaku = Number(r_kingaku);
        r_naikin = Number(r_naikin);
        r_netsukin = Number(r_netsukin);
        r_sklkin = Number(r_sklkin);
        r_nebiki = Number(r_nebiki);

        r_total = (r_kingaku + r_naikin + r_netsukin) - r_sklkin;	// 金額の合計
        r_total = r_total - r_nebiki;

        r_shokei.innerHTML = r_total.toLocaleString();		// １個あたり小計


        // 合計
        var r_in = document.getElementById('insu');		// 員数
        var r_insu = r_in.value;

        var r_gokei = document.getElementById('gokei');		// 合計

        if (r_insu.length == 0) {
            // 員数未入力なので、合計なし
            r_gokei.innerHTML = "";
        } else {
            // 員数あるので、合計計算

            // ここで数値かチェック入れる(r_insu) ****
            if (!isFinite(r_insu)) {
                r_gokei.innerHTML = "";
            } else {
                var gkk = r_insu * r_total;
                r_gokei.innerHTML = gkk.toLocaleString();
            }
        }
    }
}

// 見積入力画面：「リング：内径」
//#683
function jsGetMitsumoriKingaku_RingN(nai, ita, hiritu, cPai, zaicd, hijyu, ntcd, ty, bx) {

    /*alert(nai);
     alert(ita);
     alert(hiritu);
     alert(cPai);
     alert(zaicd);
     alert(hijyu);
     alert(ntcd);
     */
    if (httpObj) {
        //#683
        httpObj.open("GET", "/common/get_mitsumori_kingaku_ringN.php?nai=" + nai + "&ita=" + ita
            + "&hiritu=" + hiritu + "&hijyu=" + hijyu
            + "&pai=" + cPai + "&zaicd=" + zaicd
            + "&ntcd=" + ntcd
            + "&ty=" + ty + "&bx=" + bx);
        httpObj.onreadystatechange = handleHttpResponse03c2;
        httpObj.send(null);
    }

}
function handleHttpResponse03c2() {

    if (httpObj.readyState === 4) {

        var res = httpObj.responseText.split("\t");

        /*
         0:内径
         1:板厚
         2:$pai
         3:比率
         4:材質コード
         5:切代単価
         6:スクラップ単価
         7:比重
         8:熱処理コード
         9:熱処理単価
         10:タイプ
         11:トリガー bx:
              1:内径
             20:単価選択 切断帳
             21:単価選択 一箇所
              3:単価
              4:スクラップ単価
              5:熱処理重量
              6:熱処理単価選択
              7:熱処理単価
              8:熱処理

         30
         40
         3.14
         0.001
         0145

         10
         0.000008
         101
         15
         */

        var ty = res[10];
        var bx = res[11];


        var wk2 = res[0] * res[2] * res[1] * res[3];

        var r_naitan = document.getElementById('kr_tanka');		// 切代 単価
        var kr_naikei = document.getElementById('kr_naikei');	// 切代：内径

        if( bx == 1 )
        {
            r_naitan.value = res[5];
            var ktan = res[5];
        }
        if( bx == 20 )
        {
            r_naitan.value = res[5];
            r_naitan.select();
            var ktan = res[5];
        }
        else
        {
            var ktan = r_naitan.value;
        }

        var kr_tanka_flag = document.getElementById('kr_tanka_flag');	// 切代 単価選択
        var r_naitan_flag = kr_tanka_flag.options[kr_tanka_flag.selectedIndex].value;

        var r_naikin = document.getElementById('kr_kingaku');		// 切代 金額

        if (r_naitan_flag == 1) {
            // 一個
            var wk1 = parseInt(ktan);
        } else {
            // 切断長
            var wk1 = Math.round(ktan * wk2);
        }

        if (ktan !== '') {
            r_naikin.innerHTML = wk1.toLocaleString();
        }

        // スクラップ：重量 計算(frmMitsuInput3 / GetSukurap)

      // 前回とまったく変わっていなければ再計算しない
      var pThickness = document.getElementById("pThickness");
      var pOut = document.getElementById("pOut");
      var pIn = document.getElementById("pIn");
      var pScrapP = document.getElementById("pScrapP");

      var nThickness = document.getElementById("ita");
      var nOut = document.getElementById("tate");
      var nIn = document.getElementById("kr_naikei");
      var nScrapP = document.getElementById("sc_tanka");

        var hiritu = document.getElementById('hiritu');			// 比重
        var r_skljyu = document.getElementById('sc_juryo');		// スクラップ 重量
      var r_sklkin = document.getElementById('sc_kingaku');	// スクラップ 金額

      var wk3 = 0;
      var wk4 = 0;

      if (cstf(pThickness.value) != cstf(nThickness.value) ||
          cstf(pOut.value) != cstf(nOut.value) ||
          cstf(pIn.value) != cstf(nIn.value) ||
          cstf(pScrapP.value) != cstf(nScrapP.value))
      {
        wk3 = ((res[0] - 10) / 2) * ((res[0] - 10) / 2) * res[2] * res[1] * hiritu.value;

        if (wk3 < 1) {
          wk3 = 1;
        }
        wk3 = Math.round(wk3);
        r_skljyu.innerHTML = wk3.toLocaleString();

        var r_skltan = document.getElementById('sc_tanka');		// スクラップ 単価
        if (bx == 1) {
          r_skltan.value = res[6];
          var stan = res[6];
        } else {
          var stan = r_skltan.value;
        }

        var wk4 = stan * wk3;
        if (stan !== '') {
          r_sklkin.innerHTML = wk4.toLocaleString();
        }

        pThickness.value = nThickness.value;
        pOut.value = nOut.value;
        pIn.value = nIn.value;
        pScrapP.value = nScrapP.value;

      } else {
        wk3 = cstf(r_skljyu.innerHTML);
        wk4 = cstf(r_sklkin.innerHTML);
      }

        // 熱処理
        var r_gaijyu = document.getElementById('juryo');	// 外径：重量
        var gaijyu = jxDelComma(r_gaijyu.innerHTML);

        if (gaijyu == "") {
            return false;
        }

        // 熱処理重量
        var wk5 = gaijyu - ((res[0] / 2) * (res[0] / 2) * res[2] * res[1] * hiritu.value);

        wk5 = Math.round(wk5);
        if (wk5 < 1) {
            wk5 = 1;
        }


        var r_netsujyu = document.getElementById('nt_juryo');	// 熱処理 重量

        // 熱重量計算フラグ
        var netsujyuCalcFlag = document.getElementById("getNetuJuryo").value;

        if (r_netsujyu.value == "" || netsujyuCalcFlag == 2) {
            r_netsujyu.value = wk5;
        } else {
			    wk5 = r_netsujyu.value;
        }


        var r_netsutan = document.getElementById('nt_tanka');		//熱処理単価
        var CboNTanka = document.getElementById('nt_tanka_flag');
        var CboNTanka_sel = CboNTanka.selectedIndex;
        var nt_tanka_flag = CboNTanka.options[CboNTanka_sel].value;
        var r_netsukin = document.getElementById('nt_kingaku');	//熱処理金額


        // 熱処理単価
        if
            (
                    (  nt_tanka_flag == 0  &&  bx == 8  )
                ||
                    (  nt_tanka_flag == 0  &&  r_netsukin.innerHTML === ""  &&  ty == 1  )
            )
        {
            r_netsutan.value = res[9];
        }
        var ntan = r_netsutan.value;


        // 熱処理金額

        if (nt_tanka_flag == 1) {
            //一個
            var wk6 = parseInt(ntan);
        } else {
            //重量
            var wk6 = wk5 * ntan;
        }

        if (ntan !== '') {
            r_netsukin.innerHTML = wk6.toLocaleString();
        }

        // 合計
        var r_nebiki = document.getElementById('nebiki');		// 値引き
        var r_shokei = document.getElementById('shokei');		// 一個当り小計
        var r_insu = document.getElementById('insu');			// 員数
        var r_gokei = document.getElementById('gokei');			// 合計

        if (r_nebiki.value == "") {
            r_nebiki.value = 0;
        }

        var gaikin = 0;
        var r_gaikin = document.getElementById('kingaku');	// 材料(外径)：金額
        if (r_gaikin.innerHTML == "") {
            r_gaikin.innerHTML = 0;
        } else {
            gaikin = r_gaikin.innerHTML;
        }

        // 小計
        /*
         r_gaikin.value[材料]
         wk1[切代]
         wk4[スクラップ](引く)
         wk6[熱処理]
         r_nebiki.value[値引]
         */
        var d1 = 0;	//
        var d5 = 0;	//

        if (gaikin == "") {
            d1 = 0;
        } else {
            d1 = parseInt(jxDelComma(gaikin));
        }

        if (r_nebiki.value == "") {
            d5 = 0;
        } else {
            d5 = parseInt(jxDelComma(r_nebiki.value));
        }

        var sho = (d1 + wk1 + wk6) - wk4 - d5;
        r_shokei.innerHTML = sho.toLocaleString();

        var goki = 0;
        if (r_insu.value == "") {
            // 員数が空白：合計０
            r_gokei.innerHTML = "";
        } else {
            // 員数あり：合計計算
            goki = sho * parseInt(r_insu.value);
            r_gokei.innerHTML = goki.toLocaleString();
        }
    }
}

// 見積入力画面：「型」
//  tokui  :得意先コード
//  ita    :板厚
//  keijyo :形状 11:型(角) 12:型(丸)
//  zai    :材質コード
//  jr     :重量
//  ty     :取得種別 1:材料 2:熱処理
//#684
function jsGetMitsumoriKingaku_Kata(tokui, ita, keijyo, zai, jr, type, bx) {

    $.ajaxSetup({
        cache: false
    });

    $.ajax({
        type         : "get",
        url          : "/common/get_mitsumori_kingaku_kata.php",
        data         : {
            "tok_cd": tokui,
            "ita"   : ita,
            "keijyo": keijyo,
            "zai"   : zai,
            "jr"    : jr,
            "ty"    : "1",
            "type"  : type,
            "bx"    : bx
        },
        async        : true,	// 非同期有効
        crossDomain  : false,
        dataType     : "text",
        scriptCharset: 'utf-8',
    }).done(function (res) {
        handleHttpResponse03d(res);
    }).fail(function () {
        alert("単価取得に失敗しました。");
    });

}

function handleHttpResponse03d(result) {

    var res = result.split("\t");
    //#684
    var res_keijyo = res[0];
    var res_ty = res[1];
    var res_tok_cd = res[2];
    var res_rank = res[3];
    var res_tanka = res[4];
    var res_jr = res[5];
    var res_type = res[6];
    var res_bx = res[7];


    var kingaku = document.getElementById('kingaku');	// 金額
    var kr_kingaku = document.getElementById('kr_kingaku');	// 金額;切代
    var sc_kingaku = document.getElementById('sc_kingaku');	// 金額;スクラップ
    var nt_kingaku = document.getElementById('nt_kingaku');	// 金額:熱処理

    var nebiki = document.getElementById('nebiki');		// 値引き
    var shokei = document.getElementById('shokei');		// 一個当り小計
    var insu = document.getElementById('insu');		// 員数
    var gokei = document.getElementById('gokei');		// 合計

    var nt_tanka = document.getElementById("nt_tanka");		// 熱処理単価


    var rank = document.getElementById('rank');		// ランク(P
    var tanka = document.getElementById('tanka');		// 単価

    var tan = document.getElementById('tanka_flag');	// 単価選択
    var tan_sel = tan.selectedIndex;
    var tanka_flag = tan.options[tan_sel].value;

    var ele = document.getElementsByName('kakou_flag');	// 加工フラグ
    var kakou_flag = 0;
    if (ele[0].checked == true) {
        kakou_flag = 1;
    }


    //#684
    // 単価

    if (res_tanka === "") {
        res_tanka = 0;
    }

    // #ukeire #411-2
    //if (res_bx == 1 && tanka_flag == 1 && kakou_flag == 0 && res_type == 1) {//}
    if (res_bx == 1 && tanka_flag == 1 && kakou_flag == 0 && (  res_type == 1 || res_type == 2 ) ) {
        res_tanka = 0;
        tanka.value = res_tanka;		// 単価
    }

    //#684
    if
    (
            res_bx == 6 && tanka_flag == 0
        // #ukeire #433
        //// #ukeire #411
        ||
            // #ukeire #433
         // res_bx == 1 && tanka_flag == 0 && res_type == 1
            res_bx == 1 && tanka_flag == 0 && ( res_type == 1 || res_type == 2 )
        // #ukeire #411
        ||
            res_bx == 1 && tanka_flag == 0 && tanka.value === ''
        ||
            res_bx == 2 && tanka_flag == 0 && tanka.value === ''
        ||
            res_bx == 3 && tanka_flag == 0 && tanka.value === ''
    ) {
        tanka.value = res_tanka;		// 単価
    }


    // ランク

    rank.innerHTML = res_rank;		// ランク


    // 金額

    if (res_jr === "") {
        res_jr = 0;
    }

    // 単価選択ごとの金額取得
    //#684
    if (tanka_flag == 1)		// 一個
    {
        res_jr = 1;
    }

    //#684
    if (tanka.value !== '') {
        var wk1 = res_jr * tanka.value;
        kingaku.innerHTML = wk1.toLocaleString();
    }


    // 熱処理単価、重量に値があれば金額計算する
    if (nt_tanka.value == "") {
        // 値なし -> 熱処理金額を０にする
    } else if (nt_tanka.value == 0) {
        // 値なし -> 熱処理金額を０にする
    } else {
        // 値あり
        var ntj = document.getElementById('nt_juryo');		// 熱処理重量
        var nt_juryo = jxDelComma(ntj.innerHTML);

        // 重量に値あり
        if (nt_juryo != "") {

            var nt_kingaku = document.getElementById("nt_kingaku");		// 熱処理金額

            // 熱処理単価選択
            var ntan = document.getElementById('nt_tanka_flag');
            var ntan_sel = ntan.selectedIndex;
            var nt_tanka_flag = ntan.options[ntan_sel].value;	// ID

            if (nt_tanka_flag == 1) {
                // 一個
                var wwkn = nt_tanka.value;
                nt_kingaku.innerHTML = wwkn.toLocaleString();
            } else {
                // 重量
                var wwkn = nt_tanka.value * nt_juryo;
                nt_kingaku.innerHTML = wwkn.toLocaleString();
            }
        }
    }


    // 合計計算

    var kin = jxDelComma(kingaku.innerHTML);
    var krkin = jxDelComma(kr_kingaku.innerHTML);
    var sckin = jxDelComma(sc_kingaku.innerHTML);
    var ntkin = jxDelComma(nt_kingaku.innerHTML);

    if (nebiki.value == "") {
        var nb = 0;
    } else {
        var nb = nebiki.value;
    }

    if (kin == "") {
        kin = 0;
    }
    if (krkin == "") {
        krkin = 0;
    }
    if (sckin == "") {
        sckin = 0;
    }
    if (ntkin == "") {
        ntkin = 0;
    }

    var sho = parseInt(kin) + parseInt(krkin) + parseInt(ntkin) - parseInt(sckin) - parseInt(nb);

    shokei.innerHTML = sho.toLocaleString();

    // 員数チェック、あれば合計を表示
    if (insu.value != "") {
        if (isFinite(insu.value)) {
            var gokeiv = sho * insu.value;
            gokei.innerHTML = gokeiv.toLocaleString();
        }
    }
}

// 数値文字列よりカンマを除く
function jxDelComma(targetNum) {

    var retStr = ""; // 作成する文字列を格納

    // 先頭の文字から順にループ
    for (var i = 0; i < targetNum.length; i++) {
        if (targetNum.charAt(i) != ",") {
            // カンマ記号でなければコピー
            retStr = retStr + targetNum.charAt(i);
        }
    }

    return retStr;

}

// 04:見積からの受注作成 ================================================
//
function jsOpenOrderInput(mno) {


    if (httpObj) {
//		httpObj.open("GET", "/common/get_mitsumori_keijo.php?mno="+mno);
        httpObj.onreadystatechange = handleHttpResponse03;
        httpObj.send(null);
    }

}

function handleHttpResponse04() {

    if (httpObj.readyState === 4) {

        var res = httpObj.responseText;


    }
}

// 05:見積書の添付ファイルを取得 =======================================
//
function jsMitsumoriAddFileList(mno) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_mitsumori_add_file.php?mno=" + mno + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse05;
        httpObj.send(null);
    }
}

function handleHttpResponse05() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");
        var ii = 7;
        var iii = 0;


        var table = document.getElementById("addfile");
        var row;
        var cell;

        var cnt = res[1];	// 件数
        var exp = "";		// 拡張子

        var ofl = "";	// 元ファイル名(拡張子除いたファイル名
        var jfl = "";	// 実ファイル名(拡張子除いたファイル名

        var fltmp = "";

        var opcg = "";		// jpg変換後ファイル名(元
        var jpcg = "";		// jpg変換後ファイル名(実

        var tmpD = 0;

        if (cnt > 0) {

            for (i = 0; i < cnt; i++) {

                iii = ii * i;

                // tifのみ表示変更
                exp = exGetExtension(res[iii + 2]);

                row = table.insertRow(-1);

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 2];

                // ファイルサイズ

                tmpD = eval(res[iii + 7]);
                tmpD = tmpD.toLocaleString();

                cell = row.insertCell(-1);
                cell.innerHTML = tmpD + "ﾊﾞｲﾄ";

                // ファイル日付

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 8];

                cell = row.insertCell(-1);

                if (exp === "tif") {

                    jpcg = "";

                    fltmp = exSplitExt(res[iii + 2]);	// 元
                    ofl = fltmp[0];

                    fltmp = exSplitExt(res[iii + 3]);	// 実
                    jfl = fltmp[0];

                    opcg = ofl + ".jpg";
                    jpcg = jfl + ".jpg";

                    cell.innerHTML = "<a href='../upload/mitsumori/" + res[iii + 4] + "/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 3] + "' target='_blank'>" + res[iii + 2] + "</a><br><a href='../upload/mitsumori/" + res[iii + 4] + "/" + res[iii + 5] + "/" + res[iii + 6] + "/" + jpcg + "' target='_blank'>" + opcg + "</a>";
                } else {
                    cell.innerHTML = "<a href='../upload/mitsumori/" + res[iii + 4] + "/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 3] + "' target='_blank'>" + res[iii + 2] + "</a>";
                }
                cell = row.insertCell(-1);
                cell.innerHTML = "<input type='button' class='button1' value='削除' onclick=\"delAddFile('" + res[0] + "','" + res[iii + 3] + "')\">";
            }
        }
    }
}

// 添付ファイルの削除
function jsMitsumoriAddFileDelete(mno, filenm) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_mitsumori_delete_file.php?mno=" + mno + "&filenm=" + filenm + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse05b;
        httpObj.send(null);
    }

}

function handleHttpResponse05b() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");
        var ii = 7;
        var iii = 0;


        var table = document.getElementById("addfile");
        var row;
        var cell;

        var cnt = res[1];	// 件数
        var exp = "";		// 拡張子

        var ofl = "";	// 元ファイル名(拡張子除いたファイル名
        var jfl = "";	// 実ファイル名(拡張子除いたファイル名

        var fltmp = "";

        var opcg = "";		// jpg変換後ファイル名(元
        var jpcg = "";		// jpg変換後ファイル名(実

        var tmpD = 0;

        if (cnt > 0) {

            for (i = 0; i < cnt; i++) {

                iii = ii * i;

                // tifのみ表示変更
                exp = exGetExtension(res[iii + 2]);

                row = table.insertRow(-1);

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 2];

                // ファイルサイズ

                tmpD = eval(res[iii + 7]);
                tmpD = tmpD.toLocaleString();

                cell = row.insertCell(-1);
                cell.innerHTML = tmpD + "ﾊﾞｲﾄ";

                // ファイル日付

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 8];

                cell = row.insertCell(-1);

                if (exp == "tif") {

                    jpcg = "";

                    fltmp = exSplitExt(res[iii + 2]);	// 元
                    ofl = fltmp[0];

                    fltmp = exSplitExt(res[iii + 3]);	// 実
                    jfl = fltmp[0];

                    opcg = ofl + ".jpg";
                    jpcg = jfl + ".jpg";

                    cell.innerHTML = "<a href='../upload/mitsumori/" + res[iii + 4] + "/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 3] + "' target='_blank'>" + res[iii + 2] + "</a><br><a href='../upload/mitsumori/" + res[iii + 4] + "/" + res[iii + 5] + "/" + res[iii + 6] + "/" + jpcg + "' target='_blank'>" + opcg + "</a>";
                } else {
                    cell.innerHTML = "<a href='../upload/mitsumori/" + res[iii + 4] + "/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 3] + "' target='_blank'>" + res[iii + 2] + "</a>";
                }
                cell = row.insertCell(-1);
                cell.innerHTML = "<input type='button' class='button1' value='削除' onclick=\"delAddFile('" + res[0] + "','" + res[iii + 3] + "')\">";
            }
        }
    }
}

// 06:得意先＋届け先一覧を取得 =================================================
function jsGetTodoke_List(tok_cd) {

  $.ajaxSetup({
    cache: false
  });

  $.ajax({
    type         : "get",
    url          : "/common/get_tokui_todoke_list.php",
    data         : {
      "tok_cd": tok_cd
    },
    crossDomain  : false,
    dataType     : "json",
    scriptCharset: 'utf-8'
  }).done(function (data) {

    // 得意先一覧
    var tokcd = document.getElementById('tokui_cd');	// 得意先コード
    var tok = document.getElementById("tokui_name");	// 名称表示
    var tanto = document.getElementById("tanto");		// 担当select box
    var tdkid = document.getElementById("tdkid");	// 届け先一覧

    // 担当select boxを一端クリア

    while (tanto.lastChild) {
      tanto.removeChild(tanto.lastChild);
    }

    // 空白選択行を追加
    tanto.appendChild(document.createElement("option"));

    // 名称なし（取得出来ない or 使用禁止
    if (data.customerName === "") {
      // 届け先一覧を一端クリア

      while (tdkid.lastChild) {
        tdkid.removeChild(tdkid.lastChild);
      }

      tok.value = "";
      tokcd.value = "";

      alert("得意先コードがない、又は使用禁止になっています。");

      elemFocusSelect(tokcd);

      return;
    }

    tok.value = data.customerName;

    var cnt = data.charge.length;
    for (var i = 0; i < cnt; i++) {
        var op = document.createElement('option');
        op.value = data.charge[i].id;
        op.text = data.charge[i].name;
        tanto.appendChild(op);
    }

    // 届け先一覧を一端クリア
    while (tdkid.lastChild) {
      tdkid.removeChild(tdkid.lastChild);
    }

    // 空白選択行を追加
    tdkid.appendChild(document.createElement('option'));

    cnt = data.dest.length;
    for (var j = 0; j < cnt; j++) {
      var ds = document.createElement('option');
      ds.value = data.dest[j].id;		// ID
      ds.text = data.dest[j].id + ":" + data.dest[j].name;	// name
      tdkid.appendChild(ds);
    }

  }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
    alert(errorThrown);
  });

}

// 07:届け先・担当者・仮伝取得 =================================================

function jsGetTodokeName(tdk) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_todoke_tanto.php?tdk=" + tdk + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse07;
        httpObj.send(null);
    }
}

function handleHttpResponse07() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        /*
         res[0]届け先ID
         res[1]届け先名前
         res[2]仮伝 有無
         res[3]担当者数

         res[4]担当者～
         */

        var tView = document.getElementById('todokeView');	// 届け先(表示
        var tdkid = document.getElementById('todoke_id');	// 届け先ID(hidden
        var ckKari = document.getElementById('ckKari');		// 仮伝 chekbox

        tView.innerHTML = res[0] + "<br>" + res[1];
        tdkid.value = res[0];

        if (res[0] == '00000') {
            // 届け先ID:00000=消し込みデータ(仮伝フラグOFF)
            ckKari.checked = false;
        } else {
            if (res[2] == 0) {
                // 仮伝不要フラグ：要：チェック
                ckKari.checked = true;
            } else {
                // 仮伝不要フラグ：不要：チェック外す
                ckKari.checked = false;
            }
        }
        // 担当一覧
        var cnt = res[3];

        var tanto = document.getElementById("tdkTanto");		// 担当select box

        // 担当select boxを一端クリア

        while (tanto.lastChild) {
            tanto.removeChild(tanto.lastChild);
        }

        // 空白選択行を追加
        tanto.length++;
        tanto.options[tanto.length - 1].value = "";
        tanto.options[tanto.length - 1].text = "";

        var i = 0;
        var ii = 4;
        var iii = 0;

        for (i = 0; i < cnt; i++) {

            iii = i + ii;

            tanto.length++;

            tanto.options[tanto.length - 1].value = res[iii];		// ID
            tanto.options[tanto.length - 1].text = res[iii + 1];	// name

            ii++;
        }


    }
}

// 08:売上実績：得意先一覧（範囲） =================================================
function getTokuiAreaList(tok_cd) {

//alert("A");

    if (httpObj) {
        httpObj.open("GET", "/common/get_tokui_area_list.php?tok_cd=" + tok_cd);
        httpObj.onreadystatechange = handleHttpResponse08;
        httpObj.send(null);
    }
}

function handleHttpResponse08() {

    if (httpObj.readyState == 4) {

//alert("B");

        var res = httpObj.responseText.split("\t");

        var tocnt = res[0];	// 件数

        var tokui_cd2 = document.getElementById("tokui_cd2");		// 担当select box

        // 担当select boxを一端クリア

        while (tokui_cd2.lastChild) {
            tokui_cd2.removeChild(tokui_cd2.lastChild);
        }

        // 空白選択行を追加
        tokui_cd2.length++;
        tokui_cd2.options[tokui_cd2.length - 1].value = "";
        tokui_cd2.options[tokui_cd2.length - 1].text = "";

        var i = 0;
        var ii = 1;
        var iii = 0;

        for (i = 0; i < tocnt; i++) {

            iii = i + ii;

            tokui_cd2.length++;

            tokui_cd2.options[tokui_cd2.length - 1].value = res[iii];		// ID
            tokui_cd2.options[tokui_cd2.length - 1].text = res[iii] + ":" + res[iii + 1];	// name

            ii++;
        }
    }
}
// 09:見積(形状[型]) 熱処理単価取得 =================================================
//#684
/**
 * 09:見積(形状[型]) 熱処理単価取得
 *
 * @param {string} netsu_code 熱処理コード
 * @param {number} ty
 * @param {number} bx
 */
function jsGetJuchuNetsushoriTanka(netsu_code, ty, bx) {
    $.ajax({
        type         : "get",
        url          : "/common/get_netsushori_tanka.php",
        data         : {
            "netsu_code": netsu_code,
            "ty"        : ty,
            "bx"        : bx
        },
        crossDomain  : false,
        dataType     : "text",
        scriptCharset: "utf-8",
    }).done(function (res) {
        handleHttpResponse09Order(res);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        alert("熱処理単価取得に失敗しました。");
    });

}

/**
 * 09:見積(形状[型]) 熱処理単価取得
 *
 * @param {string} netsu_code 熱処理コード
 * @param {number} ty
 * @param {number} bx
 */
//###144-1
function jsGetMitsumoriNetsushoriTanka(netsu_code, ty, bx) {
    $.ajax({
        type         : "get",
        url          : "/common/get_netsushori_tanka.php",
        data         : {
            "netsu_code": netsu_code,
            "ty"        : ty,
            "bx"        : bx
        },
        crossDomain  : false,
        dataType     : "text",
        scriptCharset: "utf-8",
    }).done(function (res) {
        handleHttpResponse09Estimate(res);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        alert("熱処理単価取得に失敗しました。");
    });

}

function handleHttpResponse09Order(data) {


    var res = data.split("\t");
    //#684
    var res_nt_tanka = res[0];
    var res_type = res[1];
    var res_bx = res[2];


    var nt_tanka = document.getElementById("nt_tanka");			// 熱処理単価
    var ntj = document.getElementById("nt_juryo");				// 熱処理重量
    var nt_juryo = jxDelComma(ntj.innerHTML);

    var nt_kingaku = document.getElementById("nt_kingaku");		// 熱処理金額
    var nt_kin = 0;												// 熱処理金額

    var Hnt_kingaku = document.getElementById("Hnt_kingaku");	// 熱処理金額

    var ntan = document.getElementById('nt_tanka_flag');
    var ntan_sel = ntan.selectedIndex;
    var nt_tanka_flag = ntan.options[ntan_sel].value;			// ID

    if (nt_tanka_flag == 0) {
        nt_tanka.value = res_nt_tanka;
    }

    //#684
    if (nt_tanka.value == "" && nt_tanka_flag != 0) {
        return false;
    }

    var ntank = nt_tanka.value;

    if (nt_tanka_flag == 1) {
        // 一個
        nt_kingaku.innerHTML = ntank.toLocaleString();
        Hnt_kingaku.value = ntank;
    } else {
        // 重量

        // 重量に値があれば金額計算
        if (nt_juryo != "") {
            nt_kin = ntank * parseInt(nt_juryo);
        }

        nt_kingaku.innerHTML = nt_kin.toLocaleString();
        Hnt_kingaku.value = nt_kin;
    }

    //合計再計算
    setMitsumoriKataGokei();

}

function handleHttpResponse09Estimate(data) {


    var res = data.split("\t");
    //#684
    var res_nt_tanka = res[0];
    var res_type = res[1];
    var res_bx = res[2];


    var nt_tanka = document.getElementById("nt_tanka");			// 熱処理単価
    var ntj = document.getElementById("nt_juryo");				// 熱処理重量
    var nt_juryo = jxDelComma(ntj.innerHTML);

    var nt_kingaku = document.getElementById("nt_kingaku");		// 熱処理金額
    var nt_kin = 0;												// 熱処理金額

    var Hnt_kingaku = document.getElementById("Hnt_kingaku");	// 熱処理金額

    var ntan = document.getElementById('nt_tanka_flag');
    var ntan_sel = ntan.selectedIndex;
    var nt_tanka_flag = ntan.options[ntan_sel].value;			// ID

    //#684
    if (res_bx == 1 && nt_tanka_flag == 0) {
        nt_tanka.value = res_nt_tanka;
    }

    //#684
    if (nt_tanka.value == "" && !(  res_bx == 1 && nt_tanka_flag == 0  )) {
        return false;
    }

    var ntank = new Number(nt_tanka.value);

    if (nt_tanka_flag == 1) {
        // 一個
        nt_kingaku.innerHTML = ntank.toLocaleString();
        Hnt_kingaku.value = ntank;
    } else {
        // 重量

        // 重量に値があれば金額計算
        if (nt_juryo != "") {
            nt_kin = ntank * parseInt(nt_juryo);
        }

        nt_kingaku.innerHTML = nt_kin.toLocaleString();
        Hnt_kingaku.value = nt_kin;
    }

    //合計再計算
    setMitsumoriKataGokei();

}

// 合計値計算
function setMitsumoriKataGokei() {

    var kingaku = document.getElementById('kingaku');		// 金額:材料
    var kr_kingaku = document.getElementById('kr_kingaku');		// 金額:切代
    var sc_kingaku = document.getElementById('sc_kingaku');		// 金額:スクラップ
    var nt_kingaku = document.getElementById('nt_kingaku');		// 金額:熱処理

    var nebiki = document.getElementById('nebiki');			// 値引き

    var ikingaku = jxDelComma(kingaku.innerHTML);
    var ikr_kingaku = jxDelComma(kr_kingaku.innerHTML);
    var isc_kingaku = jxDelComma(sc_kingaku.innerHTML);
    var int_kingaku = jxDelComma(nt_kingaku.innerHTML);

    var shokei = 0;		// 小計
    var gokei = 0;		// 合計

    var insu = document.getElementById('insu');		// 員数

    var shokeiv = document.getElementById('shokei');		// 小計
    var gokeiv = document.getElementById('gokei');		// 合計

    shokeiv.innerHTML = "";
    gokeiv.innerHTML = "";

    if (ikingaku != "") {
        shokei = shokei + parseInt(ikingaku);
    }

    if (ikr_kingaku != "") {
        shokei = shokei + parseInt(ikr_kingaku);
    }

    if (isc_kingaku != "") {
        shokei = shokei - parseInt(isc_kingaku);
    }

    if (int_kingaku != "") {
        shokei = shokei + parseInt(int_kingaku);
    }

    if (nebiki.value != "") {
        if (isFinite(nebiki.value)) {
            shokei = shokei - nebiki.value;
        }
    }

    /// 小計
    shokeiv.innerHTML = shokei.toLocaleString();

    // 員数チェック、あれば合計を表示
    if (insu.value != "") {
        if (isFinite(insu.value)) {
            gokei = shokei * insu.value;
            gokeiv.innerHTML = gokei.toLocaleString();
        }
    }

    return true;
}

//10:
// 受注入力画面：「角」----------------------------
// tokui : 得意先コード
// ita   :板厚
// keijyo:形状コード
// zai   :材質コード
// jr    :重量
// ln    :行数
function jsGetJuchuKingaku_Kado(tokui, ita, keijyo, zai, jr, ln) {

    var dt = (new Date()).getTime();

    if (httpObj) {
     // httpObj.open("GET", "/common/get_juchu_kingaku_kado.php?tok_cd=" + tokui + "&ita=" + ita + "&keijyo=" + keijyo + "&zai=" + zai + "&jr=" + jr + "&ln=" + ln + "&dt=" + dt);         // #ukeire #446
        httpObj.open("GET", "/common/get_juchu_kingaku_kado.php?tok_cd=" + tokui + "&ita=" + ita + "&keijyo=" + keijyo + "&zai=" + zai + "&jr=" + jr + "&ln=" + ln + "&dt=" + dt, false);  // #ukeire #446
        httpObj.onreadystatechange = handleHttpResponse10_01;
        httpObj.send(null);
    }

}

function handleHttpResponse10_01() {

    if (httpObj.readyState == 4) {


        var res = httpObj.responseText.split("\t");

        var juryo = 0;
        var tankav = 0;

//alert(res[0]);

        /*
         0:得意先コード
         1:ランク
         2:単価
         3:重量
         4:行
         */

        if (res[4] == 1) {

            var rank = document.getElementById('rank1');		// ランク(P
            var insu = document.getElementById("insu1"); // 員数
            var tanka_flag = document.getElementById("tanka_flag1"); // 単価選択
            var tanka = document.getElementById('tanka1');	// 単価
            var kingaku = document.getElementById('kingaku1');	// 金額

            var Hrank = document.getElementById('Hrank1');		// rank
            var Hkingaku = document.getElementById('Hkingaku1');	// 金額

        } else if (res[4] == 2) {

            var rank = document.getElementById('rank2');		// ランク(P
            var insu = document.getElementById("insu2"); // 員数
            var tanka_flag = document.getElementById("tanka_flag2"); // 単価選択
            var tanka = document.getElementById('tanka2');	// 単価
            var kingaku = document.getElementById('kingaku2');	// 金額

            var Hrank = document.getElementById('Hrank2');		// rank
            var Hkingaku = document.getElementById('Hkingaku2');	// 金額

        } else if (res[4] == 3) {

            var rank = document.getElementById('rank3');		// ランク(P
            var insu = document.getElementById("insu3"); // 員数
            var tanka_flag = document.getElementById("tanka_flag3"); // 単価選択
            var tanka = document.getElementById('tanka3');	// 単価
            var kingaku = document.getElementById('kingaku3');	// 金額

            var Hrank = document.getElementById('Hrank3');		// rank
            var Hkingaku = document.getElementById('Hkingaku3');	// 金額

        }

        rank.innerHTML = res[1];	// ランク
        Hrank.value = res[1];	// ランク(H)

        if (res[2] == "") {
            tankav = 0;
        } else {
            tankav = res[2];
        }

        if (res[3] == "") {
            juryo = 0;
        } else {
            juryo = res[3];
        }

        tanka.value = tankav;	// 単価

        if (res[2] != "") {
            // 単価選択によって計算式が異なる
            if (tanka_flag.value == "0") {
                var wk1 = juryo * tankav;
            } else {
                var wk1 = insu.value * tankav;
            }
            kingaku.innerHTML = wk1.toLocaleString();
            Hkingaku.value = wk1;
        } else {
            kingaku.innerHTML = 0;
            Hkingaku.value = "0";
        }

        // 合計処理
        jsJuchuKadoSetKingakuAll();

    }
}

// 受注入力画面：「丸」----------------------------
// tokui : 得意先コード
// ita   :板厚
// keijyo:形状コード
// zai   :材質コード
// jr    :重量
// ln    :行数
function jsGetJuchuKingaku_Maru(tokui, ita, keijyo, zai, jr, ln) {

    var dt = (new Date()).getTime();

    if (httpObj) {
     // httpObj.open("GET", "/common/get_juchu_kingaku_maru.php?tok_cd=" + tokui + "&ita=" + ita + "&keijyo=" + keijyo + "&zai=" + zai + "&jr=" + jr + "&ln=" + ln + "&dt=" + dt);         // #ukeire #446
        httpObj.open("GET", "/common/get_juchu_kingaku_maru.php?tok_cd=" + tokui + "&ita=" + ita + "&keijyo=" + keijyo + "&zai=" + zai + "&jr=" + jr + "&ln=" + ln + "&dt=" + dt, false);  // #ukeire #446
        httpObj.onreadystatechange = handleHttpResponse10_02;
        httpObj.send(null);
    }

}

function handleHttpResponse10_02() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        var juryo = 0;
        var tankav = 0;

//alert(res[0]);

        /*
         0:得意先コード
         1:ランク
         2:単価
         3:重量
         4:行
         */

        if (res[4] == 1) {

            var rank = document.getElementById('rank1');		// ランク(P
            var insu = document.getElementById("insu1"); // 員数
            var tanka_flag = document.getElementById("tanka_flag1"); // 単価選択
            var tanka = document.getElementById('tanka1');	// 単価
            var kingaku = document.getElementById('kingaku1');	// 金額

            var Hrank = document.getElementById('Hrank1');		// rank
            var Hkingaku = document.getElementById('Hkingaku1');	// 金額

        } else if (res[4] == 2) {

            var rank = document.getElementById('rank2');		// ランク(P
            var insu = document.getElementById("insu2"); // 員数
            var tanka_flag = document.getElementById("tanka_flag2"); // 単価選択
            var tanka = document.getElementById('tanka2');	// 単価
            var kingaku = document.getElementById('kingaku2');	// 金額

            var Hrank = document.getElementById('Hrank2');		// rank
            var Hkingaku = document.getElementById('Hkingaku2');	// 金額

        } else if (res[4] == 3) {

            var rank = document.getElementById('rank3');		// ランク(P
            var insu = document.getElementById("insu3"); // 員数
            var tanka_flag = document.getElementById("tanka_flag3"); // 単価選択
            var tanka = document.getElementById('tanka3');	// 単価
            var kingaku = document.getElementById('kingaku3');	// 金額

            var Hrank = document.getElementById('Hrank3');		// rank
            var Hkingaku = document.getElementById('Hkingaku3');	// 金額

        }

        rank.innerHTML = res[1];	// ランク
        Hrank.value = res[1];	// ランク(H)

        if (res[2] == "") {
            tankav = 0;
        } else {
            tankav = res[2];
        }

        if (res[3] == "") {
            juryo = 0;
        } else {
            juryo = res[3];
        }

        tanka.value = tankav;	// 単価

        if (res[2] != "") {
            // 単価選択によって計算式が異なる
            if (tanka_flag.value == "0") {
                var wk1 = juryo * tankav;
            } else {
                var wk1 = insu.value * tankav;
            }
            kingaku.innerHTML = wk1.toLocaleString();
            Hkingaku.value = wk1;
        } else {
            kingaku.innerHTML = 0;
            Hkingaku.value = "0";
        }

        // 合計処理
        jsJuchuKadoSetKingakuAll();

    }
}

/**
 * 受注入力画面：「リング(外径)
 * @param tokui 得意先コード
 * @param ita 板厚
 * @param keijyo 形状コード
 * @param zai 材質コード
 * @param jr 重量
 * @param cPai
 * @param hiritu
 * @param nai 内径
 * @param bx 項目番号
 * @param isNew 新規
 * @param etype 変更／コピー
 */
function jsGetJuchuKingaku_RingG(tokui, ita, keijyo, zai, jr, cPai, hiritu, nai, bx, isNew, etype) {

  $.ajax({
    type: 'get',
    async: false,
    url: "/common/get_mitsumori_kingaku_ringG.php",
    data: {
      'tok_cd': tokui,
      'ita': ita,
      'keijyo': keijyo,
      'zai': zai,
      'jr': jr,
      'cPai': cPai,
      'hiritu': hiritu,
      'nai': nai,
      'bx': bx,
      'isNew': isNew,
      'etype': etype
    },
    crossDomain: false,
    dataType: 'text',
    scriptCharset: 'utf-8',
    success: function(resp) {

      /*
       0:tokui_cd
       1:rank
       2:単価
       3:重量
       4:切代単価
       5:pai
       6:hiritsu
       7:ita
       8:スクラップ単価

       9:ty
               3:変更

       10:bx:
               1:板厚
               2:外径
               3:単価選択
               4:単価
               5:材質
               6:比重

       11:新規          #ukeire #433
       12:変更／コピー  #ukeire #433

       */

      var res = resp.split("\t");

      // #ukeire #411
      var bx = res[10];

      // #ukeire #433
      var isNew = res[11];
      var etype = res[12];

      var rank = document.getElementById('rank');		// ランク
      var tanka = document.getElementById('tanka');		// 単価
      var kingaku = document.getElementById('kingaku');	// 金額
      var juryo = 0;

      var Hrank = document.getElementById('Hrank');	// ランク
      var Hkingaku = document.getElementById('Hkingaku');	// 金額

      // 単価種別取得
      var CboTanka = document.getElementById('tanka_flag');
      var sel = CboTanka.selectedIndex;
      var tanka_flag = CboTanka.options[sel].value;

      if
      (
          bx == 5
          || tanka.value === 0
          || tanka.value === ''
          || (bx == 1 && isNew == 'true')
          || (bx == 1 && etype == '2')
      ) {

        rank.innerHTML = res[1];	// ランク
        Hrank.value = res[1];

        var itaEditFlag = document.getElementById("itaEditFlag").value;
        if (res[2] != "") {
          tanka.value = res[2];		// 単価
        } else {
          tanka.value = 0;
        }

      }

      //##177
      if (document.activeElement == tanka) {
        tanka.select();
      }

      if (res[3] == "") {
        juryo = 0;
      } else {
        juryo = res[3];
      }

      var wk1 = 0;

      if (itaEditFlag == 1) {
        if (res[2] != "") {
          if (tanka_flag == 1) {
            wk1 = res[2];
          } else {
            wk1 = juryo * res[2];
          }
          Hkingaku.value = wk1;
          kingaku.innerHTML = wk1.toLocaleString();
        } else {
          Hkingaku.value = 0;
          kingaku.innerHTML = "0";
        }
      } else {
        if (tanka_flag == 1) {
          wk1 = tanka.value;
        } else {
          wk1 = juryo * tanka.value;
        }
        Hkingaku.value = wk1;
        kingaku.innerHTML = wk1.toLocaleString();
      }

      // #ukeire #433
      //// #ukeire #411
      Hrank.value = res[1];

// 切代単価の設定
      var r_nai = document.getElementById('kr_kingaku');	// 金額：切代

      var r_naitan = document.getElementById('kr_tanka');		// 切代 単価
      r_naitan.value = res[4];

      var kr_tanka_flag = document.getElementById('kr_tanka_flag');	// 切代 単価選択
      var r_naitan_flag = kr_tanka_flag.options[kr_tanka_flag.selectedIndex].value;

      var kr_naikei = document.getElementById('kr_naikei');	// 切代：内径
      // 内径があれば、切代：金額を再計算
      if (kr_naikei.value != "") {

        var wkkri = kr_naikei.value * res[5] * res[7] * res[6];

        if (r_naitan_flag == 1) {
          //
          var wkkri2 = parseInt(res[4]);
        } else {
          //
          var wkkri2 = Math.round(res[4] * wkkri);
        }

//			var wkkri2 = Math.round(res[4] * wkkri);

        r_nai.innerHTML = wkkri2.toLocaleString();

        // 保存
        var Hkr_kingaku = document.getElementById('Hkr_kingaku');	// 切代：金額
        Hkr_kingaku.value = wkkri2;
      }

// スクラップ

      /*
       0:内径
       1:板厚
       2:$pai
       3:比率
       4:材質コード
       5:切代単価
       6:スクラップ単価
       7:比重
       8:熱処理コード
       9:熱処理単価
       */

      // 内径があれば、スクラップ：金額を再計算
      if (kr_naikei.value != "") {

        var hiritu = document.getElementById('hiritu');			// 比重
        var r_skljyu = document.getElementById('sc_juryo');		// スクラップ 重量
        var wk3 = 0;

        // 0:内径 2:$pai 1:板厚
        wk3 = ((parseFloat(kr_naikei.value) - 10) / 2) *
            ((parseFloat(kr_naikei.value) - 10) / 2) * res[5] * res[7] *
            hiritu.value;

        if (wk3 < 1) {
          wk3 = 1;
        }

        wk3 = Math.round(wk3);
        r_skljyu.innerHTML = wk3.toLocaleString();

        var r_skltan = document.getElementById('sc_tanka');		// スクラップ 単価
        if (r_skltan.value == "") {
          r_skltan.value = 0;
          var stan = 0;
        } else {
          var stan = r_skltan.value;
        }

        var r_sklkin = document.getElementById('sc_kingaku');	//金額
        var wk4 = stan * wk3;
        r_sklkin.innerHTML = wk4.toLocaleString();
      }

// 熱処理の設定

      var nt_tanka = document.getElementById('nt_tanka');	// 熱処理：単価
      var nt_juryo = document.getElementById('nt_juryo');	// 熱処理：重量

      // 熱処理：単価選択
      var CboNTanka = document.getElementById('nt_tanka_flag');
      var CboNTanka_sel = CboNTanka.selectedIndex;
      var nt_tanka_flag = CboNTanka.options[CboNTanka_sel].value;

      var r_netsu = document.getElementById('nt_kingaku');		// 金額：熱処理
      var Hnt_kingaku = document.getElementById('Hnt_kingaku');	// 熱処理：金額

//		var wk5 = 外径：重量 - ((内径 / 2) * (内径 / 2) * $pai * 板厚 * 比率(0.001));

      // 内径があれば、熱処理重量の再計算
      if (kr_naikei.value != "") {
        var getNetuJuryo = document.getElementById("getNetuJuryo").value;
        // 外径または単価選択の重量からの遷移
        var hiritu = document.getElementById('hiritu');			// 比重

        var njwk = juryo -
            ((kr_naikei.value / 2) * (kr_naikei.value / 2) * 3.14 * res[7] *
                hiritu.value);

        njwk = Math.round(njwk);
        if (njwk < 1) {
          njwk = 1;
        }

        nt_juryo.value = njwk;

        // 熱処理単価があれば金額再計算
        if (nt_tanka.value != "") {
          if (nt_tanka_flag == 1) {
            var nkwk = nt_tanka.value;
          } else {
            var nkwk = njwk * nt_tanka.value;
          }

          r_netsu.innerHTML = nkwk.toLocaleString();
          Hnt_kingaku.value = nkwk;
        }
      }

      // １個当たり小計の表示

      var r_naikin = jxDelComma(r_nai.innerHTML);

      var r_skl = document.getElementById('sc_kingaku');		// 金額：スクラップ
      var r_sklkin = jxDelComma(r_skl.innerHTML);

      var r_netsukin = jxDelComma(r_netsu.innerHTML);

      var r_total = 0;	// 小計

      var r_neb = document.getElementById('nebiki');		// 値引
      var r_nebiki = r_neb.value;

      var r_shokei = document.getElementById('shokei');		// 一個あたり小計

      if (r_naikin.length == 0) {
        r_naikin = 0;
      } else {
        r_naikin = parseInt(r_naikin);
      }

      if (r_sklkin.length == 0) {
        r_sklkin = 0;
      } else {
        r_sklkin = parseInt(r_sklkin);
      }

      if (r_netsukin.length == 0) {
        r_netsukin = 0;
      } else {
        r_netsukin = parseInt(r_netsukin);
      }

      if (r_nebiki.length == 0) {
        r_nebiki = 0;
      } else {
        r_nebiki = parseInt(r_nebiki);
      }

      r_total = (wk1 + r_naikin + r_netsukin) - r_sklkin;	// 金額の合計
      r_total = r_total - r_nebiki;

      r_shokei.innerHTML = r_total.toLocaleString();	// １個あたり小計

      var Hshokei = document.getElementById('Hshokei');	// 小計
      Hshokei.value = r_total;

      // 合計
      var r_in = document.getElementById('insu');		// 員数
      var r_insu = r_in.value;

      var r_gokei = document.getElementById('gokei');		// 合計

      if (r_insu.length == 0) {
        // 員数未入力なので、合計なし
        r_gokei.innerHTML = "";
      } else {
        // 員数あるので、合計計算

        // ここで数値かチェック入れる(r_insu) ****
        if (!isFinite(r_insu)) {
          r_gokei.innerHTML = "";
        } else {
          var gkk = r_insu * r_total;
          r_gokei.innerHTML = gkk.toLocaleString();

          // 保存
          var Hgokei = document.getElementById('Hgokei');	// 合計
          Hgokei.value = gkk;

        }
      }
    }

  });

}

function handleHttpResponse10_03a() {

    if (httpObj.readyState == 4) {

        /*
         0:tokui_cd
         1:rank
         2:単価
         3:重量
         4:切代単価
         5:pai
         6:hiritsu
         7:ita
         8:スクラップ単価

         9:ty
                 3:変更

         10:bx:
                 1:板厚
                 2:外径
                 3:単価選択
                 4:単価
                 5:材質
                 6:比重

         11:新規          #ukeire #433
         12:変更／コピー  #ukeire #433

         */

        var res = httpObj.responseText.split("\t");

        // #ukeire #411
        var bx = res[10];

        // #ukeire #433
        var isNew = res[11];
        var etype = res[12];



        var rank = document.getElementById('rank');		// ランク
        var tanka = document.getElementById('tanka');		// 単価
        var kingaku = document.getElementById('kingaku');	// 金額
        var juryo = 0;

        var Hrank = document.getElementById('Hrank');	// ランク
        var Hkingaku = document.getElementById('Hkingaku');	// 金額

        // 単価種別取得
        var CboTanka = document.getElementById('tanka_flag');
        var sel = CboTanka.selectedIndex;
        var tanka_flag = CboTanka.options[sel].value;



        // #ukeire #411
        // #ukeire #433
        //if(  bx == 5  ||  tanka.value === 0  ||  tanka.value === ''   )
        if
        (
                bx          ==  5
            ||  tanka.value === 0
            ||  tanka.value === ''
            ||  (  bx == 1  &&  isNew == 'true'  )
            ||  (  bx == 1  &&  etype == '2'     )
        )
        {

            rank.innerHTML = res[1];	// ランク
            Hrank.value = res[1];

            var itaEditFlag = document.getElementById("itaEditFlag").value;
            if (res[2] != "") {
                tanka.value = res[2];		// 単価
            } else {
                tanka.value = 0;
            }

        }

        //##177
        if( document.activeElement == tanka )
        {
            tanka.select();
        }

        if (res[3] == "") {
            juryo = 0;
        } else {
            juryo = res[3];
        }

        var wk1 = 0;

        if (itaEditFlag == 1) {
	        if (res[2] != "") {
	        	if (tanka_flag == 1) {
	        		wk1 = res[2];
	        	} else {
	        		wk1 = juryo * res[2];
	        	}
	            Hkingaku.value = wk1;
	            kingaku.innerHTML = wk1.toLocaleString();
	        } else {
	            Hkingaku.value = 0;
	            kingaku.innerHTML = "0";
	        }
        } else {
        	if (tanka_flag == 1) {
        		wk1 = tanka.value;
        	} else {
        		wk1 = juryo * tanka.value;
        	}
        	Hkingaku.value = wk1;
            kingaku.innerHTML = wk1.toLocaleString();
        }

        // #ukeire #433
        //// #ukeire #411
        Hrank.value = res[1];

// 切代単価の設定
        var r_nai = document.getElementById('kr_kingaku');	// 金額：切代

        var r_naitan = document.getElementById('kr_tanka');		// 切代 単価
        r_naitan.value = res[4];

        var kr_tanka_flag = document.getElementById('kr_tanka_flag');	// 切代 単価選択
        var r_naitan_flag = kr_tanka_flag.options[kr_tanka_flag.selectedIndex].value;

        var kr_naikei = document.getElementById('kr_naikei');	// 切代：内径
        // 内径があれば、切代：金額を再計算
        if (kr_naikei.value != "") {

            var wkkri = kr_naikei.value * res[5] * res[7] * res[6];

            if (r_naitan_flag == 1) {
                //
                var wkkri2 = parseInt(res[4]);
            } else {
                //
                var wkkri2 = Math.round(res[4] * wkkri);
            }

//			var wkkri2 = Math.round(res[4] * wkkri);

            r_nai.innerHTML = wkkri2.toLocaleString();

            // 保存
            var Hkr_kingaku = document.getElementById('Hkr_kingaku');	// 切代：金額
            Hkr_kingaku.value = wkkri2;
        }
        /*
         var Hkr_kingaku = document.getElementById('Hkr_kingaku');	// 切代：金額
         var Hsc_juryo   = document.getElementById('Hsc_juryo');		// スクラップ：重量
         var Hsc_kingaku = document.getElementById('Hsc_kingaku');	// スクラップ：金額
         var Hnt_kingaku = document.getElementById('Hnt_kingaku');	// 熱処理：金額
         */

// スクラップ

        /*
         0:内径
         1:板厚
         2:$pai
         3:比率
         4:材質コード
         5:切代単価
         6:スクラップ単価
         7:比重
         8:熱処理コード
         9:熱処理単価
         */

        // 内径があれば、スクラップ：金額を再計算
        if (kr_naikei.value != "") {

            var hiritu = document.getElementById('hiritu');			// 比重
            var r_skljyu = document.getElementById('sc_juryo');		// スクラップ 重量
            var wk3 = 0;

            // 0:内径 2:$pai 1:板厚
            wk3 = ((parseFloat(kr_naikei.value) - 10) / 2) * ((parseFloat(kr_naikei.value) - 10) / 2) * res[5] * res[7] * hiritu.value;

            if (wk3 < 1) {
                wk3 = 1;
            }

            wk3 = Math.round(wk3);
            r_skljyu.innerHTML = wk3.toLocaleString();

            var r_skltan = document.getElementById('sc_tanka');		// スクラップ 単価
            if (r_skltan.value == "") {
                r_skltan.value = 0;
                var stan = 0;
            } else {
                var stan = r_skltan.value;
            }

            var r_sklkin = document.getElementById('sc_kingaku');	//金額
            var wk4 = stan * wk3;
            r_sklkin.innerHTML = wk4.toLocaleString();
        }

// 熱処理の設定

        var nt_tanka = document.getElementById('nt_tanka');	// 熱処理：単価
        var nt_juryo = document.getElementById('nt_juryo');	// 熱処理：重量

        // 熱処理：単価選択
        var CboNTanka = document.getElementById('nt_tanka_flag');
        var CboNTanka_sel = CboNTanka.selectedIndex;
        var nt_tanka_flag = CboNTanka.options[CboNTanka_sel].value;

        var r_netsu = document.getElementById('nt_kingaku');		// 金額：熱処理
        var Hnt_kingaku = document.getElementById('Hnt_kingaku');	// 熱処理：金額

//		var wk5 = 外径：重量 - ((内径 / 2) * (内径 / 2) * $pai * 板厚 * 比率(0.001));
//		wk5 = Math.round(wk5);

        // 内径があれば、熱処理重量の再計算
        if (kr_naikei.value != "") {
        	var getNetuJuryo = document.getElementById("getNetuJuryo").value;
        	// 外径または単価選択の重量からの遷移
        	var hiritu = document.getElementById('hiritu');			// 比重

            var njwk = juryo - ((kr_naikei.value / 2) * (kr_naikei.value / 2) * 3.14 * res[7] * hiritu.value);

            njwk = Math.round(njwk);
            if (njwk < 1) {
                njwk = 1;
            }

            nt_juryo.value = njwk;

            // 熱処理単価があれば金額再計算
            if (nt_tanka.value != "") {
                if (nt_tanka_flag == 1) {
                    var nkwk = nt_tanka.value;
                } else {
                    var nkwk = njwk * nt_tanka.value;
                }

                r_netsu.innerHTML = nkwk.toLocaleString();
                Hnt_kingaku.value = nkwk;
            }
        }

        // １個当たり小計の表示

        var r_naikin = jxDelComma(r_nai.innerHTML);

        var r_skl = document.getElementById('sc_kingaku');		// 金額：スクラップ
        var r_sklkin = jxDelComma(r_skl.innerHTML);

        var r_netsukin = jxDelComma(r_netsu.innerHTML);

        var r_total = 0;	// 小計

        var r_neb = document.getElementById('nebiki');		// 値引
        var r_nebiki = r_neb.value;

        var r_shokei = document.getElementById('shokei');		// 一個あたり小計

        if (r_naikin.length == 0) {
            r_naikin = 0;
        } else {
//			if (!isFinite(r_naikin.value)){
//				r_naikin = 0;
//			} else {
//				r_naikin = parseInt(r_naikin);
//			}

            r_naikin = parseInt(r_naikin);
        }

        if (r_sklkin.length == 0) {
            r_sklkin = 0;
        } else {
//			if (!isFinite(r_sklkin.value)){
//				r_sklkin = 0;
//			} else {
//				r_sklkin = parseInt(r_sklkin);
//			}
            r_sklkin = parseInt(r_sklkin);
        }

        if (r_netsukin.length == 0) {
            r_netsukin = 0;
        } else {
//			if (!isFinite(r_netsukin.value)){
//				r_netsukin = 0;
//			} else {
//				r_netsukin = parseInt(r_netsukin);
//			}
            r_netsukin = parseInt(r_netsukin);
        }

        if (r_nebiki.length == 0) {
            r_nebiki = 0;
        } else {
//			if (!isFinite(r_nebiki.value)){
//				r_nebiki = 0;
//			} else {
//				r_nebiki = parseInt(r_nebiki);
//			}
            r_nebiki = parseInt(r_nebiki);
        }

        r_total = (wk1 + r_naikin + r_netsukin) - r_sklkin;	// 金額の合計
        r_total = r_total - r_nebiki;

        r_shokei.innerHTML = r_total.toLocaleString();	// １個あたり小計

        var Hshokei = document.getElementById('Hshokei');	// 小計
        Hshokei.value = r_total;

        // 合計
        var r_in = document.getElementById('insu');		// 員数
        var r_insu = r_in.value;

        var r_gokei = document.getElementById('gokei');		// 合計

        if (r_insu.length == 0) {
            // 員数未入力なので、合計なし
            r_gokei.innerHTML = "";
        } else {
            // 員数あるので、合計計算

            // ここで数値かチェック入れる(r_insu) ****
            if (!isFinite(r_insu)) {
                r_gokei.innerHTML = "";
            } else {
                var gkk = r_insu * r_total;
                r_gokei.innerHTML = gkk.toLocaleString();

                // 保存
                var Hgokei = document.getElementById('Hgokei');	// 合計
                Hgokei.value = gkk;

            }
        }

    }
}

// 受注入力画面：「リング(内径)」----------------------------
// nai    :内径
// ita    :板厚
// hiritu :比率
// cPai   :pai
// zaicd  :材質コード
// hijyu  :比重
// ntcd   :熱処理コード
function jsGetJuchuKingaku_RingN(nai, ita, hiritu, cPai, zaicd, hijyu, ntcd) {

    /*
     alert(nai);
     alert(ita);
     alert(hiritu);
     alert(cPai);
     alert(zaicd);
     alert(hijyu);
     alert(ntcd);
     */
    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_mitsumori_kingaku_ringN.php?nai=" + nai + "&ita=" + ita + "&hiritu=" + hiritu + "&hijyu=" + hijyu + "&pai=" + cPai + "&zaicd=" + zaicd + "&ntcd=" + ntcd + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse10_03b;
        httpObj.send(null);
    }

}
function handleHttpResponse10_03b() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        /*
         0:内径
         1:板厚
         2:$pai
         3:比率
         4:材質コード
         5:切代単価
         6:スクラップ単価
         7:比重
         8:熱処理コード
         9:熱処理単価

         30
         40
         3.14
         0.001
         0145

         10
         0.000008
         101
         15

         */
        var wk2 = 0;
        wk2 = res[0] * res[2] * res[1] * res[3];

        var r_naitan = document.getElementById('kr_tanka');		// 切代 単価
        if (r_naitan.value == "") {
            r_naitan.value = res[5];
            var ktan = res[5];
        } else {
            var ktan = r_naitan.value;
        }

        var kr_tanka_flag = document.getElementById('kr_tanka_flag');	// 切代 単価選択
        var r_naitan_flag = kr_tanka_flag.options[kr_tanka_flag.selectedIndex].value;

        if (r_naitan_flag == 1) {
            // 一箇所
            var wk1 = parseInt(ktan);
        } else {
            // 切長
            var wk1 = Math.round(ktan * wk2);
            //
        }

        var r_naikin = document.getElementById('kr_kingaku');	// 切代 金額
//		var wk1 = Math.round(ktan * wk2);
        r_naikin.innerHTML = wk1.toLocaleString();

        /*
         'スクラップ重量
         dita = CDbl(Ita(0).Text)
         DGai = CDbl(Naikei.Text)

         wk2 = ((DGai - 10) / 2) * ((DGai - 10) / 2) * cPai * dita * txtHiritu
         wk2 = IIf(wk2 < 1, 1, wk2)
         COMJRYO(1) = CDbl(Format(wk2, "####0"))
         Juryo(1).Enabled = True
         Juryo(1).Text = Format$(wk2, "##,##0")
         Juryo(1).Enabled = False
         */

        // スクラップ：重量 計算(frmMitsuInput3 / GetSukurap)-------------------------------

        // 前回とまったく変わっていなければ再計算しない
        var pThickness = document.getElementById("pThickness");
        var pOut = document.getElementById("pOut");
        var pIn = document.getElementById("pIn");
        var pScrapP = document.getElementById("pScrapP");

        var nThickness = document.getElementById("ita");
        var nOut = document.getElementById("tate");
        var nIn = document.getElementById("kr_naikei");
        var nScrapP = document.getElementById("sc_tanka");

      var hiritu = document.getElementById('hiritu');			// 比重
      var r_skljyu = document.getElementById('sc_juryo');		// スクラップ 重量
      var r_sklkin = document.getElementById('sc_kingaku');	//金額

      var wk3 = 0;
      var wk4 = 0;

        if(cstf(pThickness.value) != cstf(nThickness.value) ||
            cstf(pOut.value) != cstf(nOut.value) ||
            cstf(pIn.value) != cstf(nIn.value) ||
            cstf(pScrapP.value) != cstf(nScrapP.value))
        {

          wk3 = ((res[0] - 10) / 2) * ((res[0] - 10) / 2) * res[2] * res[1] * hiritu.value;

          if (wk3 < 1) {
            wk3 = 1;
          }
          wk3 = Math.round(wk3);
          r_skljyu.innerHTML = wk3.toLocaleString();


          var r_skltan = document.getElementById('sc_tanka');		// スクラップ 単価

          if (r_skltan.value == "") {
            r_skltan.value = res[6];
            var stan = res[6];
          } else {
            var stan = r_skltan.value;
          }

          wk4 = stan * wk3;
          r_sklkin.innerHTML = wk4.toLocaleString();

          pThickness.value = nThickness.value;
          pOut.value = nOut.value;
          pIn.value = nIn.value;
          pScrapP.value = nScrapP.value;
        } else {
          wk3 = cstf(r_skljyu.innerHTML);
          wk4 = cstf(r_sklkin.innerHTML);
        }

        // 熱処理 -----------------------------------------------------------------

        var r_gaijyu = document.getElementById('juryo');	// 外径：重量
        var gaijyu = jxDelComma(r_gaijyu.innerHTML);

        if (gaijyu == "") {
            return;
        }

        //
        var wk5 = gaijyu - ((res[0] / 2) * (res[0] / 2) * res[2] * res[1] * hiritu.value);

        wk5 = Math.round(wk5);
        if (wk5 < 1) {
            wk5 = 1;
        }

        var r_netsujyu = document.getElementById('nt_juryo');	// 熱処理 重量
        // 熱重量計算フラグ
        var netsujyuCalcFlag = document.getElementById("getNetuJuryo").value;

        if (r_netsujyu.value == "" || netsujyuCalcFlag == 2) {
            r_netsujyu.value = wk5;
        } else {
			wk5 = r_netsujyu.value;
        }



        //##177
        if( document.activeElement == r_netsujyu )
        {
            r_netsujyu.select();
        }

        var r_netsutan = document.getElementById('nt_tanka');	//単価
        if (r_netsutan.value == "" || document.activeElement.id === "netsu_code") {
            r_netsutan.value = res[9];
            var ntan = res[9];
        } else {
            var ntan = r_netsutan.value;
        }

        // 熱処理：単価選択
        var CboNTanka = document.getElementById('nt_tanka_flag');
        var CboNTanka_sel = CboNTanka.selectedIndex;
        var nt_tanka_flag = CboNTanka.options[CboNTanka_sel].value;

        if (nt_tanka_flag == 1) {
            // 一個
            var wk6 = parseInt(ntan);
        } else {
            // 重量
            var wk6 = wk5 * ntan;
        }

        var r_netsukin = document.getElementById('nt_kingaku');	//金額
        r_netsukin.innerHTML = wk6.toLocaleString();

//    wk1 = CDbl(Format(Juryo(0).Text, "#######0"))

//    wk1 = wk1 - ((DNai / 2) * (DNai / 2) * cPai * dita * txtHiritu)
//    Juryo(2).Text = Format(wk1, "##,##0")

        // 合計
        var r_nebiki = document.getElementById('nebiki');		// 値引き
        var r_shokei = document.getElementById('shokei');		// 一個当り小計
        var r_insu = document.getElementById('insu');			// 員数
        var r_gokei = document.getElementById('gokei');		// 合計

        if (r_nebiki.value == "") {
            r_nebiki.value = 0;
        }

        var gaikin = 0;
        var r_gaikin = document.getElementById('kingaku');	// 材料(外径)：金額
        if (r_gaikin.innerHTML == "") {
            r_gaikin.innerHTML = 0;
        } else {
            gaikin = r_gaikin.innerHTML;
        }

        // 小計
        /*
         r_gaikin.value[材料]
         wk1[切代]
         wk4[スクラップ](引く)
         wk6[熱処理]
         r_nebiki.value[値引]


         */
        var d1 = 0;	//
        var d5 = 0;	//

        if (gaikin == "") {
            d1 = 0;
        } else {
            d1 = parseInt(jxDelComma(gaikin));
        }

        if (r_nebiki.value == "") {
            d5 = 0;
        } else {
            d5 = parseInt(jxDelComma(r_nebiki.value));
        }

        var sho = (parseInt(d1) + parseInt(wk1) + parseInt(wk6)) - parseInt(wk4) - parseInt(d5);
        r_shokei.innerHTML = sho.toLocaleString();

        var goki = 0;
        if (r_insu.value == "") {
            // 員数が空白：合計０
            r_gokei.innerHTML = "";
        } else {
            // 員数あり：合計計算
            goki = sho * parseInt(r_insu.value);
            r_gokei.innerHTML = goki.toLocaleString();
        }

        // 取り込み用hide保存
        var Hkr_kingaku = document.getElementById('Hkr_kingaku');	// 切代：金額
        var Hsc_juryo = document.getElementById('Hsc_juryo');		// スクラップ：重量
        var Hsc_kingaku = document.getElementById('Hsc_kingaku');	// スクラップ：金額
        var Hnt_kingaku = document.getElementById('Hnt_kingaku');	// 熱処理：金額

        var Hshokei = document.getElementById('Hshokei');	// 小計
        var Hgokei = document.getElementById('Hgokei');	// 合計

        Hkr_kingaku.value = wk1;
        Hsc_juryo.value = wk3;
        Hsc_kingaku.value = wk4;
        Hnt_kingaku.value = wk6;

        Hshokei.value = sho;
        Hgokei.value = goki;

    }
}

// 受注入力画面：「型」----------------------------
//  tokui  :得意先コード
//  ita    :板厚
//  keijyo :形状 11:型(角) 12:型(丸)
//  zai    :材質コード
//  jr     :重量
//  ty     :取得種別 1:材料 2:熱処理
function jsGetJuchuKingaku_Kata(tokui, ita, keijyo, zai, jr, ty) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_mitsumori_kingaku_kata.php?tok_cd=" + tokui + "&ita=" + ita + "&keijyo=" + keijyo + "&zai=" + zai + "&jr=" + jr + "&ty=" + ty + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse10_04;
        httpObj.send(null);
    }

}

function handleHttpResponse10_04() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        var kingaku = document.getElementById('kingaku');	// 金額
        var kr_kingaku = document.getElementById('kr_kingaku');	// 金額;切代
        var sc_kingaku = document.getElementById('sc_kingaku');	// 金額;スクラップ
        var nt_kingaku = document.getElementById('nt_kingaku');	// 金額:熱処理

        var nebiki = document.getElementById('nebiki');		// 値引き
        var shokei = document.getElementById('shokei');		// 一個当り小計
        var insu = document.getElementById('insu');		// 員数
        var gokei = document.getElementById('gokei');		// 合計

        var nt_tanka = document.getElementById("nt_tanka");		// 熱処理単価

// 0:形状
// 1:ty
// 2:得意先コード
// 3:rank
// 4;単価
// 5:重量

//alert(res[0]);

        if (res[0] == 11 && res[1] == 1) {
//			// 角：材料

//2:tokui_cd
//3:rank
//4:tanka
//5:juryo
            var juryo = 0;

            var rank = document.getElementById('rank');		// ランク(P
            var tanka = document.getElementById('tanka');		// 単価

            var Hrank = document.getElementById('Hrank');	// ランク
            var Hkingaku = document.getElementById('Hkingaku');	// 金額

            rank.innerHTML = res[3];	// ランク

            if (res[4] != "") {
                tanka.value = res[4];		// 単価
            } else {
                tanka.value = 0;
            }
            //##177
            if( document.activeElement == tanka )
            {
                tanka.select();
            }

            Hrank.value = res[3];		// ランク（保存

            if (res[5] == "") {
                juryo = 0;
            } else {
                juryo = res[5];
            }

            var tan = document.getElementById('tanka_flag');	// 単価選択
            var tan_sel = tan.selectedIndex;
            var tanka_flag = tan.options[tan_sel].value;

            // 単価選択ごとの金額取得
            if (tanka_flag == 1) {
                // 一個

                if (res[4] != "") {
                    var wk1 = res[4];
                    kingaku.innerHTML = wk1.toLocaleString();
                    Hkingaku.value = wk1;
                } else {
                    kingaku.innerHTML = 0;
                    Hkingaku.value = 0;
                }
            } else {
                // 重量

                if (res[4] != "") {
                    var wk1 = juryo * res[4];
                    kingaku.innerHTML = wk1.toLocaleString();
                    Hkingaku.value = wk1;
                } else {
                    kingaku.innerHTML = 0;
                    Hkingaku.value = 0;
                }
            }

            // 熱処理単価、重量に値があれば金額計算する
            if (nt_tanka.value == "") {
                // 値なし -> 熱処理金額を０にする
            } else if (nt_tanka.value == 0) {
                // 値なし -> 熱処理金額を０にする
            } else {
                // 値あり
                var ntj = document.getElementById('nt_juryo');		// 熱処理重量
                var nt_juryo = jxDelComma(ntj.innerHTML);

                // 重量に値あり
                if (nt_juryo != "") {

                    var nt_kingaku = document.getElementById("nt_kingaku");		// 熱処理金額

                    // 熱処理単価選択
                    var ntan = document.getElementById('nt_tanka_flag');
                    var ntan_sel = ntan.selectedIndex;
                    var nt_tanka_flag = ntan.options[ntan_sel].value;	// ID

                    if (nt_tanka_flag == 1) {
                        // 一個
                        var wkn = nt_tanka.value;
                        nt_kingaku.innerHTML = wkn.toLocaleString();
                    } else {
                        // 重量
                        var wkn = nt_tanka.value * nt_juryo;
                        nt_kingaku.innerHTML = wkn.toLocaleString();
                    }
                }
            }

        } else if (res[0] == 11 && res[1] == 2) {
            // 角：熱処理 -> なし

        } else if (res[0] == 12 && res[1] == 1) {
            //丸：材料

            var juryo = 0;

            var rank = document.getElementById('rank');		// ランク(P
            var tanka = document.getElementById('tanka');		// 単価

            var Hrank = document.getElementById('Hrank');	// ランク
            var Hkingaku = document.getElementById('Hkingaku');	// 金額

            rank.innerHTML = res[3];	// ランク

            if (res[4] != "") {
                tanka.value = res[4];		// 単価
            } else {
                tanka.value = 0;
            }
            //##177
            if( document.activeElement == tanka )
            {
                tanka.select();
            }

            Hrank.value = res[3];		// ランク（保存

            if (res[5] == "") {
                juryo = 0;
            } else {
                juryo = res[5];
            }

            var tan = document.getElementById('tanka_flag');	// 単価選択
            var tan_sel = tan.selectedIndex;
            var tanka_flag = tan.options[tan_sel].value;

            // 単価選択ごとの金額取得
            if (tanka_flag == 1) {
                // 一個

                if (res[4] != "") {
                    var wk1 = res[4];
                    kingaku.innerHTML = wk1.toLocaleString();
                    Hkingaku.value = wk1;
                } else {
                    kingaku.innerHTML = 0;
                    Hkingaku.value = 0;
                }
            } else {
                // 重量

                if (res[4] != "") {
                    var wk1 = juryo * res[4];
                    kingaku.innerHTML = wk1.toLocaleString();
                    Hkingaku.value = wk1;
                } else {
                    kingaku.innerHTML = 0;
                    Hkingaku.value = 0;
                }
            }

            // 熱処理単価、重量に値があれば金額計算する
            if (nt_tanka.value == "") {
                // 値なし -> 熱処理金額を０にする
            } else if (nt_tanka.value == 0) {
                // 値なし -> 熱処理金額を０にする
            } else {
                // 値あり
                var ntj = document.getElementById('nt_juryo');		// 熱処理重量
                var nt_juryo = jxDelComma(ntj.innerHTML);

                // 重量に値あり
                if (nt_juryo != "") {

                    var nt_kingaku = document.getElementById("nt_kingaku");		// 熱処理金額

                    // 熱処理単価選択
                    var ntan = document.getElementById('nt_tanka_flag');
                    var ntan_sel = ntan.selectedIndex;
                    var nt_tanka_flag = ntan.options[ntan_sel].value;	// ID

                    if (nt_tanka_flag == 1) {
                        // 一個
                        var wkn = nt_tanka.value;
                        nt_kingaku.innerHTML = wkn.toLocaleString();
                    } else {
                        // 重量
                        var wkn = nt_tanka.value * nt_juryo;
                        nt_kingaku.innerHTML = wkn.toLocaleString();
                    }
                }
            }

        } else if (res[0] == 12 && res[1] == 2) {
            // 丸：熱処理 ->なし

        }

        // 合計計算

        var Hkr_kingaku = document.getElementById('Hkr_kingaku');	// 切代：金額
//		var Hsc_juryo   = document.getElementById('Hsc_juryo');		// スクラップ：重量
        var Hsc_kingaku = document.getElementById('Hsc_kingaku');	// スクラップ：金額
        var Hnt_kingaku = document.getElementById('Hnt_kingaku');	// 熱処理：金額

        var Hshokei = document.getElementById('Hshokei');	// 小計
        var Hgokei = document.getElementById('Hgokei');	// 合計

        var kin = jxDelComma(kingaku.innerHTML);
        var krkin = jxDelComma(kr_kingaku.innerHTML);
        var sckin = jxDelComma(sc_kingaku.innerHTML);
        var ntkin = jxDelComma(nt_kingaku.innerHTML);

        if (nebiki.value == "") {
            var nb = 0;
        } else {
            var nb = nebiki.value;
        }

        if (kin == "") {
            kin = 0;
        }
        if (krkin == "") {
            krkin = 0;
        }
        if (sckin == "") {
            sckin = 0;
        }
        if (ntkin == "") {
            ntkin = 0;
        }

        Hkr_kingaku.value = krkin;
        Hsc_kingaku.value = sckin;
        Hnt_kingaku.value = ntkin;

        var sho = parseInt(kin) + parseInt(krkin) + parseInt(ntkin) - parseInt(sckin) - parseInt(nb);

        shokei.innerHTML = sho.toLocaleString();
        Hshokei.value = sho;

        // 員数チェック、あれば合計を表示
        if (insu.value != "") {
            if (isFinite(insu.value)) {
                var gokeiv = sho * insu.value;
                gokei.innerHTML = gokeiv.toLocaleString();
                Hgokei.value = gokeiv;
            }
        }

    }
}


// 添付ファイルの削除
function jsJuchuAddFileDelete(jno, sno, sno_eda, snen, filenm, ln) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_juchu_delete_file.php?jno=" + jno + "&sno=" + sno + "&sno_eda=" + sno_eda + "&snen=" + snen + "&filenm=" + filenm + "&ln=" + ln + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse10_05;
        httpObj.send(null);
    }

}

function handleHttpResponse10_05() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        /*
         0:受注番号
         1:作業番号
         2:作業枝番
         3:作業年
         4:行
         5:件数

         6:filename
         7:upfile
         8:yy
         9:mm
         10:dd
         11:サイズ
         12:日付

         13-19
         20-26

         2-6
         3-7
         4-8
         5-9
         6-10
         7-11
         8-12
         */
        var ln = res[4];

        var ii = 7;
        var iii = 0;

//		var table = document.getElementById("addfile");

        if (ln == 1) {
            var table = document.getElementById('addfile1');
        } else if (ln == 2) {
            var table = document.getElementById('addfile2');
        } else if (ln == 3) {
            var table = document.getElementById('addfile3');
        } else {
            return false;
        }

        var row;
        var cell;

        var cnt = res[5];	// 件数
        var exp = "";		// 拡張子

        var ofl = "";	// 元ファイル名(拡張子除いたファイル名
        var jfl = "";	// 実ファイル名(拡張子除いたファイル名

        var fltmp = "";

        var opcg = "";		// jpg変換後ファイル名(元
        var jpcg = "";		// jpg変換後ファイル名(実

        var tmpD = 0;

        if (cnt > 0) {

            for (i = 0; i < cnt; i++) {

                iii = ii * i;

                // tifのみ表示変更
                exp = exGetExtension(res[iii + 6]);

                row = table.insertRow(-1);

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 6];

                // ファイルサイズ

                tmpD = eval(res[iii + 11]);
                tmpD = tmpD.toLocaleString();

                cell = row.insertCell(-1);
                cell.innerHTML = tmpD + "ﾊﾞｲﾄ";

                // ファイル日付

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 12];

                cell = row.insertCell(-1);

                if (exp == "tif") {

                    jpcg = "";

                    fltmp = exSplitExt(res[iii + 6]);	// 元
                    ofl = fltmp[0];

                    fltmp = exSplitExt(res[iii + 7]);	// 実
                    jfl = fltmp[0];

                    opcg = ofl + ".jpg";
                    jpcg = jfl + ".jpg";

                    cell.innerHTML = "<a href='../upload/order/" + res[iii + 8] + "/" + res[iii + 9] + "/" + res[iii + 10] + "/" + res[iii + 7] + "' target='_blank'>" + res[iii + 6] + "</a><br><a href='../upload/order/" + res[iii + 8] + "/" + res[iii + 9] + "/" + res[iii + 10] + "/" + jpcg + "' target='_blank'>" + opcg + "</a>";

                } else {

                    cell.innerHTML = "<a href='../upload/order/" + res[iii + 8] + "/" + res[iii + 9] + "/" + res[iii + 10] + "/" + res[iii + 7] + "' target='_blank'>" + res[iii + 6] + "</a>";

                }

                cell = row.insertCell(-1);
                cell.innerHTML = "<input type='button' class='button1' value='削除' onclick=\"delAddFile('" + res[0] + "','" + res[1] + "','" + res[2] + "','" + res[3] + "','" + res[iii + 7] + "','" + res[4] + "')\">";

            }
        }

        /*

         var table = document.getElementById("addfile");
         var row;
         var cell;

         var cnt  = res[1];	// 件数
         var exp  = "";		// 拡張子

         var ofl = "";	// 元ファイル名(拡張子除いたファイル名
         var jfl = "";	// 実ファイル名(拡張子除いたファイル名

         var fltmp = "";

         var opcg = "";		// jpg変換後ファイル名(元
         var jpcg = "";		// jpg変換後ファイル名(実

         var tmpD = 0;

         if(cnt > 0) {

         for(i=0;i<cnt;i++) {

         iii = ii*i;

         // tifのみ表示変更
         exp = exGetExtension(res[iii+2]);

         row = table.insertRow(-1);

         cell = row.insertCell(-1);
         cell.innerHTML = res[iii+2];

         // ファイルサイズ

         tmpD = eval(res[iii+7]);
         tmpD = tmpD.toLocaleString();

         cell = row.insertCell(-1);
         cell.innerHTML = tmpD+"ﾊﾞｲﾄ";

         // ファイル日付

         cell = row.insertCell(-1);
         cell.innerHTML = res[iii+8];

         cell = row.insertCell(-1);

         if(exp == "tif") {

         jpcg = "";

         fltmp = exSplitExt(res[iii+2]);	// 元
         ofl   = fltmp[0];

         fltmp = exSplitExt(res[iii+3]);	// 実
         jfl   = fltmp[0];

         opcg = ofl+".jpg";
         jpcg = jfl+".jpg";

         cell.innerHTML = "<a href='../upload/mitsumori/"+res[iii+4]+"/"+res[iii+5]+"/"+res[iii+6]+"/"+res[iii+3]+"' target='_blank'>"+res[iii+2]+"</a><br><a href='../upload/mitsumori/"+res[iii+4]+"/"+res[iii+5]+"/"+res[iii+6]+"/"+jpcg+"' target='_blank'>"+opcg+"</a>";
         } else {
         cell.innerHTML = "<a href='../upload/mitsumori/"+res[iii+4]+"/"+res[iii+5]+"/"+res[iii+6]+"/"+res[iii+3]+"' target='_blank'>"+res[iii+2]+"</a>";
         }
         cell = row.insertCell(-1);
         cell.innerHTML = "<input type='button' value='削除' onclick=\"delAddFile('"+res[0]+"','"+res[iii+3]+"')\">";
         }
         }
         */
    }
}


// 受注：角・丸の合計欄
function jsJuchuKadoSetKingakuAll() {

    var kingaku1 = document.getElementById('kingaku1');	// 金額1
    var kingaku2 = document.getElementById('kingaku2');	// 金額2
    var kingaku3 = document.getElementById('kingaku3');	// 金額3
    var kingaku_all = document.getElementById('kingaku_all');	// 合計金額

    var kin1 = kingaku1.innerHTML;
    var kin2 = kingaku2.innerHTML;
    var kin3 = kingaku3.innerHTML;

    // 空欄の場合、0をセット
    if (kin1 == "") {
        kin1 = "0";
    }

    if (kin2 == "") {
        kin2 = "0";
    }

    if (kin3 == "") {
        kin3 = "0";
    }

    // カンマを外す
    var k1 = kin1.replace(/,/g, "");
    var k2 = kin2.replace(/,/g, "");
    var k3 = kin3.replace(/,/g, "");

    var kin = parseInt(k1) + parseInt(k2) + parseInt(k3);

    // 加工ありなら加工賃も加算
    // 加工賃は一個あたり加工金額 * 員数
    var isProcessingLine1 = document.getElementById("kkf1").checked;
    var processingFeesLine1 = parseInt(document.getElementById("kkakou_kinkei1").value.replace(/,/g, ""));
    if (isProcessingLine1 && processingFeesLine1 != 0) {
        var memberLine1 = parseInt(document.getElementById("insu1").value.replace(/,/g, ""));
        kin += processingFeesLine1 * memberLine1;
    }
    var isProcessingLine2 = document.getElementById("kkf2").checked;
    var processingFeesLine2 = parseInt(document.getElementById("kkakou_kinkei2").value.replace(/,/g, ""));
    if (isProcessingLine2 && processingFeesLine2 != "0") {
        var memberLine2 = parseInt(document.getElementById("insu2").value.replace(/,/g, ""));
        kin += processingFeesLine2 * memberLine2;
    }
    var isProcessingLine3 = document.getElementById("kkf3").checked;
    var processingFeesLine3 = parseInt(document.getElementById("kkakou_kinkei3").value.replace(/,/g, ""));
    if (isProcessingLine3 && processingFeesLine3 != "0") {
        var memberLine3 = parseInt(document.getElementById("insu3").value.replace(/,/g, ""));
        kin += processingFeesLine3 * memberLine3;
    }

    kingaku_all.innerHTML = kin.toLocaleString();

}

// 角・丸の材質変更時の再計算
function jsGetJuchuKingaku_Kado_Maru(tokui_cd, shohin_code, keijyo, itaatu1, itaatu2, itaatu3, juryo1, juryo2, juryo3, tanka_flag1, tanka_flag2, tanka_flag3) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_juchu_kingaku_KadoMaru.php?tokui_cd=" + tokui_cd + "&shohin_code=" + shohin_code + "&keijyo=" + keijyo + "&itaatu1=" + itaatu1 + "&itaatu2=" + itaatu2 + "&itaatu3=" + itaatu3 + "&juryo1=" + juryo1 + "&juryo2=" + juryo2 + "&juryo3=" + juryo3 + "&tanka_flag1=" + tanka_flag1 + "&tanka_flag2=" + tanka_flag2 + "&tanka_flag3=" + tanka_flag3 + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse10_10;
        httpObj.send(null);
    }

}

function handleHttpResponse10_10() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        /*
         0:	tok_cd
         1:	rank1
         2:	tanka1
         3:	rank2
         4:	tanka2
         5:	rank3
         6:	tanka3
         */


        var CboTanka1 = document.getElementById('tanka_flag1');
        var sel1 = CboTanka1.selectedIndex;
        var tanka_flag1 = CboTanka1.options[sel1].value;

        var CboTanka2 = document.getElementById('tanka_flag2');
        var sel2 = CboTanka2.selectedIndex;
        var tanka_flag2 = CboTanka2.options[sel2].value;

        var CboTanka3 = document.getElementById('tanka_flag3');
        var sel3 = CboTanka3.selectedIndex;
        var tanka_flag3 = CboTanka3.options[sel3].value;

        var juryo1 = document.getElementById('Hjuryo1');
        var juryo2 = document.getElementById('Hjuryo2');
        var juryo3 = document.getElementById('Hjuryo3');

        // 単価
        var tanka1 = document.getElementById('tanka1');
        var tanka2 = document.getElementById('tanka2');
        var tanka3 = document.getElementById('tanka3');

        var rank1 = document.getElementById('rank1');		// ランク(表示用
        var rank2 = document.getElementById('rank2');		// ランク
        var rank3 = document.getElementById('rank3');		// ランク

        var Hrank1 = document.getElementById('Hrank1');		// rank(保存用
        var Hrank2 = document.getElementById('Hrank2');		// rank
        var Hrank3 = document.getElementById('Hrank3');		// rank

        var kingaku1 = document.getElementById('kingaku1');	// 金額(表示用
        var kingaku2 = document.getElementById('kingaku2');	// 金額
        var kingaku3 = document.getElementById('kingaku3');	// 金額

        var Hkingaku1 = document.getElementById('Hkingaku1');	// 金額(保存用
        var Hkingaku2 = document.getElementById('Hkingaku2');	// 金額
        var Hkingaku3 = document.getElementById('Hkingaku3');	// 金額

        if (res[2] === "") {
            // 単価取得出来なければ何もしない
        } else {
            if (tanka_flag1 == 1) {
                // 一個の場合は何もしない
            } else {
                // 重量

                if (juryo1.value == "") {
                    // 重量が空欄の場合は計算なし
                } else {
                    // 単価を表示し再計算

                    var kk1 = res[2] * juryo1.value;

                    tanka1.value = res[2];
                    Hrank1.value = res[1];
                    Hkingaku1.value = kk1;

                    rank1.innerHTML = res[1];
                    kingaku1.innerHTML = kk1.toLocaleString();
                }
            }
        }

        if (res[4] === "") {
            // 単価取得出来なければ何もしない
        } else {
            if (tanka_flag2 == 1) {
                // 一個の場合は何もしない
            } else {
                // 重量

                if (juryo2.value == "") {
                    // 重量が空欄の場合は計算なし
                } else {
                    // 単価を表示し再計算
                    var kk2 = res[4] * juryo2.value;

                    tanka2.value = res[4];
                    Hrank2.value = res[3];
                    Hkingaku2.value = kk2;

                    rank2.innerHTML = res[3];
                    kingaku2.innerHTML = kk2.toLocaleString();
                }
            }
        }

        if (res[6] === "") {
            // 単価取得出来なければ何もしない
        } else {
            if (tanka_flag3 == 1) {
                // 一個の場合は何もしない
            } else {
                // 重量

                if (juryo3.value == "") {
                    // 重量が空欄の場合は計算なし
                } else {
                    // 単価を表示し再計算
                    var kk3 = res[6] * juryo3.value;

                    tanka3.value = res[6];
                    Hrank3.value = res[5];
                    Hkingaku3.value = kk3;

                    rank3.innerHTML = res[5];
                    kingaku3.innerHTML = kk3.toLocaleString();
                }
            }
        }

        // 合計の再計算
        jsJuchuKadoSetKingakuAll();

    }
}

// 11;外注名取得
function jsGetMitsumoriSearch_Gaichu(gcd) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_select_tokuisaki_name.php?tok_cd=" + gcd + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse11;
        httpObj.send(null);
    }

}

function handleHttpResponse11() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        var kakou_gai_name = document.getElementById('kakou_gai_name');

        kakou_gai_name.innerHTML = res[0];

    }

}
// 12:該当受注書の添付を一覧表示（作業データ一覧画面用
// sabyo_bango 作業番号
// sagyo_nen   作業年
// jyuban      受注番号
// keijyo_code 形状コード
function jsJyuchuAddFileList(sabyo_bango, sagyo_nen, jyuban, keijyo_code) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_jyuchu_add_file.php?sabyo_bango=" + sabyo_bango + "&sagyo_nen=" + sagyo_nen + "&jyuban=" + jyuban + "&keijyo_code=" + keijyo_code + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse12;
        httpObj.send(null);
    }
}

function handleHttpResponse12() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        var ii = 7;
        var iii = 0;


        var table = document.getElementById("addfile");
        var row;
        var cell;

        var cnt = res[2];	// 件数
        var exp = "";		// 拡張子

        var ofl = "";	// 元ファイル名(拡張子除いたファイル名
        var jfl = "";	// 実ファイル名(拡張子除いたファイル名

        var fltmp = "";

        var opcg = "";		// jpg変換後ファイル名(元
        var jpcg = "";		// jpg変換後ファイル名(実

        var tmpD = 0;

        if (cnt > 0) {

            for (i = 0; i < cnt; i++) {

                iii = ii * i;

                // tifのみ表示変更
                exp = exGetExtension(res[iii + 3]);

                row = table.insertRow(-1);

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 3];

                // ファイルサイズ

                tmpD = eval(res[iii + 8]);
                tmpD = tmpD.toLocaleString();

                cell = row.insertCell(-1);
                cell.innerHTML = tmpD + "ﾊﾞｲﾄ";

                // ファイル日付

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 9];

                cell = row.insertCell(-1);

                if (exp == "tif") {

                    jpcg = "";

                    fltmp = exSplitExt(res[iii + 3]);	// 元
                    ofl = fltmp[0];

                    fltmp = exSplitExt(res[iii + 4]);	// 実
                    jfl = fltmp[0];

                    opcg = ofl + ".jpg";
                    jpcg = jfl + ".jpg";

                    cell.innerHTML = "<a href='../upload/order/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 7] + "/" + res[iii + 4] + "' target='_blank'>" + res[iii + 3] + "</a><br><a href='../upload/mitsumori/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 7] + "/" + jpcg + "' target='_blank'>" + opcg + "</a>";
                } else {
                    cell.innerHTML = "<a href='../upload/order/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 7] + "/" + res[iii + 4] + "' target='_blank'>" + res[iii + 3] + "</a>";
                }
                cell = row.insertCell(-1);
                cell.innerHTML = "<input type='button' class='button1' value='削除' onclick=\"delAddFile('" + res[0] + "','" + res[1] + "','" + res[iii + 4] + "')\">";
            }
        }
    }
}

// 受注データ添付削除（作業データ一覧画面用
function jsJyuchuAddFileDelete(sabyo_bango, sagyo_nen, filenm) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_jyuchu_delete_file.php?sabyo_bango=" + sabyo_bango + "&sagyo_nen=" + sagyo_nen + "&filenm=" + filenm + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse12b;
        httpObj.send(null);
    }
}

function handleHttpResponse12b() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        /*
         0:sno
         1:snen
         2:count

         3:file
         4:upfile
         5:
         6:
         7:
         8:
         9:

         */

        var ii = 7;
        var iii = 0;

        var table = document.getElementById("addfile");
        var row;
        var cell;

        var cnt = res[2];	// 件数
        var exp = "";		// 拡張子

        var ofl = "";	// 元ファイル名(拡張子除いたファイル名
        var jfl = "";	// 実ファイル名(拡張子除いたファイル名

        var fltmp = "";

        var opcg = "";		// jpg変換後ファイル名(元
        var jpcg = "";		// jpg変換後ファイル名(実

        var tmpD = 0;

        if (cnt > 0) {

            for (i = 0; i < cnt; i++) {

                iii = ii * i;

                // tifのみ表示変更
                exp = exGetExtension(res[iii + 3]);

                row = table.insertRow(-1);

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 3];

                // ファイルサイズ

                tmpD = eval(res[iii + 8]);
                tmpD = tmpD.toLocaleString();

                cell = row.insertCell(-1);
                cell.innerHTML = tmpD + "ﾊﾞｲﾄ";

                // ファイル日付

                cell = row.insertCell(-1);
                cell.innerHTML = res[iii + 9];

                cell = row.insertCell(-1);

                if (exp == "tif") {

                    jpcg = "";

                    fltmp = exSplitExt(res[iii + 3]);	// 元
                    ofl = fltmp[0];

                    fltmp = exSplitExt(res[iii + 4]);	// 実
                    jfl = fltmp[0];

                    opcg = ofl + ".jpg";
                    jpcg = jfl + ".jpg";

                    cell.innerHTML = "<a href='../upload/order/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 7] + "/" + res[iii + 4] + "' target='_blank'>" + res[iii + 3] + "</a><br><a href='../upload/mitsumori/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 7] + "/" + jpcg + "' target='_blank'>" + opcg + "</a>";
                } else {
                    cell.innerHTML = "<a href='../upload/order/" + res[iii + 5] + "/" + res[iii + 6] + "/" + res[iii + 7] + "/" + res[iii + 4] + "' target='_blank'>" + res[iii + 3] + "</a>";
                }
                cell = row.insertCell(-1);
                cell.innerHTML = "<input type='button' class='button1' value='削除' onclick=\"delAddFile('" + res[0] + "','" + res[1] + "','" + res[iii + 4] + "')\">";
            }
        }
    }
}

// 13.切代：切代単価取得
function jsGetKiridaiTanka(i, ita, tate, zai) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_kiridai_tanka.php?i=" + i + "&ita=" + ita + "&tate=" + tate + "&zai=" + zai + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse13;
        httpObj.send(null);
    }

}

function handleHttpResponse13() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        /*
         0:行
         1:板厚
         2:幅(外径)
         3:材質コード
         4:単価

         */

        var index = ("00" + res[0]).slice(-2);

        var kk_ktan = document.getElementById('kk_ktan' + index);		// 切代単価(入力)

        // 空の場合のみ
        if (kk_ktan.value == "") {
            //単価未取得の場合は""をセット
            if (res[4] == "") {
                kk_ktan.value = "";
            } else {
                kk_ktan.value = res[4];
            }
        }
    }
}

// 切代単価取得（外周のみ：金額計算
function jsGetKiridaiTanka2(ita, zai, hiritu2) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_kiridai_tanka_gai.php?ita=" + ita + "&hiritu2=" + hiritu2 + "&zai=" + zai + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse13b;
        httpObj.send(null);
    }

}

function handleHttpResponse13b() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        var insu = document.getElementById('insu');	// 員数（画面側

        var kk_kejyo = document.getElementById('kk_kejyo01');		// 形状(選択)
        var kk_ita = document.getElementById('kk_ita01');		// 板厚(入力)
        var kk_tate = document.getElementById('kk_tate01');		// 幅(外径)(入力)
        var kk_yoko = document.getElementById('kk_yoko01');		// 長さ(内径)(入力)

        var kk_tanka = document.getElementById('kk_tanka01');		// 単価(選択)

        var kk_ktan = document.getElementById('kk_ktan01');		// 切代単価(入力)
        var kk_kkin = document.getElementById('kk_kkin01');		// 切代金額(表示)

        var kk_kkin01 = document.getElementById('Hkk_kkin01');		// 切代金額01(計算用

        /*
         0:ita
         1:hiritu2
         2:zai
         3:tanka
         */
        if (kk_ktan.value != "") {
            if (kk_ktan.value != "0") {
                res[3] = kk_ktan.value;
            }
        }

        kk_ktan.value = res[3];	// 切代単価セット

        var kkj = kk_kejyo.options[kk_kejyo.selectedIndex].value;	// 形状
        var kkt = kk_tanka.options[kk_tanka.selectedIndex].value;	// 単価選択

        var kkin = 0;

        // 切代金額計算
        if (kkt == 1) {
            // 一個
            kkin = res[3];

        } else {
            // 切長
            if (kkj == 12) {
                // 丸
                kkin = Math.round(kk_tate.value) * 3.14 * Math.round(kk_ita.value) * res[1] * Math.round(res[3]);
            } else {
                // 角・他
                kkin = (Math.round(kk_tate.value) + Math.round(kk_yoko.value)) * 2 * Math.round(kk_ita.value) * res[1] * Math.round(res[3]);
            }

          kkin = Math.round(kkin);
        }

        kk_kkin.innerHTML = kkin.toLocaleString();
        kk_kkin01.value = kkin;
    }
}
var httpObj2;
// 切代：切代計算
function jsGetKiridaiDataSet(i, ita, tate, yoko, keijo, zai, insu, hiritu2, hiritu) {

    var dt = (new Date()).getTime();
    httpObj2 = createXMLHttpRequest();
    if (httpObj2) {
    	httpObj2.open("GET", "/common/get_kiridai_tanka_all.php?i=" + i + "&ita=" + ita + "&tate=" + tate + "&yoko=" + yoko + "&keijo=" + keijo + "&zai=" + zai + "&insu=" + insu + "&hiritu2=" + hiritu2 + "&hiritu=" + hiritu + "&dt=" + dt);
    	httpObj2.onreadystatechange = handleHttpResponse14;
    	httpObj2.send(null);
    }

}


function handleHttpResponse14() {
    if (httpObj2.readyState == 4) {

        var res = httpObj2.responseText.split("\t");

        var cPai = 3.14;

        var index = ("00" + res[0]).slice(-2);

        var kk_tanka = document.getElementById('kk_tanka' + index);		// 単価(選択)
        var kk_ktan = document.getElementById('kk_ktan' + index);		// 切代単価(入力)
        var kk_kkin = document.getElementById('kk_kkin' + index);		// 切代金額(表示)
        var kk_skjuryo = document.getElementById('kk_skjuryo' + index);	// スクラップ重量(表示)
        var kk_sktan = document.getElementById('kk_sktan' + index);		// スクラップ単価(入力)
        var kk_skkin = document.getElementById('kk_skkin' + index);		// スクラップ金額(表示)

        var Hkk_kkin = document.getElementById('Hkk_kkin' + index);	// 切代金額(表示)
        var Hkk_skjuryo = document.getElementById('Hkk_skjuryo' + index);	// スクラップ重量(表示)
        var Hkk_skkin = document.getElementById('Hkk_skkin' + index);	// スクラップ金額(表示)

        /*
         0:$i.		行数
         1:$ita.		板厚
         2:$tate.	幅
         3:$yoko.	長さ
         4:$zai.
         5:$keijo.	形状	11:角 12:丸
         6:hiritsu2	比重2(0.0001)
         7:insu		員数
         8:tanka.	切代単価
         9:hiritsu	比重(0.000008)
         */
        // 単価が取得出来なかった場合は関連情報をクリア状態にしておく
        if (res[8] == "") {
            // 既に切代単価入力されたらスルー
            if (kk_ktan.value == "") {
                // 切代単価、切代金額、スクラップ重量、スクラップ単価、スクラップ金額

                kk_ktan.value = "";
                kk_kkin.innerHTML = "0";
                Hkk_kkin.value = "0";
                kk_skjuryo.innerHTML = "0";
                Hkk_skjuryo.value = "0";
                kk_sktan.value = "0";
                kk_skkin.innerHTML = "0";
                Hkk_skkin.value = "0";

                getKiridaiKingakuGoukei();	// 切代合計
                getScrapKingakuGoukei();	// スクラップ合計

                return false;
            }
        }

        var tnkf = kk_tanka.options[kk_tanka.selectedIndex].value;	// 0:切長 1:一個

        if (res[5] == "11") {
            // 角

            if (res[0] == "1") {
                // 外周

                // 切代金額


            } else {
                // 内周

                // 切代単価(入力)
                // 既に値があれば更新しない
                var ckktan = kk_ktan.value;

                if (ckktan == "") {
                    ckktan = res[8];
                }

                kk_ktan.value = ckktan;
                //##130
                if (document.activeElement.id.match(/kk_ktan/) != null) {
                    document.activeElement.select();
                }

                //
                //dWork = (CLng(TxtTateInp) + CLng(TxtYokoInp)) * 2 * CLng(TxtIta) * cHiritu2 * CLng(TxtKtankaInp) * CLng(TxtInzInp.Text)
//				var wk = (parseInt(res[2],10) + parseInt(res[3],10)) * 2 * parseInt(res[1],10) * parseInt(res[6],10) * parseInt(res[8],10) * parseInt(res[7],10);

                var wk = 0;

                // 切代金額(表示)
                if (tnkf == 1) {
                    // 一個

                    // 員数 * 切代単価
                    wk = jisRound(res[7]) * jisRound(ckktan);

                } else {
                    // 重量

                    wk = (
                             jisRound( parseFloat( res[2] ) )    // 幅
                           + jisRound( parseFloat( res[3] ) )    // 長さ
                         );
                    wk = wk * 2;
                    wk = wk * jisRound( parseFloat( res[1] ) );  // 板厚
                    wk = wk * res[6];                              // 比重2(0.0001)
                    wk = wk * jisRound( parseFloat( ckktan ) );  // 切代単価
                    wk = wk * jisRound( parseFloat( res[7] ) );  // 員数

                }

                wk = mRound(wk, 0);

                kk_kkin.innerHTML = wk.toLocaleString();
                Hkk_kkin.value = wk;

                getKiridaiKingakuGoukei();	// 切代合計

                // スクラップ重量(表示)
                //dWork = (CLng(TxtTateInp) - 10) * (CLng(TxtYokoInp) - 10) * CLng(TxtIta) * cHiritu * CLng(TxtInzInp)
                var wk2 = 0;
                /* #ukeire #403
                wk2 = (parseFloat(res[2]) - 10) * (parseFloat(res[3]) - 10);
                wk2 = wk2 * res[1];
                wk2 = wk2 * res[9];
                wk2 = wk2 * res[7];
                */
                wk2 =       ( jisRound( parseFloat( res[2] ) ) - 10 );    // 幅
                wk2 = wk2 * ( jisRound( parseFloat( res[3] ) ) - 10 );    // 長さ
                wk2 = wk2 * jisRound( parseFloat( res[1] ) );             // 板厚
                wk2 = wk2 * res[9];                                         // 比率
                wk2 = wk2 * jisRound( parseFloat( res[7] ) );             // 員数

                if (wk2 < 1) {
                    wk2 = 1;
                } else {
                    wk2 = mRound(wk2, 0);
                }

                kk_skjuryo.innerHTML = wk2.toLocaleString();
                Hkk_skjuryo.value = wk2;

                // スクラップ金額
                if (kk_sktan.value == "") {
                    // スクラップ単価(入力)
                    kk_sktan.value = "";

                    // スクラップ金額(表示)
                    kk_skkin.innerHTML = "0";
                    Hkk_skkin.value = 0;
                } else {
                    // スクラップ単価入力あり
                    var wk3 = 0;
                    wk3 = wk2 * kk_sktan.value;

                    // スクラップ金額(表示)
                    kk_skkin.innerHTML = wk3.toLocaleString();
                    Hkk_skkin.value = wk3;
                }

                getScrapKingakuGoukei();	// スクラップ合計

            }
        } else {
            // 丸

            if (res[0] == "1") {
                // 外周

                // 切代金額


            } else {
                // 内周
                /*
                 0:$i.
                 1:$ita.
                 2:$tate.
                 3:$yoko.
                 4:$zai.
                 5:$keijo.11:12
                 6:hiritsu2
                 7:insu
                 8:$tanka.
                 9:hiritsu
                 */

                // 切代単価(入力)
                var ckktan = kk_ktan.value;

                if (ckktan == "") {
                    ckktan = res[8];
                }

                kk_ktan.value = ckktan;

                if (document.activeElement.id.match(/kk_ktan/) != null) {
                    document.activeElement.select();
                }

                // 切代金額(表示)
                // dWork = CLng(TxtTateInp) * cPai * CLng(TxtIta) * cHiritu2 * CLng(TxtKtankaInp) * CLng(TxtInzInp.Text)
                var wk = 0;
                // #ukeire #403
                //wk = res[2] * cPai * res[1] * res[6] * ckktan * res[7];
                wk = jisRound( parseFloat( res[2] ) );       // 幅（外径）
                wk = wk * cPai;
                wk = wk * jisRound( parseFloat( res[1] ) );  // 板厚
                wk = wk * res[6];                              // 比重2(0.0001)
                wk = wk * jisRound( parseFloat( ckktan ) );  // 切代単価
                wk = wk * jisRound( parseFloat( res[7] ) );  // 員数

                // 一個
                if (tnkf == 1) {
                    wk = res[7] * ckktan;
                }

                wk = mRound(wk, 0);

                kk_kkin.innerHTML = wk.toLocaleString();
                Hkk_kkin.value = wk;

                getKiridaiKingakuGoukei();	// 切代合計

                // スクラップ重量(表示)
                // dWork = ((CLng(TxtTateInp) - 10) / 2) * ((CLng(TxtTateInp) - 10) / 2) * cPai * CLng(TxtIta) * cHiritu * CLng(TxtInzInp)
                var wk2 = 0;
                /* #ukeire #403
                wk2 = ((parseFloat(res[2]) - 10) / 2) * ((parseFloat(res[2]) - 10) / 2);
                wk2 = wk2 * cPai;
                wk2 = wk2 * res[1];
                wk2 = wk2 * res[9];
                wk2 = wk2 * res[7];
                */
                wk2 = ( jisRound( parseFloat( res[2] ) ) - 10 ) / 2;  // 幅（外径）
                wk2 = wk2 * wk2;
                wk2 = wk2 * cPai;
                wk2 = wk2 * jisRound( parseFloat( res[1] ) );         // 板厚
                wk2 = wk2 * res[9];                                     // 比率
                wk2 = wk2 * jisRound( parseFloat( res[7] ) );         // 員数

                if (wk2 < 1) {
                    wk2 = 1;
                } else {
                    wk2 = mRound(wk2, 0);
                }

                kk_skjuryo.innerHTML = wk2.toLocaleString();
                Hkk_skjuryo.value = wk2;

                // スクラップ金額
                if (kk_sktan.value == "") {
                    // スクラップ単価(入力)
                    kk_sktan.value = "";

                    // スクラップ金額(表示)
                    kk_skkin.innerHTML = "0";
                    Hkk_skkin.value = 0;
                } else {
                    //
                    var wk3 = 0;
                    wk3 = wk2 * kk_sktan.value;

                    // スクラップ金額(表示)
                    kk_skkin.innerHTML = wk3.toLocaleString();
                    Hkk_skkin.value = wk3;
                }

                getScrapKingakuGoukei();	// スクラップ合計

            }
        }
    }
}

// #ukeire #446
var httpObj2 = createXMLHttpRequest();// 加工事前データ取得
function jsGetKakouPreData(idx, tokuicd) {

    var dt = (new Date()).getTime();

    if (httpObj2) {
        httpObj2.open("GET", "/common/get_kakou_pre_data.php?idx=" + idx + "&tokuicd=" + tokuicd + "&dt=" + dt);
        httpObj2.onreadystatechange = handleHttpResponse15;
        httpObj2.send(null);
    }

}

function handleHttpResponse15() {

    if (httpObj2.readyState == 4) {

        var res = httpObj2.responseText.split("\t");

        /*
         0:idx
         1:tokuicd
         2:固定条件:kotei_out
         3:固定条件:status_out

         */
        var kotei_out = document.getElementById('kotei_out');
        var status_out = document.getElementById('status_out');

        kotei_out.value = res[2];
        status_out.value = res[3];

    }
}

// 16:加工単価の取得
// tok_cd
// ita
// kigou
// idx
// sy
function jsGetKakouMensakuTanka(tok_cd, ita, kigou, idx, sy) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        httpObj.open("GET", "/common/get_kakou_tanka.php?tok_cd=" + tok_cd + "&ita=" + ita + "&kigou=" + kigou + "&idx=" + idx + "&sy=" + sy + "&dt=" + dt);
        httpObj.onreadystatechange = handleHttpResponse16;
        httpObj.send(null);
    }

}

function handleHttpResponse16() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");


        /*
         0:status
         1:idx
         2:sy
         3:kakob
         4:fookb
         5:tan1
         6:tan2
         7:tan3

         */
        if (res[0] == 100) {
            // なし→メッセージ表示

        } else {
            //

            // 加工倍率をセット
            var kako_bairitsu = document.getElementById('kako_bairitsu');
            kako_bairitsu.value = res[3];

            var fook_bairitsu = document.getElementById('fook_bairitsu');
            fook_bairitsu.value = res[4];


            if (res[1] == 1) {

                if ((res[2] == 1) || (res[2] == 2)) {
                    // 板厚
                    var kshif = document.getElementById('kshif1_1');
                    var kshig = document.getElementById('kshig1_1');
                } else if ((res[2] == 3) || (res[2] == 4)) {
                    // 幅
                    var kshif = document.getElementById('kshif1_2');
                    var kshig = document.getElementById('kshig1_2');
                } else if ((res[2] == 5) || (res[2] == 6)) {
                    // 長さ
                    var kshif = document.getElementById('kshif1_3');
                    var kshig = document.getElementById('kshig1_3');
                }

            } else if (res[1] == 2) {


            } else if (res[1] == 3) {


            }

            // 単価あり
            if (res[5] != "") {
                if (res[5] > 0) {
                    kshif.value = res[5];
                }
            }

            // 単価あり
            if (res[6] != "") {
                if (res[6] > 0) {
                    kshig.value = res[6];
                }
            }

        }
    }
}

// 17:見積検索：外注コード検索
function jsGetMitsuSearchGaiName(tok_cd, bx) {

    var dt = (new Date()).getTime();

    if (httpObj) {
        // #3 19 28
        httpObj.open("GET", "/common/get_mitsumori_gaichu_name.php?tok_cd=" + tok_cd + "&bx=" + bx + "&dt=" + dt, false);
        httpObj.onreadystatechange = handleHttpResponse17;
        httpObj.send(null);

        // #3 19 28
        var res = httpObj.responseText.split( "\t" );
        if ( res[ 2 ] == "" )
        {
            return false;
        }
        return true;

    }

}

function handleHttpResponse17() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");

        /*
         res[0]外注コード
         res[1]1:材料外注 2:熱処理外注 3:加工外注 4:外注加工
         res[2]外注名
         */

        switch (res[1]) {
            case "1":
                var tok = document.getElementById('tokui_cd_gai');
                var toknm = document.getElementById('tokui_name_gai');
                break;
            case "2":
                var tok = document.getElementById('tokui_cd_net');
                var toknm = document.getElementById('tokui_name_net');
                break;
            case "3":
                var tok = document.getElementById('tokui_cd_kako');
                var toknm = document.getElementById('tokui_name_kako');
                break;
            case "4":
                var tok = document.getElementById('kakou_gai_code');
                var toknm = document.getElementById('kakou_gai_name');
                break;
        }

        // 取得なし
        if (res[2] == "") {
            //
            alert(Message.orderInputSubcontractCodeNotFound);

            if (res[1] == 4) {
                // ここだけspan表示
                toknm.innerHTML = "";
            } else {
                toknm.value = "";
            }

            elemFocusSelect(tok);

            return false;
        }

        if (res[1] == 4) {
            // ここだけspan表示
            toknm.innerHTML = res[2];
        } else {
            toknm.value = res[2];
        }
    }
}

// 18:実績問合せ
function jsGetJissekiToiawase(tokui_cd,chkKakou,start_date,zaisitsu,keijyo,ita,tate,yoko,iSelFlag) {

    var dt = (new Date()).getTime();

    if (httpObj) {
//        httpObj.open("GET", "/common/get_mitsumori_gaichu_name.php?tok_cd=" + tok_cd + "&bx=" + bx + "&dt=" + dt);
//        httpObj.onreadystatechange = handleHttpResponse18;
//        httpObj.send(null);
    }

}

function handleHttpResponse18() {

    if (httpObj.readyState == 4) {

        var res = httpObj.responseText.split("\t");








    }
}

// ----- 共通関数 -------------------------------------------------------//
function exGetExtension(fileName) {
    var ret;

    if (!fileName) {
        return ret;
    }

    var fileTypes = fileName.split(".");
    var len = fileTypes.length;

    if (len === 0) {
        return ret;
    }

    ret = fileTypes[len - 1];

    return ret;
}

function exSplitExt(filename) {

    var fll = filename.split(/\.(?=[^.]+$)/);

    return fll;
}

/**
 * デバッグログ
 *
 * @param {string} str
 * @param {string} clear
 * @param {string} path
 */
function debug_log( str, clear, path )
{

    str = ' < javascript >\n' + str;

    $.ajax({
        type          : "get",
        async         : false,
        url           : "/common/debug_log.php",
        data          :
        {
            "str"         : str,
            "clear"       : clear,
            "path"        : path,
        },
        crossDomain   : false,
        dataType      : "text",
        scriptCharset : "utf-8",
    }).done( function( res )
    {
    }).fail( function( XMLHttpRequest, textStatus, errorThrown )
    {
    });

}
