/** @global moment */
/**
 * 日付処理に関する関数をまとめたクラス
 *
 */
var DateFunc = {};

/**
 * 文字列が日付として正しいかチェックを行う。
 *
 * @param {string} sourceDate チェックを行う文字列
 * @param {number} mode フォーマット 0:YYYYMMDD
 */
DateFunc.checkDate = function (sourceDate, mode) {

    // 0: YYYYMMDD
    if (mode === 0) {
        var year = sourceDate.substr(0, 4);
        var month = parseInt(sourceDate.substr(4, 2)) - 1;
        var day = sourceDate.substr(6, 2);

    }

    var date = moment([year, month, day]);
    return (date.year() == year && date.month() == month && date.date() == day);

};


/**
 * 引数の日付がその月の第何週であるかを取得する。
 *
 * @param {moment} date 日付
 * @returns {number} 週の値
 */
DateFunc.getWeek = function (date) {
    return Math.floor((date.date() - date.day() + 12) / 7);
};

/**
 * 受け取った文字列を日付変換する。
 * 変換できなかった場合はnullを返す。
 * @param {string} strDate 変換する日付の文字列
 * @returns {Moment} 変換した日付。変換失敗時はnull
 */
DateFunc.convertDate = function (strDate) {
    // 日付変換
    var date = moment(strDate, ['M/D', 'YYYYMMDD', 'YY/M/D']);

    // 日付変換できていなければnullを返す
    if (!date.isValid()) {
        return null;
    }

    // 日付を返す
    return date;
};

/**
 * 受け取った文字列を日付変換する。(年・月優先）
 * 変換できなかった場合はnullを返す。
 * @param {string} strDate 変換する日付の文字列
 * @returns {Moment} 変換した日付。変換失敗時はnull
 */
DateFunc.convertDateYears = function (strDate) {
    // 日付変換
    var date = moment(strDate, ['YYYY/MM', 'YYYYMM', 'YY/MM', 'YYMM', 'MM']);

    // 日付変換できていなければnullを返す
    if (!date.isValid()) {
        return null;
    }

    // 日付を返す
    return date;
};

/**
 * 現在日付より300日以上離れているかをチェックする。
 * 超えている場合、ダイアログを表示する。
 * @param {Moment} momentDate チェックを行う日付
 * @returns {boolean} 300日未満または300日以上でダイアログではいを選択した場合true,いいえを選択した場合false
 */
DateFunc.checkThreeHundredDays = function (momentDate) {
    // 現在日付を取得
    var nowDate = moment('000000', 'HHmmss');

    // 現在日付との差分を取得
    var diff = nowDate.diff(momentDate, 'days');

    // 300日以上離れていた場合ダイアログを表示する
    if (diff >= 300 || diff <= -300) {
        if (!confirm(momentDate.format('YYYY/MM/DD') + ' は今日から300日以上離れた日付ですが、よろしいですか？')) {
            return false;
        }
    }

    // 300日以内またはダイアログではいを選択した
    return true;
};

/**
 * 日付が正常であるかのチェック結果
 */
var checkDateResult = {
    /**
     * 正常な日付
     * @type Number
     */
    normal: 0,
    /**
     * 許可されている空文字
     * @type Number
     */
    permitNull: 1,
    /**
     * エラー
     * @type Number
     */
    error: -1
};

/**
 * 日付として正しいかチェックを行う。
 * 無限ループに陥る可能性がない場合に使用する。
 * @param {string} checkId チェックを行う項目ID
 * @param {Boolean} isPermitNull 空文字を許可するかどうか
 * @returns {number} 0:正常  1:空文字許可時の空文字-1: エラー
 */
DateFunc.isCorrectDate = function (checkId, isPermitNull) {


    var inputDateElement = document.getElementById(checkId);

    // IDから値が取得できなければエラーを表示する。
    if (inputDateElement === null) {
        alert('指定された値が見つかりませんでした。');
        return checkDateResult.error;
    }

    var inputDate = inputDateElement.value;

    // 無入力の場合はダイアログを表示する。
    if (inputDate === "") {

        // 空文字許可の場合処理を抜ける
        if (isPermitNull) {
            return checkDateResult.permitNull;
        }

        alert('yyyy/mm/dd形式で入力してください。');
        setTimeout(function() {
            inputDateElement.focus();
        }, 1);
        return checkDateResult.error;
    }

    // 文字列を日付変換
    var resultDate = this.convertDate(inputDate);
    // 変換に失敗した場合はダイアログを出し終了
    if (!resultDate) {
        alert('yyyy/mm/dd形式で入力してください。');
        setTimeout(function() {
            inputDateElement.focus();
        }, 1);
        return checkDateResult.error;
    }

    return checkDateResult.normal;

};

/**
 * 日付として正しいかチェックを行う。
 * 無限ループに陥る可能性がある項目に使用する。
 * @param {string} checkId チェックを行う項目のID
 * @param {Boolean} isPermitNull 空文字を許可するかどうか
 * @returns {number} 0:正常  1:空文字許可時の空文字-1: エラー
 */
DateFunc.isCorrectDateStepLoop = function (checkId, isPermitNull) {


    var inputDateElement = document.getElementById(checkId);

    // IDから値が取得できなければエラーを表示する。
    if (inputDateElement === null) {
        alert('指定された値が見つかりませんでした。');
        return checkDateResult.error;
    }

    var inputDate = inputDateElement.value;

    // 無入力の場合はダイアログを表示する。
    if (inputDate === "") {

        // 空文字許可の場合処理を抜ける
        if (isPermitNull) {
            return checkDateResult.permitNull;
        }

        alert('yyyy/mm/dd形式で入力してください。');
        this.moveFocus(checkId);
        return checkDateResult.error;
    }

    // 文字列を日付変換
    var resultDate = this.convertDate(inputDate);
    // 変換に失敗した場合はダイアログを出し終了
    if (!resultDate) {
        alert('yyyy/mm/dd形式で入力してください。');
        this.moveFocus(checkId);
        return checkDateResult.error;
    }

    return checkDateResult.normal;

};

/**
 * 日付として正しいかチェックを行う。(年・月版)
 * 無限ループに陥る可能性がない場合に使用する。
 * @param {string} checkId チェックを行う項目ID
 * @param {Boolean} isPermitNull 空文字を許可するかどうか
 * @returns {number} 0:正常  1:空文字許可時の空文字-1: エラー
 */
DateFunc.isCorrectDateYears = function (checkId, isPermitNull) {


    var inputDateElement = document.getElementById(checkId);

    // IDから値が取得できなければエラーを表示する。
    if (inputDateElement === null) {
        alert('指定された値が見つかりませんでした。');
        return checkDateResult.error;
    }

    var inputDate = inputDateElement.value;

    // 無入力の場合はダイアログを表示する。
    if (inputDate === "") {

        // 空文字許可の場合処理を抜ける
        if (isPermitNull) {
            return checkDateResult.permitNull;
        }

        alert('yyyy/mm形式で入力してください。');
        elemFocus(inputDateElement);
        return checkDateResult.error;
    }

    // 文字列を日付変換
    var resultDate = this.convertDateYears(inputDate);
    // 変換に失敗した場合はダイアログを出し終了
    if (!resultDate) {
        alert('yyyy/mm形式で入力してください。');
        elemFocus(inputDateElement);

        return checkDateResult.error;
    }

    return checkDateResult.normal;

};

/**
 * 日付として正しいかチェックを行う。（年・月版）
 * 無限ループに陥る可能性がある項目に使用する。
 * @param {string} checkId チェックを行う項目のID
 * @param {Boolean} isPermitNull 空文字を許可するかどうか
 * @returns {number} 0:正常  1:空文字許可時の空文字-1: エラー
 */
DateFunc.isCorrectDateYearsStepLoop = function (checkId, isPermitNull) {


    var inputDateElement = document.getElementById(checkId);

    // IDから値が取得できなければエラーを表示する。
    if (inputDateElement === null) {
        alert('指定された値が見つかりませんでした。');
        return checkDateResult.error;
    }

    var inputDate = inputDateElement.value;

    // 無入力の場合はダイアログを表示する。
    if (inputDate === "") {

        // 空文字許可の場合処理を抜ける
        if (isPermitNull) {
            return checkDateResult.permitNull;
        }

        alert('yyyy/mm形式で入力してください。');
        this.moveFocus(checkId);
        return checkDateResult.error;
    }

    // 文字列を日付変換
    var resultDate = this.convertDateYears(inputDate);
    // 変換に失敗した場合はダイアログを出し終了
    if (!resultDate) {
        alert('yyyy/mm形式で入力してください。');
        this.moveFocus(checkId);
        return checkDateResult.error;
    }

    return checkDateResult.normal;

};

/**
 * 入力チェックを行い、日付をYYYY/MM/DD形式に補正する。
 * 日付として正しいかを判定する。
 * @param {string} checkId
 * @param isPermitNull
 * @returns {Boolean} 　
 */
DateFunc.checkInputDate = function (checkId, isPermitNull) {

    // isPermitNullに引数指定がない場合は自動でfalseが入る
    if (isPermitNull == null) {
        isPermitNull = false;
    }

    // 日付として正しいかチェック
    var correctResult = this.isCorrectDate(checkId, isPermitNull);
    // エラーであればfalseを返す
    if (correctResult === checkDateResult.error) {
        return false;
        // 許可されている空文字であればtrueを返し終了
    } else if (correctResult === checkDateResult.permitNull) {
        return true;
    }

    var inputElement = document.getElementById(checkId);
    var inputDate = this.convertDate(inputElement.value);

    inputElement.value = inputDate.format('YYYY/MM/DD');

    return true;
};

/**
 * 入力チェックを行い、日付をYYYY/MM/DD形式に補正する。
 * 日付として正しいかを判定する。
 * 無限ループ回避用
 * @param {string} checkId
 * @param isPermitNull
 * @returns {boolean} 　
 */
DateFunc.checkInputDateStepLoop = function (checkId, isPermitNull) {

    // isPermitNullに引数指定がない場合は自動でfalseが入る
    if (isPermitNull == null) {
        isPermitNull = false;
    }

    // 日付として正しいかチェック
    var correctResult = this.isCorrectDateStepLoop(checkId, isPermitNull);
    // エラーであればfalseを返す
    if (correctResult === checkDateResult.error) {
        return false;
        // 許可されている空文字であればtrueを返し終了
    } else if (correctResult === checkDateResult.permitNull) {
        return true;
    }

    var inputElement = document.getElementById(checkId);
    var inputDate = this.convertDate(inputElement.value);

    inputElement.value = inputDate.format('YYYY/MM/DD');

    return true;
};

/**
 * 入力チェックを行い、日付をYYYY/MM形式に補正する。
 * 日付として正しいかを判定する。
 * @param {string} checkId
 * @param isPermitNull
 * @returns {boolean} 　
 */
DateFunc.checkInputDateYears = function (checkId, isPermitNull) {

    // isPermitNullに引数指定がない場合は自動でfalseが入る
    if (isPermitNull == null) {
        isPermitNull = false;
    }

    // 日付として正しいかチェック
    var correctResult = this.isCorrectDateYears(checkId, isPermitNull);
    // エラーであればfalseを返す
    if (correctResult === checkDateResult.error) {
        return false;
        // 許可されている空文字であればtrueを返し終了
    } else if (correctResult === checkDateResult.permitNull) {
        return true;
    }

    var inputElement = document.getElementById(checkId);
    var inputDate = this.convertDateYears(inputElement.value);

    inputElement.value = inputDate.format('YYYY/MM');

    return true;
};

/**
 * 入力チェックを行い、日付をYYYY/MM形式に補正する。
 * 日付として正しいかを判定する。
 * 無限ループ回避用
 * @param {string} checkId
 * @param isPermitNull
 * @returns {boolean} 　
 */
DateFunc.checkInputDateYearsStepLoop = function (checkId, isPermitNull) {

    // isPermitNullに引数指定がない場合は自動でfalseが入る
    if (isPermitNull == null) {
        isPermitNull = false;
    }

    // 日付として正しいかチェック
    var correctResult = this.isCorrectDateYearsStepLoop(checkId, isPermitNull);
    // エラーであればfalseを返す
    if (correctResult === checkDateResult.error) {
        return false;
        // 許可されている空文字であればtrueを返し終了
    } else if (correctResult === checkDateResult.permitNull) {
        return true;
    }

    var inputElement = document.getElementById(checkId);
    var inputDate = this.convertDateYears(inputElement.value);

    inputElement.value = inputDate.format('YYYY/MM');

    return true;
};

/**
 * 入力チェックを行い、日付をYYYY/MM/DD形式に補正する。
 * 日付として正しいか・300日以上離れているかを判定する。
 * @param {string} checkId
 * @param isPermitNull
 * @returns {Boolean}
 */
DateFunc.checkInputDateThreeHundred = function (checkId, isPermitNull) {

    // isPermitNullに引数指定がない場合は自動でfalseが入る
    if (isPermitNull == null) {
        isPermitNull = false;
    }

// 日付として正しいかチェック
    var correctResult = this.isCorrectDate(checkId, isPermitNull);
    // エラーであればfalseを返す
    if (correctResult === checkDateResult.error) {
        return false;
        // 許可されている空文字であればtrueを返し終了
    } else if (correctResult === checkDateResult.permitNull) {
        return true;
    }

    var inputElement = document.getElementById(checkId);
    var inputDate = this.convertDate(inputElement.value);

    // 300日以上離れているかチェックする
    if (!this.checkThreeHundredDays(inputDate)) {
        elemFocus(inputElement);

        return false;
    }

    inputElement.value = inputDate.format('YYYY/MM/DD');

    return true;
};

/**
 * 入力チェックを行い、日付をYYYY/MM/DD形式に補正する。
 * 日付として正しいか・300日以上離れているかを判定する。
 * 無限ループ回避用
 * @param {string} checkId
 * @param isPermitNull
 * @returns {Boolean}
 */
DateFunc.checkInputDateThreeHundredStepLoop = function (checkId, isPermitNull) {

    // isPermitNullに引数指定がない場合は自動でfalseが入る
    if (isPermitNull == null) {
        isPermitNull = false;
    }

// 日付として正しいかチェック
    var correctResult = this.isCorrectDateStepLoop(checkId, isPermitNull);
    // エラーであればfalseを返す
    if (correctResult === checkDateResult.error) {
        return false;
        // 許可されている空文字であればtrueを返し終了
    } else if (correctResult === checkDateResult.permitNull) {
        return true;
    }

    var inputElement = document.getElementById(checkId);
    var inputDate = this.convertDate(inputElement.value);

    // 300日以上離れているかチェックする
    if (!this.checkThreeHundredDays(inputDate)) {
        this.moveFocus(checkId);
        return false;
    }

    inputElement.value = inputDate.format('YYYY/MM/DD');

    return true;
};

/**
 * 二つの文字列の日付を一つ目の日付基準で比較する。
 * @param {string} strFrom　比較基準の日付の文字列
 * @param {string} strTo 比較する日付の文字列
 * @returns {number} 基準の日付と比較対象の日付の差の日数
 */
DateFunc.compareDateFromString = function (strFrom, strTo) {
    var dateFrom = moment(strFrom, ['M/D', 'YYYYMMDD', 'YY/M/D']);
    var dateTo = moment(strTo, ['M/D', 'YYYYMMDD', 'YY/M/D']);

    return dateFrom.diff(dateTo, 'days');

};

/**
 * 無限ループが発生しないようフォーカスが当てられた要素のonblurイベントを無効化してフォーカスを移動する。
 * @param {string} focusId 移動するID
 * @returns {Boolean} 終了時trueをかえす。
 */
DateFunc.moveFocus = function (focusId) {
    var focusElement = document.getElementById(focusId);
    var activeBlur = focusElement.onblur;
    var focusElementJ = $(document.getElementById(focusId));
    focusElement.onblur = null;
    // jQueryのイベントハンドラーがあれば削除
    var events = $._data(focusElementJ.get(0)).events;
    if (events != undefined && events.blur != undefined) {
        var activeBlurJ = events.blur[0].handler;
        focusElementJ.unbind("blur");
    }
    
    focusElement.focus();
    setTimeout(function () {
        focusElement.onblur = activeBlur;
        // jQueryのイベントハンドラー復活処理
        if (activeBlurJ != undefined) {
            focusElementJ.bind("blur", activeBlurJ);
        }
    }, 10);

    return true;
};

/**
 * 休日判定を行う。
 * 日曜→振り替え休日→第1・第3土曜→祝日の順に判定する。
 *
 * @param {moment} date 休日判定を行う日付オブジェクト
 * @return {boolean} 問題がなければtrue キャンセル等ある場合はfalse
 */
DateFunc.checkHoliday = function (date) {

    // 曜日:0(日）- 6(土)
    var day = date.day();

    // 曜日毎に処理(0:日 - 6:土)
    switch (day) {

        // 日曜
        case 0:
            if (!confirm('この日は日曜日ですがよろしいですか？')) {
                return false;
            }
            break;
        // 月曜
        case 1:
            // 前日が祝日なら振替休日
            //var yesterday = date.subtract(1, "days");
            var yesterday = date.clone().subtract(1, "days");
            if(comJsGetHoliday(yesterday.format('YYYY/MM/DD')) === "") {
                break;
            }

            if(!confirm("この日付は振替休日に当たります。よろしいですか？")) {
                return false;
            }

            break;
        // 土曜
        case 6:
            var week = this.getWeek(date);

            if (week === 1 || week === 3) {
                // 第一・第三土曜日
                if (!confirm('この日は第１・第3土曜日ですがよろしいですか？')) {
                    return false;
                }
            }
            break;
        default:
            break;
    }

    // 祝日
    var holiday = comJsGetHoliday(date.format('YYYY/MM/DD'));
    if (holiday !== '' ) {
        return confirm('この日付は祝日（' + holiday + '）に当たります。よろしいですか？');
    }

    return true;
};

/**
 * 入力チェックを行い、日付をYYYY/MM/DD形式に補正する。
 * 日付として正しいか・300日以上離れているか・休日かを判定する。
 * @param {string} checkId
 * @returns {Boolean}
 */
DateFunc.checkInputDateThreeHundredHoliday = function (checkId, isPermitNull) {

    // isPermitNullに引数指定がない場合は自動でfalseが入る
    if (isPermitNull == null) {
        isPermitNull = false;
    }

// 日付として正しいかチェック
    var correctResult = this.isCorrectDate(checkId, isPermitNull);
    // エラーであればfalseを返す
    if (correctResult === checkDateResult.error) {
        return false;
        // 許可されている空文字であればtrueを返し終了
    } else if (correctResult === checkDateResult.permitNull) {
        return true;
    }

    var inputElement = document.getElementById(checkId);
    var inputDate = this.convertDate(inputElement.value);

    // 300日以上離れているかチェックする
    if (!this.checkThreeHundredDays(inputDate)) {
        elemFocus(inputElement);
        return false;
    }

    // 休日チェックをする
    if (!this.checkHoliday(inputDate)) {
        elemFocus(inputElement);
        return false;
    }

    inputElement.value = inputDate.format('YYYY/MM/DD');

    return true;
};

/**
 * 入力チェックを行い、日付をYYYY/MM/DD形式に補正する。
 * 日付として正しいか・300日以上離れているか・休日かを判定する。
 * 無限ループ回避用
 * @param {string} checkId
 * @returns {Boolean}
 */
DateFunc.checkInputDateThreeHundredHolidayStepLoop = function (checkId, isPermitNull) {

    // isPermitNullに引数指定がない場合は自動でfalseが入る
    if (isPermitNull == null) {
        isPermitNull = false;
    }

// 日付として正しいかチェック
    var correctResult = this.isCorrectDateStepLoop(checkId, isPermitNull);
    // エラーであればfalseを返す
    if (correctResult === checkDateResult.error) {
        return false;
        // 許可されている空文字であればtrueを返し終了
    } else if (correctResult === checkDateResult.permitNull) {
        return true;
    }

    var inputElement = document.getElementById(checkId);
    var inputDate = this.convertDate(inputElement.value);

    // 300日以上離れているかチェックする
    if (!this.checkThreeHundredDays(inputDate)) {
        this.moveFocus(checkId);
        return false;
    }

    // 休日チェックをする
    if (!this.checkHoliday(inputDate)) {
        DateFunc.moveFocus(checkId);
        return false;
    }

    inputElement.value = inputDate.format('YYYY/MM/DD');

    return true;
};

/**
 * 年として正しい値であるかチェックする。
 *
 * @param {string} checkId チェックを行うエレメントのID
 * @param {Boolean} isPermitNull 空の値を許可するか
 * @returns {Boolean} 異常なしの場合true 異常有の場合false
 */
DateFunc.checkYear = function (checkId, isPermitNull) {
    var inputYearElement = document.getElementById(checkId);

    // IDから値が取得できなければエラーを表示する。
    if (inputYearElement === null) {
        alert('指定された値が見つかりませんでした。');
        return false;
    }

    var inputYear = inputYearElement.value;

    // 無入力の場合はダイアログを表示する。
    if (inputYear === "") {

        // 空文字許可の場合処理を抜ける
        if (isPermitNull) {
            return true;
        }

        alert('年が入力されていません。');
        this.moveFocus(checkId);
        return false;
    }

    // 日付変換
    var date = moment(inputYear, ['YYYY']);

    // 日付変換できていなければnullを返す
    if (!date.isValid()) {
        alert('年には、数値を入力してください。');
        this.moveFocus(checkId);
        return false;
    }

    return true;

};

/**
 * 月として正しい値であるかチェックする。
 *
 * @param {string} checkId チェックを行うエレメントのID
 * @param {Boolean} isPermitNull 空の値を許可するか
 * @returns {Boolean} 異常なしの場合true 異常有の場合false
 */
DateFunc.checkMonth = function (checkId, isPermitNull) {
    var inputMonthElement = document.getElementById(checkId);

    // IDから値が取得できなければエラーを表示する。
    if (inputMonthElement === null) {
        alert('指定された値が見つかりませんでした。');
        return false;
    }

    var inputMonth = inputMonthElement.value;

    // 無入力の場合はダイアログを表示する。
    if (inputMonth === "") {

        // 空文字許可の場合処理を抜ける
        if (isPermitNull) {
            return true;
        }

        alert('月が入力されていません。');
        this.moveFocus(checkId);
        return false;
        // 月が1～12でない場合
    } else if (inputMonth < 1 || inputMonth > 12) {
        alert('月には1～12の数値を入力してください。');
        this.moveFocus(checkId);
        return false;
    }


    // 日付変換
    var date = moment(inputMonth, ['MM']);

    // 日付変換できていなければnullを返す
    if (!date.isValid()) {

        alert('月には、数値を入力してください。');
        this.moveFocus(checkId);
        return false;
    }

    return true;

};

/**
 * 前営業日を取得する。
 *
 * @param {string} dateStr 基準となる日付の文字列
 * @return {string | null} 前日の日付の文字列(YYYY/MM/DD) 取得できなければnull
 */
DateFunc.getLastBusinessDay = function (dateStr) {

    // 引数が日付に変換できるか確認
    var date = this.convertDate(dateStr);
    if(date == null) {
        alert("日付を変換できませんでした。");
        return null;
    }

    // 繰り返しフラグ
    var isContinue = true;
    // 休日でなくなるまで繰り返し日付を戻す
    while (isContinue) {

        isContinue = false;

        // 1日戻す
        date.subtract(1, "days");

        // 曜日取得:0(日）- 6(土)
        var dayWeek = date.day();

        // 曜日毎に処理(0:日 - 6:土)
        switch (dayWeek) {

            // 日曜
            case 0:
                isContinue = true;
                break;
            // 月曜
            case 1:
                // 前日が祝日なら振替休日
                var yesterday = date.subtract(1, "days");
                if(comJsGetHoliday(yesterday.format('YYYY/MM/DD')) !== "") {
                    isContinue = true;
                }

                // 2日戻ってしまっているため1日追加する
                date.add(1, "days");
                break;
            // 土曜
            case 6:
                var week = this.getWeek(date);

                if (week === 1 || week === 3) {
                    // 第一・第三土曜日
                    isContinue = true;
                }
                break;
            default:
                break;
        }

        // 祝日
        var holiday = comJsGetHoliday(date.format('YYYY/MM/DD'));
        if (holiday !== '' ) {
            isContinue = true;
        }
    }

    // 日付を返す
    return date.format("YYYY/MM/DD");

};