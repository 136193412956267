/**
 * JS用定数ファイル
 */

/**
 * ウィンドウサイズ
 */

/**
 * 受注入力横幅
 * @type {number}
 */
var ORDER_INPUT_WINDOW_WIDTH = 1350;

/**
 * 受注入力縦幅
 * @type {number}
 */
var ORDER_INPUT_WINDOW_HEIGHT = 935;

/**
 * 見積入力横幅
 * @type {number}
 */
var ESTIMATE_INPUT_WINDOW_WIDTH = 1350;

//------------------------------------------------------------------------------

/**
 * 受注関連
 */

/**
 * 入力行の最大値
 * @type {number}
 */
var INPUT_LINE_MAX = 3;

/**
 * セレクトボックスフォーカス時の行数
 * @type {number}
 */
var SELECTBOX_SIZE = 6;

/**
 * 届け先セレクトボックスフォーカス時の行数
 * @type {number}
 */
var SELECTBOX_SIZE_TODOKESAKI = 24;

/**
 * 届け先セレクトボックスの横幅
 * @type {string}
 */
var SELECTBOX_WIDTH_TODOKESAKI = '320px';

/**
 * 再計算ダイアログの実行結果:キャンセル
 * @type {string}
 */
var RECALC_RESULT_CANCEL = 'CANCEL';

// -----------------------------------------------------------------------------

/**
 * 加工関連
 */

/**
 * 形状タイプ:角・型(角)
 *
 * @type {number}
 */
var SHAPE_TYPE_SQUARE = 1;

/**
 * 形状タイプ:丸・型(丸)
 *
 * @type {number}
 */
var SHAPE_TYPE_ROUND = 2;

/**
 * 形状タイプ:リング
 *
 * @type {number}
 */
var SHAPE_TYPE_RING = 3;


// 加工内容ボタンの定数値
/**
 * 元データ
 * @type {number}
 */
var SELECT_SOURCE = 3;
/**
 * マスタ設定
 * @type {number}
 */
var SELECT_MASTER = 2;
/**
 * 最新
 * @type {number}
 */
var SELECT_LATEST = 1;